@import "_variables";
@import "fonts/_style";

input:not([type="checkbox"]):not([type="radio"]),
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.wrapper {
  max-width: $desktop-max-width;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  // @media (max-width: 1510px) {
  //   background-image: url(../img/common/bg_fill.png);
  //   background-position: 95% 475px;
  //   background-repeat: no-repeat;
  //   background-size: 40%;
  // }
  // @media (max-width: 1200px) {
  //   background-position: 95% 195px;
  // }
  // @media (max-width: 991px) {
  //   background-position: 50% 140px;
  //   background-size: 300px;
  
  // }
  // @media (max-width: 768px) {
  //   background-position: 50% 135px;
  // }
  // @media (max-width: 575px) {
  //   background-position: 50% 190px;
  // }
}
.main {

}

* {
  box-sizing: border-box;
}

li {
  list-style: none;
}

textarea {
  resize: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-size: 13px;
  font-weight: 300;
  font-family: var(--font-family);
  color: rgba(47, 47, 47, 0.6);
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  font-size: 13px;
  font-weight: 300;
  font-family: var(--font-family);
  color: rgba(47, 47, 47, 0.6);
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-size: 13px;
  font-weight: 300;
  font-family: var(--font-family);
  color: rgba(47, 47, 47, 0.6);
}

a {
  text-decoration: none;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    // color: inherit;
    outline: none;
  }

}

a,
button {
  &:disabled {
    pointer-events: none;
    opacity: 0.8;
  }
}

button,
.slick-arrow {
  outline: none !important;
  background: none;
  padding: 0;
  font-size: 0;
}

body,
html {
  font-size: 16px;
  min-height: 100vh;
  min-width: 369px;
  font-family: var(--font-family);
  color: var(--c-main);
  position: relative;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: var(--c-b-white);
  letter-spacing: 0.03em;
}

body {
  p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 0;
  }
  @media (max-width: 1199px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.btn {
  -webkit-appearance: none;
  padding: 0;
  outline: none;
  border-radius: 50px;
  position: relative;
  border: none;
  height: 69px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding-left: 80px;
  padding-right: 80px;

  span {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.pr {
  position: relative;
}

.scroll {
  transition: all 0.5s ease;
  z-index: 11111;
  position: fixed;
  -webkit-box-shadow: 0 0 5px #000;
  box-shadow: 0 0 5px #000;
  width: 60px;
  height: 60px;
  right: -65px;
  bottom: 80px;
  -webkit-border-radius: 25px;
  border-radius: 30px;
  background: var(--c-red);
  opacity: 0.5;
  text-align: center;
  line-height: 1;
  font-size: 30px;
  color: var(--c-white);
  cursor: pointer;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: var(--c-white);
    }
  }
}

.scroll:hover {
  opacity: 1;
}

.scroll.active {
  right: 30px;
  bottom: 80px;
}

section,
footer,
header {
  position: relative;
}

section {
  .container {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  @media (max-width: 1199px) {
    .container {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }
  @media (max-width: 991px) {
    .container {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
  @media (max-width: 768px) {
    margin: 0;
    .container {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    padding: 1rem 0;
  }
  @media (max-width: 556px) {
    margin: 0;
    .container {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}

a {
  transition: all 0.3s ease;
}

a:hover {
  text-decoration: none;
}

a.button,
.button {
  height: 2.25rem;
  padding: 0 1.75rem;
  background: var(--c-black);
  mix-blend-mode: normal;
  border: 1px solid #030405;
  font-style: normal;
  font-weight: 700;
  font-size: 0.8125rem;
  line-height: 100%;
  color: var(--c-white);
  mix-blend-mode: normal;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  text-align: center;
  width: fit-content;
  text-decoration: none;
  opacity: 1;
  outline: none !important;
  text-transform: uppercase;
  border-radius: 150px;
  transition: all 0.5s;
  font-feature-settings: 'pnum' on, 'lnum' on;

  &--squared {
    border-radius: 0.625rem;
  }

  &--white {
    background: var(--c-white);
    border: 1px solid var(--c-main);
    color: var(--c-main);
  }

  &--black {
    background: var(--c-black);
    border: 1px solid var(--c-black);
    color: var(--c-white);
  }


  &:hover,
  &:active,
  &:focus {
    // background: var(--c-red);
    opacity: 0.8;
    transition: all 0.5s;
  }

}

.button-two {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: var(--c-grey);
  width: 100%;
  max-width: 313px;
  height: 70px;
  top: 0;
  text-decoration: none !important;
  border: 0;
  opacity: 1;
  outline: none !important;
  border-radius: 100px;
  transition: all 0.5s;

  span {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 22px;
    line-height: 100%;
    letter-spacing: 0;
    color: var(--c-white);
    text-transform: uppercase;
    text-align: center;
    transition: all 0.5s;
  }

  &:hover,
  &:active,
  &:focus {
    background: var(--c-red);
    opacity: 0.9;
    transition: all 0.5s;

    span {
      color: var(--c-white);
      transition: all 0.5s;
    }
  }
}

.button-link {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: transparent;
  width: 100%;
  max-width: 134px;
  height: 40px;
  top: 0;
  text-decoration: none !important;
  border: 0;
  opacity: 1;
  outline: none !important;
  border-radius: 0;
  transition: all 0.5s;

  span {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0;
    color: var(--c-red);
    text-decoration: underline;
    text-decoration-skip-ink: none;
    transition: all 0.5s;
  }

  &:hover,
  &:active,
  &:focus {
    background: transparent;
    opacity: 0.8;
    transition: all 0.5s;

    span {
      color: var(--c-red);
      transition: all 0.5s;
    }
  }
}

.button-form {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: var(--c-red);
  width: 100%;
  max-width: 408px;
  height: 80px;
  top: 0;
  text-decoration: none !important;
  border: 0;
  opacity: 1;
  outline: none !important;
  border-radius: 100px;
  transition: all 0.5s;

  span {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 22px;
    line-height: 100%;
    letter-spacing: 0;
    color: var(--c-white);
    text-transform: uppercase;
    text-align: center;
    transition: all 0.5s;
  }

  &:hover,
  &:active,
  &:focus {
    background: var(--c-red);
    opacity: 0.8;
    transition: all 0.5s;

    span {
      color: var(--c-white);
      transition: all 0.5s;
    }
  }
}

.button:focus,
.button:active,
.button-two:focus,
.button-two:active,
.button-link:focus,
.button-link:active,
.button-form:focus,
.button-form:active,
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 1391px;
}

ul {
  padding-left: 0;
}

ul {
  &.squared {
    li {
      position: relative;
      padding-left: 1.25rem;
      padding-bottom: 0.3rem;
      font-style: normal;
      font-weight: 500;
      font-size: 1.625rem;
      line-height: 120%;
      color: #232035;
      &:before {
        content: "";
        position: absolute;
        top: 7px;
        left: 5px;
        width: 6px;
        height: 6px;
        background-color: var(--c-red);
        border-radius: 2px;
      }
    }
    &--yellow {
      li {
        &:before {
          background: linear-gradient(204.37deg, #FFDF96 13.87%, #D6A32D 110.55%);
        }
      }
    }
  }
}

img {
  max-width: 100%;
  max-height: 100%;
}

[disabled] {
  cursor: not-allowed;
}

.pr {
  position: relative;
}

.padded {
  padding-top: 120px;
  padding-bottom: 120px;
}

.form {
  &__text {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 20px;
  }

  &__line {
    margin-bottom: 20px;
  }

  input {
    height: 69px;
    width: 100%;
    border-radius: 50px;
    background: #fff;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    font-family: var(--font-family);
    outline: none;
    border: none;
    border: 1px solid #fff;

    &:focus {
      border-color: var(--c-purple);
    }
  }

  &__title {
    font-size: 52px;
    font-weight: bold;
    font-family: var(--font-family);
    line-height: 1.1;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
  }

  &__footer {
    margin-top: 5px;
    max-width: 65%;
  }
}

.checkbox {
  position: relative;
  padding-left: 30px;
  font-size: 12px;
  display: block;
  margin-bottom: 0;
  line-height: 1.3;
  cursor: pointer;
  text-transform: none;
  color: rgba(255, 255, 255, 0.6);

  a {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: underline;
  }

  &__input {
    position: absolute;
    width: 16px;
    height: 16px;
    border: 1px solid #bdc1c9;
    border-radius: 4px;
    top: 0;
    left: 0;
    opacity: 1 !important;
  }

  input:checked+.checkbox__input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:before {
      content: '';
      color: var(--c-white);
      background-image: url(../img/last/check.svg);
      background-repeat: no-repeat;
      background-position: center;
      width: 20px;
      height: 20px;
      padding-top: 1px;
      padding-left: 17px;
      opacity: 1;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
}

.toggle-menu {
  display: none;
  width: 19px;
  height: 13px;
  position: relative;
}

.toggle-menu span:after,
.toggle-menu span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
}

.toggle-menu span:after {
  top: 12px;
}

.toggle-menu span {
  position: relative;
  display: block;
}

.toggle-menu span,
.toggle-menu span:after,
.toggle-menu span:before {
  width: 100%;
  height: 2px;
  background-color: var(--c-main);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.toggle-menu.on span {
  background-color: transparent;
}

.toggle-menu.on span:before {
  -webkit-transform: rotate(45deg) translate(-1px, 0);
  -ms-transform: rotate(45deg) translate(-1px, 0);
  transform: rotate(45deg) translate(-1px, 0);
}

.toggle-menu.on span:after {
  -webkit-transform: rotate(-45deg) translate(5px, -5px);
  -ms-transform: rotate(-45deg) translate(5px, -5px);
  transform: rotate(-45deg) translate(5px, -5px);
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.625rem;
  mix-blend-mode: normal;
  line-height: 1.2;
  font-feature-settings: 'pnum' on, 'lnum' on;
  letter-spacing: 0;
  color: var(--c-main);

  .green {
    color: var(--c-green);
  }

  .white {
    color: var(--c-white);
  }
}

.t-green {
  color: var(--c-t-green) !important;
}
.b-violet,
.t-violet {
  background: radial-gradient(50% 82.45% at 50% 50%, #9C7AE4 0%, #6B38C1 100%);
}
.t-violet {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.heading {
  font-style: normal;
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 110%;
  text-transform: uppercase;
  color: var(--c-main);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.form__answer {
  .form__button {
    max-width: 100%;
    padding: 15px 10px;

    &:hover {
      opacity: 1;
      color: #fff;
    }
  }
}

.lazy-loaded {
  opacity: 1;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.buttons {
  padding: 2rem 0 0 0;
  margin: 0 0 0 auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .button {
  }
}

@media (max-width: 1509px) {
  .container {
    // max-width: 1200px;
    max-width: 100%;
  }
}

.swiper {
  &__nav {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 0 0;
    gap: 1rem;
  }
  &-button {
    margin-top: 0;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    width: 3.75rem;
    height: 3.75rem;
    background: var(--c-white);
    box-shadow: 0px 2px 10px #36363661;
    // border-radius: 0.625rem;
    border-radius: 100%;
    color: var(--c-black);
    &-next {
    }
    &-prev {
    }
    &::after {
      font-size: 1rem;
      font-weight: 600;
    }
  }
  &-pagination {
    position: relative;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    &-bullet {
      border-radius: 10px;
    }
  }

}

@import "header/_style";
@import "first/_style";
@import "about/_style";
@import "vidy/_style";
@import "dlya/_style";
@import "komu/_style";
@import "preim/_style";
@import "course/_style";
@import "ideal/_style";
@import "team/_style";
@import "intensives/_style";
@import "space/_style";
@import "tours/_style";
@import "experts/_style";
@import "partners/_style";
@import "programms/_style";
@import "program/_style";
@import "programma/_style";
@import "skills/_style";
@import "price/_style";
@import "last/_style";
@import "faq/_style";
@import "footer/_style";
@import "popup/_style";
@import "form/_style";
@import "timer/_style";
@import "dost/_style";
@import "news/_style";
@import "students/_style";