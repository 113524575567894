.ideal {
  padding-top: 180px;
  padding-bottom: 60px;

  .container {
  }

  &__heading {
    margin-bottom: 51px;
    .special {
      display: block;
      margin-bottom: -72px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0;
    margin: 0 -0.5rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0.5rem;
    font-family: var(--font-family);
    font-size: 26px;
    line-height: 120%;
    color: var(--c-white);
    box-sizing: border-box;
    width: 100%;
    max-width: 480px;
    background: var(--c-greyer);
    backdrop-filter: blur(7.5px);
    border-radius: 30px;
  }
  &__text {
    padding: 1rem;
  }

  &__number {
    font-family: var(--font3);
    font-size: 50px;
    line-height: 100%;
    text-transform: uppercase;
    color: var(--c-green);
    letter-spacing: 0;
    padding: 0 0 1rem 0;
  }

  &__text {
    margin-top: auto;
  }


  @media (max-width: 1880px) {
    &__list {

    }
  }

  @media (max-width: 1840px) {
    &__list {

    }
  }

  @media (max-width: 1700px) {
    &__list {

    }
  }
}