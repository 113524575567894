.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: transparent;
  margin: 0 auto;

  &-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 0;
    align-items: stretch;

    &__logo {
      width: 200px;
      img {
        width: 100%;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 1rem;
      a {
        svg {
          height: 30px;
          width: auto;
        }
      }
    }
  }

  .nav {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: 0.5rem;
    position: relative;
    z-index: 10;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
    color: var(--c-black);
    width: 100%;
    max-width: 747px;
    justify-content: space-evenly;
    a {
      color: var(--c-black);
    }
    &__subitem {
      display: none;
      position: absolute;
      top: 4.6rem;
      left: -5rem;
      z-index: 11;
      flex-direction: column;
      background: #FFFFFF;
      mix-blend-mode: normal;
      box-shadow: 0px 20px 32px rgba(24, 24, 24, 0.32);
      padding: 1rem 3rem 3rem 3rem;
      gap: 0.6rem;
      transition: all 1.5s;
      width: max-content;
      // max-width: 360px;
    }
    &__item {
      height: 100%;
      box-sizing: border-box;
      position: relative;
      &:hover {
        .nav__subitem {
          display: flex;
        }
      }
    }
    &__top {
      display: flex;
      height: 100%;
      box-sizing: border-box;
      align-items: center;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      &:hover {
        border-bottom: 2px solid #000;
      }
    }
    &__sub {
      font-style: normal;
      font-weight: 500;
      font-size: 1.0625rem;
      line-height: 110%;
      &:hover {
        text-decoration-line: underline;
      }
      &--title {
        font-style: normal;
        font-weight: 700;
        margin-top: 1rem;
      }
    }
  }

  &__buttons-btn {
    height: 52px;
    padding: 0 1.5rem;
    border-radius: 50px;
    font-weight: 600;
    font-size: 20px;
    text-transform: none;
    svg {
      display: none;
    }
  }

  &.fix {
    position: fixed;
    top: -1px;
    background-color: var(--c-white);
    box-shadow: 0px 2px 10px #36363661;

    .header-wrapper {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 20px;
      transition: all 0.5s;
    }
  }

  .row {
    height: 100%;
    justify-content: space-between;
    padding-top: 31px;
    padding-bottom: 20px;
    transition: all 0.5s;
  }

  &__box {
    position: relative;
    top: -3px;
    width: 100%;
    max-width: 202px;

    &-btn {
      &__thanks {
        display: none;
      }
    }
  }
  &-mobile {
    display: none;
    &__open {
      display: none;
    }
  }
  .toggle-menu {
    display: none;
    width: 43px;
    height: 43px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    background-color: var(--c-grey);
    border-radius: 10px;
    span {
      width: 100%;
      max-width: 31px;
      height: 2px;
      background: #000;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-radius: 2px;
      margin: 0 auto;
      margin-top: 14px;
      box-sizing: border-box;
      &:after,
      &:before {
        box-sizing: border-box;
        content: "";
        position: absolute;
        left: 0;
        top: 6px;
        width: 100%;
        max-width: 31px;
        height: 2px;
        background: #000;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border-radius: 2px;
        margin: 0 auto;
      }
      &:after {
        top: 12px;
      }
    }
  }
  &-close {
    display: none;
  }

  // &__logo {
  //   position: relative;
  //   cursor: pointer;
  //   display: block;
  //   width: 100%;
  //   max-width: fit-content;
  //   opacity: 1;
  //   transition: all 0.5s;

  //   &:hover,
  //   &:focus,
  //   &:active {
  //     opacity: 0.8;
  //     transition: all 0.5s;
  //   }

  //   img {
  //     width: 100%;
  //   }
  // }
}
