.footer {
  padding-top: 6rem;
  padding-bottom: 2rem;
  background: var(--c-grey);
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  mix-blend-mode: normal;
  a {
    color: var(--c-black);
  }

  &__top {
    display: flex;
    gap: 2rem;
    box-sizing: border-box;
    align-items: flex-start;
    padding-bottom: 2rem;
  }

  &__desc {
    box-sizing: border-box;
    width: 50%;
    display: flex;
  }

  &__info {
    box-sizing: border-box;
    width: 50%;
    display: flex;
    gap: 3rem;
    &--item {
      display: flex;
      gap: 2rem;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 1.0625rem;
    padding: 1.3rem 0;
    display: block;
  }

  &__logo {
    position: relative;
    cursor: pointer;
    display: block;
    // width: 100%;
    // max-width: fit-content;
    opacity: 1;
    transition: all 0.5s;

    &:hover,
    &:focus,
    &:active {
      opacity: 0.8;
      transition: all 0.5s;
    }

    img {
      width: 207px;
    }
  }

  &__slogan {
    border-left: 2px solid var(--c-black);
    margin-left: 3rem;
    padding-left: 1rem;
    max-width: 320px;
  }

  &__address {
    position: relative;

    &--item {
      padding: 2rem 0 0;
    }
    &--link,
    a {
      opacity: 1;
      transition: all 0.5s;

      &:hover,
      &:focus,
      &:active {
        color: var(--c-black);
        opacity: 0.8;
        transition: all 0.5s;
      }
    }
  }

  &__box {
    position: relative;
  }

  &__menu {
    &--list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__ps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9375rem;
  }

  @media (max-width: 1509px) {
  }
}