.last {
  position: relative;
  padding-top: 120px;
  padding-bottom: 0;
  z-index: 1;

  .container {
    display: flex;
    justify-content: space-between;
    padding: 65px 100px;
    box-sizing: border-box;
    // background: radial-gradient(50% 82.45% at 50% 50%, #92B83E 0%, #72912D 100%);
    backdrop-filter: blur(7.5px);
    border-radius: 30px;
  }

  &-wrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
    // padding-top: 110px;
    // padding-bottom: 102px;
  }

  &__info {
    // max-width: 950px;
    display: flex;
    flex-direction: column;
  }

  &__heading {
    font-size: 64px;
    line-height: 100%;
    margin-bottom: 24px;
    color: var(--c-black);

    .special {
      position: relative;
      top: 8px;
      display: block;
      font-size: 84px;
      line-height: 100%;
    }

    .white {
      position: relative;
      top: -6px;
      display: block;
    }
  }

  &__description {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 26px;
    line-height: 120%;
    letter-spacing: 0;
    color: var(--c-white);
    margin: auto 0 0 0;
    margin-left: 1px;
  }

  &-form {
    width: 480px;
    max-width: 480px;
    box-sizing: border-box;
    overflow: hidden;
    border: none;
    border-radius: 30px;
    padding: 0 0 0 30px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 26px;
    line-height: 120%;
    letter-spacing: 0;
    color: var(--c-white);
    z-index: 1;

    .form {
      .h1 {
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 26px;
        line-height: 120%;
        letter-spacing: 0;
        text-align: center;
        color: var(--c-white);
        margin-bottom: 48px;
      }

      &__lines {
        position: relative;
        width: 100%;
        max-width: 408px;
        margin-left: auto;
        margin-right: auto;

        .row {
          max-width: 408px;
          margin: 0 auto;

          .col-lg-3 {
            flex: none;
            width: 100%;
            max-width: 408px;
            height: 80px;
            padding: 0;
            margin-bottom: 20px;

            &:last-child {
              height: fit-content;
              margin-top: 3px;
              margin-bottom: 0;
            }
          }
        }
      }

      &__line {
        margin-bottom: 0;
      }

      &__input {
        width: 100%;
        max-width: 408px;
        height: 80px;
        background-color: var(--c-white);
        border: 1px solid #e8e5e1;
        border-radius: 100px;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: 0;
        color: #9b9b9b;
        padding-top: 0;
        padding-left: 31px;

        &::placeholder {
          font-family: var(--font-family);
          font-weight: 400;
          font-size: 22px;
          line-height: 120%;
          letter-spacing: 0;
          color: #9b9b9b;
        }
      }

      &__confirmation {
        max-width: 100%;
        margin-top: 0;
        margin-left: 0;

        .checkbox {
          padding-left: 20px;

          &__input {
            top: 0;
            left: 0;
            border: 1px solid var(--c-black);
            border-radius: 2px;
            width: 16px;
            height: 16px;
          }

          input {
            &:checked+.checkbox__input {
              top: 0;
              left: 0;
              border: 1px solid var(--c-black);
              border-radius: 2px;
              width: 16px;
              height: 16px;

              &:before {
                position: absolute;
                top: 2px;
                left: 2px;
                background-image: url(../img/popup/check1.svg);
                background-repeat: no-repeat;
                background-position: center;
                width: 10px;
                height: 10px;
                padding-top: 0;
                padding-left: 0;
                filter: brightness(0);
              }
            }
          }

          span {
            font-family: var(--font4);
            font-weight: 300;
            font-size: 12px;
            line-height: 120%;
            letter-spacing: 0;
            color: var(--c-white);

            .privacy {
              color: var(--c-white);
              text-decoration: underline;
            }

            br {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1509px) {
    &__info {
      // max-width: 770px;
    }

    &__heading {
      font-size: 52px;
      line-height: 100%;
      margin-bottom: 24px;

      .special {
        top: 4px;
        font-size: 72px;
        line-height: 100%;
      }

      .white {
        top: -8px;
      }
    }

    &-form {
      // top: 320px;
      // margin-right: -585px;
    }
  }
}
.last .button-form,
.last .button-form:hover,
.last .button-form:active,
.last .button-form:focus {
  background-color: var(--c-black);
}