.vidy {
  padding-top: 0;
  padding-bottom: 0;
  .container {
    background: var(--c-greyer);
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-right: 0;
    padding-left: 0;
  }

  &__box {
    // padding: 4rem 4rem;
    // background: url(../img/vidy/bg_vidy_1.jpg) no-repeat top left;
    height: 667px;
    padding-right: 6rem;
  }

  &__slider {
    width: 100%;
    height: 100%;
  }

  &__info {
    width: 100%;
    max-width: 461px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 6rem 4rem;
    background: linear-gradient(135deg,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.59) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  &__heading {
    padding-left: 6rem;
    margin-bottom: 4rem;
    color: var(--c-white)
  }

  .swiper {
    width: 100%;
    height: 100%;
    margin-right: auto;
    &-wrapper {
      align-items: stretch;
    }
    &-slide {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      flex-direction: column;
      background-repeat: no-repeat;
      background-position: top right;
      background-size: cover;
      // height: 100%;
      // min-height: 400px;
      height: auto;
      &:last-child {
        border-right: none;
      }
    }
    &__nav {
      margin-top: auto;
      justify-content: space-around;
    }
  }

  &__count {
    margin-bottom: 3rem;
    font-style: normal;
    font-weight: 100;
    font-size: 2.875rem;
    line-height: 110%;
    text-transform: uppercase;
    color: #434951;
    mix-blend-mode: normal;
    span {
      &:first-child {
        font-style: normal;
        font-weight: 400;
        font-size: 4.1875rem;
        color: #000000;
      }
    }
  }
  &__name {
    line-height: 110%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6875rem;
    mix-blend-mode: normal;
    margin-bottom: 2rem;
  }

  @media (max-width: 1880px) {
    &__list {
    }
  }

  @media (max-width: 1840px) {
    &__list {
    }
  }

  @media (max-width: 1700px) {
    &__list {
    }
  }
}