.komu {
  padding-top: 0;
  padding-bottom: 0;
  background: var(--c-greyer);
  .container {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 100%;
    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   left: 100%;
    //   width: 10000px;
    //   background: var(--c-white);
    // }
  }

  &__box {
    // padding: 4rem 4rem;
    // background: url(../img/komu/bg_komu.jpg) no-repeat top left;
    height: 623px;
  }

  &__slider {
    width: 100%;
    max-width: 1555px;
    height: 100%;
    margin-right: 6rem;
  }
  &__info {
    width: 100%;
    max-width: 555px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 6rem 4rem;
    // background: linear-gradient(135deg,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.59) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    margin-left: auto;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, #FFFFFF 100%);
    mix-blend-mode: normal;
  }

  &__heading {
    padding-left: 6rem;
    margin-bottom: 4rem;
    color: var(--c-white)
  }

  .swiper {
    width: 100%;
    height: 100%;
    margin-right: auto;
    &-wrapper {
      align-items: stretch;
    }
    &-slide {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      flex-direction: column;
      background-repeat: no-repeat;
      background-position: top right;
      background-size: cover;
      // height: 100%;
      // min-height: 400px;
      height: auto;
      &:last-child {
        border-right: none;
      }
    }
    &__nav {
      justify-content: space-evenly;
      margin-top: auto;
    }
  }

  &__count {
    margin-bottom: 1rem;
    font-style: normal;
    font-weight: 100;
    font-size: 2.875rem;
    line-height: 110%;
    text-transform: uppercase;
    text-align: right;
    color: #434951;
    mix-blend-mode: normal;
    span {
      &:first-child {
        font-style: normal;
        font-weight: 400;
        font-size: 4.1875rem;
        color: #000000;
      }
    }
  }
  &__name {
    line-height: 110%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-style: normal;
    font-weight: 600;


    font-style: normal;
    font-weight: 600;
    font-size: 2.125rem;

    margin-bottom: 4rem;
  }

  &__text {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6875rem;
  }

  @media (max-width: 1880px) {
    &__list {
    }
  }

  @media (max-width: 1840px) {
    &__list {
    }
  }

  @media (max-width: 1700px) {
    &__list {
    }
  }
}

.program {
  .container {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 650px;
      left: 100%;
      width: 10000px;
      transform: translateY(-650px);
      background: var(--c-white);
    }
  }
}