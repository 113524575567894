.course {
  // padding-top: 180px;
  padding-bottom: 60px;

  .container {
    padding: 65px 100px;
    box-sizing: border-box;
    backdrop-filter: blur(7.5px);
    border-radius: 30px;
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 10%;
  }

  &__heading {
    margin-bottom: 51px;
    color: var(--c-black);
    font-size: 60px;

    .special {
      font-size: 60px;
      display: block;
      margin-bottom: -72px;
    }
  }

  &__info {
    display: flex;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0 -25px;
    box-sizing: border-box;
    font-family: var(--font3);
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 130%;
  }

  &__item {
    box-sizing: border-box;
    position: relative;
    margin: 25px;
    font-family: var(--font-family);
    width: calc(50% - 50px);
    font-size: 26px;
    line-height: 120%;
    color: var(--c-white);
  }


  @media (max-width: 1880px) {
    &__list {
      margin-left: -180px;
    }
  }

  @media (max-width: 1840px) {
    &__list {
      margin-left: -100px;
    }
  }

  @media (max-width: 1700px) {
    &__list {
      margin-left: 0;
    }
  }

  @media (max-width: 1200px) {
    &__info {
      flex-wrap: wrap;
    }
    &__item {
      width: 100%;
    }
  }
}