.dlya {
  padding-top: 0;
  padding-bottom: 0;
  &__bg {
    background: linear-gradient(to right,  #ffffff 0%,#ffffff 50%,var(--c-grey) 51%,var(--c-grey) 100%);
  }

  .container {
    background: var(--c-greyer);
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 6rem;
  }

  &__box {
    background: var(--c-grey);
    padding: 0;
  }

  &__heading {
    // padding-left: 4rem;
    padding-bottom: 4rem;
    margin-bottom: 0;
    color: var(--c-white);
  }

  &__img {
    margin-bottom: 2rem;
    min-height: 116px;
    display: flex;
    align-items: center;
  }


  


  .swiper {
    width: 100%;
    height: auto;
    &-wrapper {
      align-items: stretch;
    }
    &-slide {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      max-width: 320px;
      // height: 100%;
      // min-height: 400px;
      height: auto;
      padding: 3rem 2rem 4rem 3rem;
      border-right: 7px solid var(--c-greyer);
      &:last-child {
        border-right: none;
      }
    }
    &__nav {
      padding: 2rem 0 3rem;
    }
  }

  &__name {
    margin-bottom: 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
  }
  &__text {
    line-height: 110%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-style: normal;
    font-weight: 500;
    font-size: 1.0625rem;
    line-height: 20px;
    mix-blend-mode: normal;
  }

  @media (max-width: 1880px) {
    &__list {
    }
  }

  @media (max-width: 1840px) {
    &__list {
    }
  }

  @media (max-width: 1700px) {
    &__list {
    }
  }
}