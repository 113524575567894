// @font-face {
//     font-family: 'HelveticaNeueCyr';
//     src: local('HelveticaNeueCyr-Bold'),
//         url('fonts/HelveticaNeueCyr-Bold.woff2') format('woff2'),
//         url('fonts/HelveticaNeueCyr-Bold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Inter';
//     src: local('Inter Bold'), local('Inter-Bold'),
//         url('fonts/Inter-Bold.woff2') format('woff2'),
//         url('fonts/Inter-Bold.woff') format('woff');
//     font-weight: 800;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Inter';
//     src: local('Inter Extra Bold'), local('Inter-ExtraBold'),
//         url('fonts/Inter-ExtraBold.woff2') format('woff2'),
//         url('fonts/Inter-ExtraBold.woff') format('woff');
//     font-weight: 900;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Inter';
//     src: local('Inter Medium'), local('Inter-Medium'),
//         url('fonts/Inter-Medium.woff2') format('woff2'),
//         url('fonts/Inter-Medium.woff') format('woff');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Inter';
//     src: local('Inter Medium Italic'), local('Inter-MediumItalic'),
//         url('fonts/Inter-MediumItalic.woff2') format('woff2'),
//         url('fonts/Inter-MediumItalic.woff') format('woff');
//     font-weight: 500;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Inter';
//     src: local('Inter Regular'), local('Inter-Regular'),
//         url('fonts/Inter-Regular.woff2') format('woff2'),
//         url('fonts/Inter-Regular.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Inter';
//     src: local('Inter Semi Bold'), local('Inter-SemiBold'),
//         url('fonts/Inter-SemiBold.woff2') format('woff2'),
//         url('fonts/Inter-SemiBold.woff') format('woff');
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'MV-SKIFER';
//     src: local('MV-SKIFER'), local('MVSKIFERRegular'),
//         url('fonts/MVSKIFERRegular.woff2') format('woff2'),
//         url('fonts/MVSKIFERRegular.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Suisse Intl';
//     src: local('Suisse Intl Light'), local('SuisseIntl-Light'),
//         url('fonts/SuisseIntl-Light.woff2') format('woff2'),
//         url('fonts/SuisseIntl-Light.woff') format('woff');
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Dela Gothic One';
//     src: local('Dela Gothic One Regular'), local('DelaGothicOne-Regular'),
//         url('fonts/DelaGothicOne-Regular.woff2') format('woff2'),
//         url('fonts/DelaGothicOne-Regular.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }














@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-ExtraLight.eot');
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
        url('fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-ExtraLight.woff2') format('woff2'),
        url('fonts/Montserrat-ExtraLight.woff') format('woff'),
        url('fonts/Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-MediumItalic.eot');
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
        url('fonts/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-MediumItalic.woff2') format('woff2'),
        url('fonts/Montserrat-MediumItalic.woff') format('woff'),
        url('fonts/Montserrat-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Italic.eot');
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
        url('fonts/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-Italic.woff2') format('woff2'),
        url('fonts/Montserrat-Italic.woff') format('woff'),
        url('fonts/Montserrat-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Regular.eot');
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-Regular.woff2') format('woff2'),
        url('fonts/Montserrat-Regular.woff') format('woff'),
        url('fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-ThinItalic.eot');
    src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
        url('fonts/Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-ThinItalic.woff2') format('woff2'),
        url('fonts/Montserrat-ThinItalic.woff') format('woff'),
        url('fonts/Montserrat-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Bold.eot');
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-Bold.woff2') format('woff2'),
        url('fonts/Montserrat-Bold.woff') format('woff'),
        url('fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Light.eot');
    src: local('Montserrat Light'), local('Montserrat-Light'),
        url('fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-Light.woff2') format('woff2'),
        url('fonts/Montserrat-Light.woff') format('woff'),
        url('fonts/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Black.eot');
    src: local('Montserrat Black'), local('Montserrat-Black'),
        url('fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-Black.woff2') format('woff2'),
        url('fonts/Montserrat-Black.woff') format('woff'),
        url('fonts/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Medium.eot');
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
        url('fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-Medium.woff2') format('woff2'),
        url('fonts/Montserrat-Medium.woff') format('woff'),
        url('fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-ExtraLightItalic.eot');
    src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
        url('fonts/Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-ExtraLightItalic.woff2') format('woff2'),
        url('fonts/Montserrat-ExtraLightItalic.woff') format('woff'),
        url('fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-BlackItalic.eot');
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
        url('fonts/Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-BlackItalic.woff2') format('woff2'),
        url('fonts/Montserrat-BlackItalic.woff') format('woff'),
        url('fonts/Montserrat-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-ExtraBoldItalic.eot');
    src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
        url('fonts/Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
        url('fonts/Montserrat-ExtraBoldItalic.woff') format('woff'),
        url('fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-SemiBoldItalic.eot');
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
        url('fonts/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
        url('fonts/Montserrat-SemiBoldItalic.woff') format('woff'),
        url('fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-ExtraBold.eot');
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
        url('fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-ExtraBold.woff2') format('woff2'),
        url('fonts/Montserrat-ExtraBold.woff') format('woff'),
        url('fonts/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-BoldItalic.eot');
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
        url('fonts/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-BoldItalic.woff2') format('woff2'),
        url('fonts/Montserrat-BoldItalic.woff') format('woff'),
        url('fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-LightItalic.eot');
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
        url('fonts/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-LightItalic.woff2') format('woff2'),
        url('fonts/Montserrat-LightItalic.woff') format('woff'),
        url('fonts/Montserrat-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-SemiBold.eot');
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url('fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('fonts/Montserrat-SemiBold.woff') format('woff'),
        url('fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Thin.eot');
    src: local('Montserrat Thin'), local('Montserrat-Thin'),
        url('fonts/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
        url('fonts/Montserrat-Thin.woff2') format('woff2'),
        url('fonts/Montserrat-Thin.woff') format('woff'),
        url('fonts/Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}



















// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-SemiBoldItalic.eot');
//     src: local('Raleway SemiBold Italic'), local('Raleway-SemiBoldItalic'),
//         url('fonts/Raleway-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-SemiBoldItalic.woff2') format('woff2'),
//         url('fonts/Raleway-SemiBoldItalic.woff') format('woff'),
//         url('fonts/Raleway-SemiBoldItalic.ttf') format('truetype');
//     font-weight: 600;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-ExtraLightItalic.eot');
//     src: local('Raleway ExtraLight Italic'), local('Raleway-ExtraLightItalic'),
//         url('fonts/Raleway-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-ExtraLightItalic.woff2') format('woff2'),
//         url('fonts/Raleway-ExtraLightItalic.woff') format('woff'),
//         url('fonts/Raleway-ExtraLightItalic.ttf') format('truetype');
//     font-weight: 200;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-MediumItalic.eot');
//     src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'),
//         url('fonts/Raleway-MediumItalic.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-MediumItalic.woff2') format('woff2'),
//         url('fonts/Raleway-MediumItalic.woff') format('woff'),
//         url('fonts/Raleway-MediumItalic.ttf') format('truetype');
//     font-weight: 500;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-ExtraBoldItalic.eot');
//     src: local('Raleway ExtraBold Italic'), local('Raleway-ExtraBoldItalic'),
//         url('fonts/Raleway-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-ExtraBoldItalic.woff2') format('woff2'),
//         url('fonts/Raleway-ExtraBoldItalic.woff') format('woff'),
//         url('fonts/Raleway-ExtraBoldItalic.ttf') format('truetype');
//     font-weight: 800;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-Regular.eot');
//     src: local('Raleway'), local('Raleway-Regular'),
//         url('fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-Regular.woff2') format('woff2'),
//         url('fonts/Raleway-Regular.woff') format('woff'),
//         url('fonts/Raleway-Regular.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-ExtraBold.eot');
//     src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'),
//         url('fonts/Raleway-ExtraBold.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-ExtraBold.woff2') format('woff2'),
//         url('fonts/Raleway-ExtraBold.woff') format('woff'),
//         url('fonts/Raleway-ExtraBold.ttf') format('truetype');
//     font-weight: 800;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-BlackItalic.eot');
//     src: local('Raleway Black Italic'), local('Raleway-BlackItalic'),
//         url('fonts/Raleway-BlackItalic.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-BlackItalic.woff2') format('woff2'),
//         url('fonts/Raleway-BlackItalic.woff') format('woff'),
//         url('fonts/Raleway-BlackItalic.ttf') format('truetype');
//     font-weight: 900;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-Light.eot');
//     src: local('Raleway Light'), local('Raleway-Light'),
//         url('fonts/Raleway-Light.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-Light.woff2') format('woff2'),
//         url('fonts/Raleway-Light.woff') format('woff'),
//         url('fonts/Raleway-Light.ttf') format('truetype');
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-Black.eot');
//     src: local('Raleway Black'), local('Raleway-Black'),
//         url('fonts/Raleway-Black.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-Black.woff2') format('woff2'),
//         url('fonts/Raleway-Black.woff') format('woff'),
//         url('fonts/Raleway-Black.ttf') format('truetype');
//     font-weight: 900;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-Medium.eot');
//     src: local('Raleway Medium'), local('Raleway-Medium'),
//         url('fonts/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-Medium.woff2') format('woff2'),
//         url('fonts/Raleway-Medium.woff') format('woff'),
//         url('fonts/Raleway-Medium.ttf') format('truetype');
//     font-weight: 500;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-ExtraLight.eot');
//     src: local('Raleway ExtraLight'), local('Raleway-ExtraLight'),
//         url('fonts/Raleway-ExtraLight.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-ExtraLight.woff2') format('woff2'),
//         url('fonts/Raleway-ExtraLight.woff') format('woff'),
//         url('fonts/Raleway-ExtraLight.ttf') format('truetype');
//     font-weight: 200;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-Italic.eot');
//     src: local('Raleway Italic'), local('Raleway-Italic'),
//         url('fonts/Raleway-Italic.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-Italic.woff2') format('woff2'),
//         url('fonts/Raleway-Italic.woff') format('woff'),
//         url('fonts/Raleway-Italic.ttf') format('truetype');
//     font-weight: normal;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-Thin.eot');
//     src: local('Raleway Thin'), local('Raleway-Thin'),
//         url('fonts/Raleway-Thin.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-Thin.woff2') format('woff2'),
//         url('fonts/Raleway-Thin.woff') format('woff'),
//         url('fonts/Raleway-Thin.ttf') format('truetype');
//     font-weight: 100;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-SemiBold.eot');
//     src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
//         url('fonts/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-SemiBold.woff2') format('woff2'),
//         url('fonts/Raleway-SemiBold.woff') format('woff'),
//         url('fonts/Raleway-SemiBold.ttf') format('truetype');
//     font-weight: 600;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-LightItalic.eot');
//     src: local('Raleway Light Italic'), local('Raleway-LightItalic'),
//         url('fonts/Raleway-LightItalic.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-LightItalic.woff2') format('woff2'),
//         url('fonts/Raleway-LightItalic.woff') format('woff'),
//         url('fonts/Raleway-LightItalic.ttf') format('truetype');
//     font-weight: 300;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Raleway';
//     src: url('fonts/Raleway-Bold.eot');
//     src: local('Raleway Bold'), local('Raleway-Bold'),
//         url('fonts/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Raleway-Bold.woff2') format('woff2'),
//         url('fonts/Raleway-Bold.woff') format('woff'),
//         url('fonts/Raleway-Bold.ttf') format('truetype');
//     font-weight: bold;
//     font-style: normal;
// }

