.timer {
	padding: 0;
	background: url(../img/common/bg_timer.jpg) center center;

	&__inner {
		padding: 1rem;
		display: flex;
		align-items: center;
		justify-content: space-between;	
		gap: 1rem;
		font-style: normal;
		font-weight: 600;
		font-size: 2.25rem;
		line-height: 110%;
		font-feature-settings: 'pnum' on, 'lnum' on;
		color: #FFFFFF;
		@media (max-width: 1510px) {
			font-size: 1.85rem;
		}
		@media (max-width: 1355px) {
			font-size: 1.25rem;
		}
	}

	&-top {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 1rem;
	}


	&-title {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0.625rem 1.25rem;
		background: #F8444B;
		border-radius: 0.625rem;
		br {
			display: none;
		}
	}


	&-items {
		display: flex;
	}


	&-item {
		position: relative;
		// margin: 0 0.5rem;
		flex-basis: 25%;
		display: flex;
		align-items: flex-start;
		gap: 5px;

		&-value {
			display: flex;
			align-items: center;
		}

		&-text {
			display: none;
			align-items: center;
		}
	}

	&__button {
		height: 52px;
		padding: 0 1.5rem;
		border-radius: 50px;
		font-weight: 600;
		font-size: 1.25rem;
		text-transform: none;
	}

	.timer-item_1 .timer-item-text {
		display: flex;
	}
}

.timer-item_1 .timer-item-text:after,
.timer-item_2 .timer-item-value:after,
.timer-item_3 .timer-item-value:after {
	content: ':';
	padding: 0 0.5rem;
	display: block;
}