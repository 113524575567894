.programms {
  margin-top: 4rem;
  padding-top: 7rem;
  padding-bottom: 7rem;
  background: #ECEDF0;

  &__heading {
    max-width: 870px;
    margin-bottom: 51px;
  }



  


  .swiper {
    width: 100%;
    height: 750px;
    margin-bottom: 0;
    &-slide {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      max-width: 453px;
      height: calc((100% - 30px) / 2) !important;
      background: #FFFFFF;
      border-radius: 20px;
      padding: 1rem;
      background-image: url("data:image/svg+xml,%3Csvg width='63' height='40' viewBox='0 0 63 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' fill='%23ECEDF0'/%3E%3Ccircle cx='43' cy='20' r='20' fill='%23ED131C'/%3E%3Cpath d='M50.0537 12.8037C50.3883 12.4851 50.8335 12.3091 51.2954 12.3126C51.7573 12.316 52.1998 12.4989 52.5295 12.8224C52.8592 13.1459 53.0503 13.5849 53.0625 14.0467C53.0747 14.5084 52.9071 14.9569 52.5949 15.2974L43.1187 27.1487C42.9558 27.3242 42.7591 27.465 42.5405 27.5628C42.3218 27.6605 42.0857 27.7132 41.8463 27.7177C41.6068 27.7221 41.369 27.6782 41.1469 27.5886C40.9248 27.499 40.723 27.3655 40.5537 27.1962L34.2694 20.9119C34.0944 20.7488 33.9541 20.5522 33.8567 20.3337C33.7594 20.1152 33.707 19.8793 33.7028 19.6401C33.6986 19.401 33.7426 19.1634 33.8322 18.9416C33.9217 18.7198 34.0551 18.5183 34.2242 18.3492C34.3934 18.18 34.5949 18.0467 34.8167 17.9571C35.0384 17.8675 35.276 17.8235 35.5152 17.8278C35.7544 17.832 35.9902 17.8843 36.2087 17.9817C36.4272 18.079 36.6239 18.2194 36.7869 18.3944L41.7602 23.3653L50.0086 12.8559L50.0537 12.8037Z' fill='white'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: calc(100% - 1rem);
      background-position-y: calc(0% + 1rem);
    }
    &__nav {
      padding-bottom: 3rem;
    }
  }

  &__type {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #7C7C7C;
    padding-bottom: 0.5rem;
  }
  &__name {
    max-width: 360px;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 110%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
  }
  &__text {
    margin-top: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
  }
  
  &__list {
    display: flex;
    margin: 0 -0.5rem;
    align-items: stretch;
    flex-wrap: wrap;
  }
  &__item {
    margin: 0.5rem;
    padding: 1rem;
    box-sizing: border-box;
    width: calc(50% - 1rem);
    height: 342px;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 110%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
    background: #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    a.button {
      margin-top: auto;
      text-decoration: underline !important;
    }
    &:nth-child(2) {
      padding: 0;
      background: url(../img/programms/img.jpg) no-repeat center center;
      background-size: cover;
    }
  }
  &__info {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__icons {
    display: flex;
    gap: 1rem;
  }
  &__button {
    display: none;
  }


  @media (max-width: 1880px) {
    &__list {
    }
  }

  @media (max-width: 1840px) {
    &__list {
    }
  }

  @media (max-width: 1700px) {
    &__list {
    }
  }
}