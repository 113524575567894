@charset "UTF-8";
/***********/
/* Common */
:root {
	--font-family: "Montserrat", sans-serif;
	--c-main: #000;
	--c-red: #ED131C;
	--c-black: #000;
	--c-white: #ffffff;
	--c-grey: #E8E9F0;
	--c-greyer: #484A4E;
	--c-green: #ACF805;
	--c-t-green: #ACF805;
	--c-b-white: #FFF;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-ExtraLight.eot");
	src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"), url("fonts/Montserrat-ExtraLight.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-ExtraLight.woff2") format("woff2"), url("fonts/Montserrat-ExtraLight.woff") format("woff"), url("fonts/Montserrat-ExtraLight.ttf") format("truetype");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-MediumItalic.eot");
	src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"), url("fonts/Montserrat-MediumItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-MediumItalic.woff2") format("woff2"), url("fonts/Montserrat-MediumItalic.woff") format("woff"), url("fonts/Montserrat-MediumItalic.ttf") format("truetype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-Italic.eot");
	src: local("Montserrat Italic"), local("Montserrat-Italic"), url("fonts/Montserrat-Italic.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-Italic.woff2") format("woff2"), url("fonts/Montserrat-Italic.woff") format("woff"), url("fonts/Montserrat-Italic.ttf") format("truetype");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-Regular.eot");
	src: local("Montserrat Regular"), local("Montserrat-Regular"), url("fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-Regular.woff2") format("woff2"), url("fonts/Montserrat-Regular.woff") format("woff"), url("fonts/Montserrat-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-ThinItalic.eot");
	src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"), url("fonts/Montserrat-ThinItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-ThinItalic.woff2") format("woff2"), url("fonts/Montserrat-ThinItalic.woff") format("woff"), url("fonts/Montserrat-ThinItalic.ttf") format("truetype");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-Bold.eot");
	src: local("Montserrat Bold"), local("Montserrat-Bold"), url("fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-Bold.woff2") format("woff2"), url("fonts/Montserrat-Bold.woff") format("woff"), url("fonts/Montserrat-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-Light.eot");
	src: local("Montserrat Light"), local("Montserrat-Light"), url("fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-Light.woff2") format("woff2"), url("fonts/Montserrat-Light.woff") format("woff"), url("fonts/Montserrat-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-Black.eot");
	src: local("Montserrat Black"), local("Montserrat-Black"), url("fonts/Montserrat-Black.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-Black.woff2") format("woff2"), url("fonts/Montserrat-Black.woff") format("woff"), url("fonts/Montserrat-Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-Medium.eot");
	src: local("Montserrat Medium"), local("Montserrat-Medium"), url("fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-Medium.woff2") format("woff2"), url("fonts/Montserrat-Medium.woff") format("woff"), url("fonts/Montserrat-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-ExtraLightItalic.eot");
	src: local("Montserrat ExtraLight Italic"), local("Montserrat-ExtraLightItalic"), url("fonts/Montserrat-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-ExtraLightItalic.woff2") format("woff2"), url("fonts/Montserrat-ExtraLightItalic.woff") format("woff"), url("fonts/Montserrat-ExtraLightItalic.ttf") format("truetype");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-BlackItalic.eot");
	src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"), url("fonts/Montserrat-BlackItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-BlackItalic.woff2") format("woff2"), url("fonts/Montserrat-BlackItalic.woff") format("woff"), url("fonts/Montserrat-BlackItalic.ttf") format("truetype");
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-ExtraBoldItalic.eot");
	src: local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"), url("fonts/Montserrat-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-ExtraBoldItalic.woff2") format("woff2"), url("fonts/Montserrat-ExtraBoldItalic.woff") format("woff"), url("fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-SemiBoldItalic.eot");
	src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"), url("fonts/Montserrat-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-SemiBoldItalic.woff2") format("woff2"), url("fonts/Montserrat-SemiBoldItalic.woff") format("woff"), url("fonts/Montserrat-SemiBoldItalic.ttf") format("truetype");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-ExtraBold.eot");
	src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url("fonts/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("fonts/Montserrat-ExtraBold.woff") format("woff"), url("fonts/Montserrat-ExtraBold.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-BoldItalic.eot");
	src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"), url("fonts/Montserrat-BoldItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-BoldItalic.woff2") format("woff2"), url("fonts/Montserrat-BoldItalic.woff") format("woff"), url("fonts/Montserrat-BoldItalic.ttf") format("truetype");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-LightItalic.eot");
	src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"), url("fonts/Montserrat-LightItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-LightItalic.woff2") format("woff2"), url("fonts/Montserrat-LightItalic.woff") format("woff"), url("fonts/Montserrat-LightItalic.ttf") format("truetype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-SemiBold.eot");
	src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-SemiBold.woff2") format("woff2"), url("fonts/Montserrat-SemiBold.woff") format("woff"), url("fonts/Montserrat-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url("fonts/Montserrat-Thin.eot");
	src: local("Montserrat Thin"), local("Montserrat-Thin"), url("fonts/Montserrat-Thin.eot?#iefix") format("embedded-opentype"), url("fonts/Montserrat-Thin.woff2") format("woff2"), url("fonts/Montserrat-Thin.woff") format("woff"), url("fonts/Montserrat-Thin.ttf") format("truetype");
	font-weight: 100;
	font-style: normal;
}

input:not([type="checkbox"]):not([type="radio"]),
textarea,
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.wrapper {
	max-width: 1920px;
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
	margin: 0 auto;
}

* {
	box-sizing: border-box;
}

li {
	list-style: none;
}

textarea {
	resize: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	font-size: 13px;
	font-weight: 300;
	font-family: var(--font-family);
	color: rgba(47, 47, 47, 0.6);
}

input::-moz-placeholder,
textarea::-moz-placeholder {
	font-size: 13px;
	font-weight: 300;
	font-family: var(--font-family);
	color: rgba(47, 47, 47, 0.6);
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	font-size: 13px;
	font-weight: 300;
	font-family: var(--font-family);
	color: rgba(47, 47, 47, 0.6);
}

a {
	text-decoration: none;
	outline: none;
}

a:hover, a:active, a:focus {
	outline: none;
}

a:disabled,
button:disabled {
	pointer-events: none;
	opacity: 0.8;
}

button,
.slick-arrow {
	outline: none !important;
	background: none;
	padding: 0;
	font-size: 0;
}

body,
html {
	font-size: 16px;
	min-height: 100vh;
	min-width: 369px;
	font-family: var(--font-family);
	color: var(--c-main);
	position: relative;
	word-wrap: break-word;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	background: var(--c-b-white);
	letter-spacing: 0.03em;
}

body p {
	font-size: 18px;
	line-height: 1.5;
	margin-bottom: 0;
}

@media (max-width: 1199px) {
	body {
		font-size: 14px;
	}
}

@media (max-width: 768px) {
	body {
		font-size: 12px;
	}
}

.btn {
	-webkit-appearance: none;
	padding: 0;
	outline: none;
	border-radius: 50px;
	position: relative;
	border: none;
	height: 69px;
	color: #fff;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	padding-left: 80px;
	padding-right: 80px;
}

.btn span {
	position: relative;
	font-size: 20px;
	font-weight: bold;
	line-height: 1;
	text-transform: uppercase;
	cursor: pointer;
}

.pr {
	position: relative;
}

.scroll {
	transition: all 0.5s ease;
	z-index: 11111;
	position: fixed;
	-webkit-box-shadow: 0 0 5px #000;
	box-shadow: 0 0 5px #000;
	width: 60px;
	height: 60px;
	right: -65px;
	bottom: 80px;
	-webkit-border-radius: 25px;
	border-radius: 30px;
	background: var(--c-red);
	opacity: 0.5;
	text-align: center;
	line-height: 1;
	font-size: 30px;
	color: var(--c-white);
	cursor: pointer;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}

.scroll svg path {
	fill: var(--c-white);
}

.scroll:hover {
	opacity: 1;
}

.scroll.active {
	right: 30px;
	bottom: 80px;
}

section,
footer,
header {
	position: relative;
}

section .container {
	padding-top: 6rem;
	padding-bottom: 6rem;
}

@media (max-width: 1199px) {
	section .container {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
}

@media (max-width: 991px) {
	section .container {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

@media (max-width: 768px) {
	section {
		margin: 0;
		padding: 1rem 0;
	}
	section .container {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

@media (max-width: 556px) {
	section {
		margin: 0;
	}
	section .container {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

a {
	transition: all 0.3s ease;
}

a:hover {
	text-decoration: none;
}

a.button,
.button {
	height: 2.25rem;
	padding: 0 1.75rem;
	background: var(--c-black);
	mix-blend-mode: normal;
	border: 1px solid #030405;
	font-style: normal;
	font-weight: 700;
	font-size: 0.8125rem;
	line-height: 100%;
	color: var(--c-white);
	mix-blend-mode: normal;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	display: inline-flex;
	text-align: center;
	width: fit-content;
	text-decoration: none;
	opacity: 1;
	outline: none !important;
	text-transform: uppercase;
	border-radius: 150px;
	transition: all 0.5s;
	font-feature-settings: 'pnum' on, 'lnum' on;
}

a.button--squared,
.button--squared {
	border-radius: 0.625rem;
}

a.button--white,
.button--white {
	background: var(--c-white);
	border: 1px solid var(--c-main);
	color: var(--c-main);
}

a.button--black,
.button--black {
	background: var(--c-black);
	border: 1px solid var(--c-black);
	color: var(--c-white);
}

a.button:hover, a.button:active, a.button:focus,
.button:hover,
.button:active,
.button:focus {
	opacity: 0.8;
	transition: all 0.5s;
}

.button-two {
	cursor: pointer;
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: var(--c-grey);
	width: 100%;
	max-width: 313px;
	height: 70px;
	top: 0;
	text-decoration: none !important;
	border: 0;
	opacity: 1;
	outline: none !important;
	border-radius: 100px;
	transition: all 0.5s;
}

.button-two span {
	font-family: var(--font-family);
	font-weight: 800;
	font-size: 22px;
	line-height: 100%;
	letter-spacing: 0;
	color: var(--c-white);
	text-transform: uppercase;
	text-align: center;
	transition: all 0.5s;
}

.button-two:hover, .button-two:active, .button-two:focus {
	background: var(--c-red);
	opacity: 0.9;
	transition: all 0.5s;
}

.button-two:hover span, .button-two:active span, .button-two:focus span {
	color: var(--c-white);
	transition: all 0.5s;
}

.button-link {
	cursor: pointer;
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: transparent;
	width: 100%;
	max-width: 134px;
	height: 40px;
	top: 0;
	text-decoration: none !important;
	border: 0;
	opacity: 1;
	outline: none !important;
	border-radius: 0;
	transition: all 0.5s;
}

.button-link span {
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 24px;
	line-height: 100%;
	letter-spacing: 0;
	color: var(--c-red);
	text-decoration: underline;
	text-decoration-skip-ink: none;
	transition: all 0.5s;
}

.button-link:hover, .button-link:active, .button-link:focus {
	background: transparent;
	opacity: 0.8;
	transition: all 0.5s;
}

.button-link:hover span, .button-link:active span, .button-link:focus span {
	color: var(--c-red);
	transition: all 0.5s;
}

.button-form {
	cursor: pointer;
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: var(--c-red);
	width: 100%;
	max-width: 408px;
	height: 80px;
	top: 0;
	text-decoration: none !important;
	border: 0;
	opacity: 1;
	outline: none !important;
	border-radius: 100px;
	transition: all 0.5s;
}

.button-form span {
	font-family: var(--font-family);
	font-weight: 800;
	font-size: 22px;
	line-height: 100%;
	letter-spacing: 0;
	color: var(--c-white);
	text-transform: uppercase;
	text-align: center;
	transition: all 0.5s;
}

.button-form:hover, .button-form:active, .button-form:focus {
	background: var(--c-red);
	opacity: 0.8;
	transition: all 0.5s;
}

.button-form:hover span, .button-form:active span, .button-form:focus span {
	color: var(--c-white);
	transition: all 0.5s;
}

.button:focus,
.button:active,
.button-two:focus,
.button-two:active,
.button-link:focus,
.button-link:active,
.button-form:focus,
.button-form:active,
.btn:focus,
.btn:active {
	outline: none !important;
	box-shadow: none !important;
}

.container {
	padding-left: 1rem;
	padding-right: 1rem;
	max-width: 1391px;
}

ul {
	padding-left: 0;
}

ul.squared li {
	position: relative;
	padding-left: 1.25rem;
	padding-bottom: 0.3rem;
	font-style: normal;
	font-weight: 500;
	font-size: 1.625rem;
	line-height: 120%;
	color: #232035;
}

ul.squared li:before {
	content: "";
	position: absolute;
	top: 7px;
	left: 5px;
	width: 6px;
	height: 6px;
	background-color: var(--c-red);
	border-radius: 2px;
}

ul.squared--yellow li:before {
	background: linear-gradient(204.37deg, #FFDF96 13.87%, #D6A32D 110.55%);
}

img {
	max-width: 100%;
	max-height: 100%;
}

[disabled] {
	cursor: not-allowed;
}

.pr {
	position: relative;
}

.padded {
	padding-top: 120px;
	padding-bottom: 120px;
}

.form__text {
	font-size: 16px;
	font-weight: 500;
	text-align: center;
	line-height: 1.4;
	margin-bottom: 20px;
}

.form__line {
	margin-bottom: 20px;
}

.form input {
	height: 69px;
	width: 100%;
	border-radius: 50px;
	background: #fff;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 16px;
	font-family: var(--font-family);
	outline: none;
	border: none;
	border: 1px solid #fff;
}

.form input:focus {
	border-color: var(--c-purple);
}

.form__title {
	font-size: 52px;
	font-weight: bold;
	font-family: var(--font-family);
	line-height: 1.1;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 30px;
}

.form__footer {
	margin-top: 5px;
	max-width: 65%;
}

.checkbox {
	position: relative;
	padding-left: 30px;
	font-size: 12px;
	display: block;
	margin-bottom: 0;
	line-height: 1.3;
	cursor: pointer;
	text-transform: none;
	color: rgba(255, 255, 255, 0.6);
}

.checkbox a {
	color: rgba(255, 255, 255, 0.6);
	text-decoration: underline;
}

.checkbox__input {
	position: absolute;
	width: 16px;
	height: 16px;
	border: 1px solid #bdc1c9;
	border-radius: 4px;
	top: 0;
	left: 0;
	opacity: 1 !important;
}

.checkbox input:checked + .checkbox__input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.checkbox input:checked + .checkbox__input:before {
	content: '';
	color: var(--c-white);
	background-image: url(../img/last/check.svg);
	background-repeat: no-repeat;
	background-position: center;
	width: 20px;
	height: 20px;
	padding-top: 1px;
	padding-left: 17px;
	opacity: 1;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.toggle-menu {
	display: none;
	width: 19px;
	height: 13px;
	position: relative;
}

.toggle-menu span:after,
.toggle-menu span:before {
	content: "";
	position: absolute;
	left: 0;
	top: 6px;
}

.toggle-menu span:after {
	top: 12px;
}

.toggle-menu span {
	position: relative;
	display: block;
}

.toggle-menu span,
.toggle-menu span:after,
.toggle-menu span:before {
	width: 100%;
	height: 2px;
	background-color: var(--c-main);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-border-radius: 2px;
	border-radius: 2px;
}

.toggle-menu.on span {
	background-color: transparent;
}

.toggle-menu.on span:before {
	-webkit-transform: rotate(45deg) translate(-1px, 0);
	-ms-transform: rotate(45deg) translate(-1px, 0);
	transform: rotate(45deg) translate(-1px, 0);
}

.toggle-menu.on span:after {
	-webkit-transform: rotate(-45deg) translate(5px, -5px);
	-ms-transform: rotate(-45deg) translate(5px, -5px);
	transform: rotate(-45deg) translate(5px, -5px);
}

.title {
	font-style: normal;
	font-weight: 600;
	font-size: 1.625rem;
	mix-blend-mode: normal;
	line-height: 1.2;
	font-feature-settings: 'pnum' on, 'lnum' on;
	letter-spacing: 0;
	color: var(--c-main);
}

.title .green {
	color: var(--c-green);
}

.title .white {
	color: var(--c-white);
}

.t-green {
	color: var(--c-t-green) !important;
}

.b-violet,
.t-violet {
	background: radial-gradient(50% 82.45% at 50% 50%, #9C7AE4 0%, #6B38C1 100%);
}

.t-violet {
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

.heading {
	font-style: normal;
	font-weight: 400;
	font-size: 2.125rem;
	line-height: 110%;
	text-transform: uppercase;
	color: var(--c-main);
}

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	clip: rect(0 0 0 0);
	overflow: hidden;
}

.form__answer .form__button {
	max-width: 100%;
	padding: 15px 10px;
}

.form__answer .form__button:hover {
	opacity: 1;
	color: #fff;
}

.lazy-loaded {
	opacity: 1;
	-webkit-transition: 0.1s;
	transition: 0.1s;
}

.buttons {
	padding: 2rem 0 0 0;
	margin: 0 0 0 auto;
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

@media (max-width: 1509px) {
	.container {
		max-width: 100%;
	}
}

.swiper__nav {
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
	padding: 1rem 0 0 0;
	gap: 1rem;
}

.swiper-button {
	margin-top: 0;
	position: relative;
	top: auto;
	left: auto;
	right: auto;
	bottom: auto;
	width: 3.75rem;
	height: 3.75rem;
	background: var(--c-white);
	box-shadow: 0px 2px 10px #36363661;
	border-radius: 100%;
	color: var(--c-black);
}

.swiper-button::after {
	font-size: 1rem;
	font-weight: 600;
}

.swiper-pagination {
	position: relative;
	top: auto !important;
	left: auto !important;
	right: auto !important;
	bottom: auto !important;
}

.swiper-pagination-bullet {
	border-radius: 10px;
}

.header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	background-color: transparent;
	margin: 0 auto;
}

.header-wrapper {
	display: flex;
	justify-content: space-between;
	padding-top: 2rem;
	padding-bottom: 0;
	align-items: stretch;
}

.header-wrapper__logo {
	width: 200px;
}

.header-wrapper__logo img {
	width: 100%;
}

.header-wrapper__buttons {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.header-wrapper__buttons a svg {
	height: 30px;
	width: auto;
}

.header .nav {
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-left: 0.5rem;
	position: relative;
	z-index: 10;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 20px;
	line-height: 1;
	color: var(--c-black);
	width: 100%;
	max-width: 747px;
	justify-content: space-evenly;
}

.header .nav a {
	color: var(--c-black);
}

.header .nav__subitem {
	display: none;
	position: absolute;
	top: 4.6rem;
	left: -5rem;
	z-index: 11;
	flex-direction: column;
	background: #FFFFFF;
	mix-blend-mode: normal;
	box-shadow: 0px 20px 32px rgba(24, 24, 24, 0.32);
	padding: 1rem 3rem 3rem 3rem;
	gap: 0.6rem;
	transition: all 1.5s;
	width: max-content;
}

.header .nav__item {
	height: 100%;
	box-sizing: border-box;
	position: relative;
}

.header .nav__item:hover .nav__subitem {
	display: flex;
}

.header .nav__top {
	display: flex;
	height: 100%;
	box-sizing: border-box;
	align-items: center;
	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
}

.header .nav__top:hover {
	border-bottom: 2px solid #000;
}

.header .nav__sub {
	font-style: normal;
	font-weight: 500;
	font-size: 1.0625rem;
	line-height: 110%;
}

.header .nav__sub:hover {
	text-decoration-line: underline;
}

.header .nav__sub--title {
	font-style: normal;
	font-weight: 700;
	margin-top: 1rem;
}

.header__buttons-btn {
	height: 52px;
	padding: 0 1.5rem;
	border-radius: 50px;
	font-weight: 600;
	font-size: 20px;
	text-transform: none;
}

.header__buttons-btn svg {
	display: none;
}

.header.fix {
	position: fixed;
	top: -1px;
	background-color: var(--c-white);
	box-shadow: 0px 2px 10px #36363661;
}

.header.fix .header-wrapper {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.header.fix .row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 20px;
	padding-bottom: 20px;
	transition: all 0.5s;
}

.header .row {
	height: 100%;
	justify-content: space-between;
	padding-top: 31px;
	padding-bottom: 20px;
	transition: all 0.5s;
}

.header__box {
	position: relative;
	top: -3px;
	width: 100%;
	max-width: 202px;
}

.header__box-btn__thanks {
	display: none;
}

.header-mobile {
	display: none;
}

.header-mobile__open {
	display: none;
}

.header .toggle-menu {
	display: none;
	width: 43px;
	height: 43px;
	position: relative;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow: hidden;
	background-color: var(--c-grey);
	border-radius: 10px;
}

.header .toggle-menu span {
	width: 100%;
	max-width: 31px;
	height: 2px;
	background: #000;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-radius: 2px;
	margin: 0 auto;
	margin-top: 14px;
	box-sizing: border-box;
}

.header .toggle-menu span:after, .header .toggle-menu span:before {
	box-sizing: border-box;
	content: "";
	position: absolute;
	left: 0;
	top: 6px;
	width: 100%;
	max-width: 31px;
	height: 2px;
	background: #000;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-radius: 2px;
	margin: 0 auto;
}

.header .toggle-menu span:after {
	top: 12px;
}

.header-close {
	display: none;
}

.first {
	margin-top: 6.6rem;
	margin-bottom: 0;
	background-position: top right;
	background-repeat: no-repeat;
	background-size: auto;
	position: relative;
	box-sizing: border-box;
	overflow: hidden;
	padding: 0 0 0 0;
	z-index: 1;
	height: 493px;
}

.first .container {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 100%;
	padding: 0;
}

.first__info {
	background: linear-gradient(135deg, white 0%, rgba(255, 255, 255, 0.59) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	box-sizing: border-box;
	padding: 5rem 4rem 3rem 6rem;
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	z-index: 3;
	width: 100%;
	max-width: 672px;
}

.first__img {
	display: none;
}

.first__subheading {
	display: block;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 26px;
	line-height: 100%;
	letter-spacing: 0;
	color: var(--c-white);
	margin-bottom: 18px;
}

.first__heading {
	margin-bottom: 1rem;
	margin-bottom: 1rem;
	max-width: 800px;
}

.first__heading .special {
	position: relative;
	top: 9px;
	display: block;
}

.first__heading .red {
	position: relative;
	top: -7px;
	display: block;
}

.first__description {
	font-family: var(--font-family);
	line-height: 120%;
	font-style: normal;
	font-weight: 500;
	font-size: 1.125rem;
	margin-bottom: 0;
}

.first__buttons {
	padding: 2rem 0 0 0;
	margin: 0 0 0 auto;
}

.main .first {
	padding: 0;
}

.about {
	padding-top: 0;
	padding-bottom: 0;
	background: linear-gradient(to right, #ffffff 0%, #ffffff 50%, var(--c-grey) 51%, var(--c-grey) 100%);
}

.about .container {
	background: var(--c-greyer);
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 0;
	padding-left: 6rem;
}

.about__box {
	background: var(--c-grey);
	padding: 4rem 0 4rem 4rem;
}

.about__heading {
	padding-left: 4rem;
	margin-bottom: 6rem;
}

.about .swiper {
	width: 100%;
	height: auto;
}

.about .swiper-wrapper {
	align-items: stretch;
}

.about .swiper-slide {
	display: flex;
	box-sizing: border-box;
	width: 100%;
	max-width: 380px;
	height: auto;
	padding: 0 1rem 0 0;
	border-right: 2px solid #A09F9F;
}

.about .swiper-slide:last-child {
	border-right: none;
}

.about .swiper__nav {
	justify-content: space-evenly;
}

.about__img {
	margin-right: 0.5rem;
}

.about__name {
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	font-style: normal;
	font-weight: 500;
	font-size: 1.0625rem;
	line-height: 20px;
	mix-blend-mode: normal;
}

.vidy {
	padding-top: 0;
	padding-bottom: 0;
}

.vidy .container {
	background: var(--c-greyer);
	padding-top: 4rem;
	padding-bottom: 4rem;
	padding-right: 0;
	padding-left: 0;
}

.vidy__box {
	height: 667px;
	padding-right: 6rem;
}

.vidy__slider {
	width: 100%;
	height: 100%;
}

.vidy__info {
	width: 100%;
	max-width: 461px;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 6rem 4rem;
	background: linear-gradient(135deg, white 0%, rgba(255, 255, 255, 0.59) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.vidy__heading {
	padding-left: 6rem;
	margin-bottom: 4rem;
	color: var(--c-white);
}

.vidy .swiper {
	width: 100%;
	height: 100%;
	margin-right: auto;
}

.vidy .swiper-wrapper {
	align-items: stretch;
}

.vidy .swiper-slide {
	display: flex;
	box-sizing: border-box;
	width: 100%;
	flex-direction: column;
	background-repeat: no-repeat;
	background-position: top right;
	background-size: cover;
	height: auto;
}

.vidy .swiper-slide:last-child {
	border-right: none;
}

.vidy .swiper__nav {
	margin-top: auto;
	justify-content: space-around;
}

.vidy__count {
	margin-bottom: 3rem;
	font-style: normal;
	font-weight: 100;
	font-size: 2.875rem;
	line-height: 110%;
	text-transform: uppercase;
	color: #434951;
	mix-blend-mode: normal;
}

.vidy__count span:first-child {
	font-style: normal;
	font-weight: 400;
	font-size: 4.1875rem;
	color: #000000;
}

.vidy__name {
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	font-style: normal;
	font-weight: 600;
	font-size: 1.6875rem;
	mix-blend-mode: normal;
	margin-bottom: 2rem;
}

.dlya {
	padding-top: 0;
	padding-bottom: 0;
}

.dlya__bg {
	background: linear-gradient(to right, #ffffff 0%, #ffffff 50%, var(--c-grey) 51%, var(--c-grey) 100%);
}

.dlya .container {
	background: var(--c-greyer);
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 0;
	padding-left: 6rem;
}

.dlya__box {
	background: var(--c-grey);
	padding: 0;
}

.dlya__heading {
	padding-bottom: 4rem;
	margin-bottom: 0;
	color: var(--c-white);
}

.dlya__img {
	margin-bottom: 2rem;
	min-height: 116px;
	display: flex;
	align-items: center;
}

.dlya .swiper {
	width: 100%;
	height: auto;
}

.dlya .swiper-wrapper {
	align-items: stretch;
}

.dlya .swiper-slide {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	max-width: 320px;
	height: auto;
	padding: 3rem 2rem 4rem 3rem;
	border-right: 7px solid var(--c-greyer);
}

.dlya .swiper-slide:last-child {
	border-right: none;
}

.dlya .swiper__nav {
	padding: 2rem 0 3rem;
}

.dlya__name {
	margin-bottom: 1rem;
	font-style: normal;
	font-weight: 600;
	font-size: 1.25rem;
}

.dlya__text {
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	font-style: normal;
	font-weight: 500;
	font-size: 1.0625rem;
	line-height: 20px;
	mix-blend-mode: normal;
}

.komu {
	padding-top: 0;
	padding-bottom: 0;
	background: var(--c-greyer);
}

.komu .container {
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 0;
	padding-left: 0;
	position: relative;
	height: 100%;
	width: 100%;
	max-width: 100%;
}

.komu__box {
	height: 623px;
}

.komu__slider {
	width: 100%;
	max-width: 1555px;
	height: 100%;
	margin-right: 6rem;
}

.komu__info {
	width: 100%;
	max-width: 555px;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 6rem 4rem;
	margin-left: auto;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, #FFFFFF 100%);
	mix-blend-mode: normal;
}

.komu__heading {
	padding-left: 6rem;
	margin-bottom: 4rem;
	color: var(--c-white);
}

.komu .swiper {
	width: 100%;
	height: 100%;
	margin-right: auto;
}

.komu .swiper-wrapper {
	align-items: stretch;
}

.komu .swiper-slide {
	display: flex;
	box-sizing: border-box;
	width: 100%;
	flex-direction: column;
	background-repeat: no-repeat;
	background-position: top right;
	background-size: cover;
	height: auto;
}

.komu .swiper-slide:last-child {
	border-right: none;
}

.komu .swiper__nav {
	justify-content: space-evenly;
	margin-top: auto;
}

.komu__count {
	margin-bottom: 1rem;
	font-style: normal;
	font-weight: 100;
	font-size: 2.875rem;
	line-height: 110%;
	text-transform: uppercase;
	text-align: right;
	color: #434951;
	mix-blend-mode: normal;
}

.komu__count span:first-child {
	font-style: normal;
	font-weight: 400;
	font-size: 4.1875rem;
	color: #000000;
}

.komu__name {
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	font-style: normal;
	font-weight: 600;
	font-style: normal;
	font-weight: 600;
	font-size: 2.125rem;
	margin-bottom: 4rem;
}

.komu__text {
	font-style: normal;
	font-weight: 600;
	font-size: 1.6875rem;
}

.program .container {
	position: relative;
}

.program .container::after {
	content: '';
	position: absolute;
	top: 0;
	height: 650px;
	left: 100%;
	width: 10000px;
	transform: translateY(-650px);
	background: var(--c-white);
}

.preim {
	padding-top: 5rem;
	padding-bottom: 3.75rem;
}

.preim .container {
	display: flex;
}

.preim__heading {
	margin-bottom: 51px;
}

.preim__heading .special {
	display: block;
	margin-bottom: -72px;
}

.preim__info {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin: 0;
}

.preim__list {
	counter-reset: section;
	list-style-type: none;
	justify-content: space-between;
	box-sizing: border-box;
	overflow: hidden;
	padding: 0;
	margin: 0;
	border-top: 1px solid #323232;
	border-bottom: 1px solid #323232;
}

.preim__list-item {
	position: relative;
	padding: 0.5rem;
	box-sizing: border-box;
	margin: 0.5rem;
	font-family: var(--font-family);
	font-size: 26px;
	line-height: 120%;
	color: var(--c-white);
	border-radius: 30px;
	width: 100%;
	max-width: calc((100% / 3) - 1rem);
}

.preim__list-item::before {
	counter-increment: section;
	content: "0" counters(section, ".");
	position: relative;
	display: block;
	padding-bottom: 0.5rem;
	font-family: var(--font3);
	font-size: 70px;
	line-height: 100%;
	text-transform: uppercase;
	letter-spacing: 0;
	background: radial-gradient(50% 82.45% at 50% 50%, #9C7AE4 0%, #6B38C1 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

.preim__img {
	position: relative;
	box-sizing: border-box;
	margin-left: 75px;
	display: flex;
	flex-direction: column;
	min-width: 480px;
}

.preim__img img {
	margin-bottom: 25px;
}

.course {
	padding-bottom: 60px;
}

.course .container {
	padding: 65px 100px;
	box-sizing: border-box;
	backdrop-filter: blur(7.5px);
	border-radius: 30px;
	background-repeat: no-repeat;
	background-position-x: 90%;
	background-position-y: 10%;
}

.course__heading {
	margin-bottom: 51px;
	color: var(--c-black);
	font-size: 60px;
}

.course__heading .special {
	font-size: 60px;
	display: block;
	margin-bottom: -72px;
}

.course__info {
	display: flex;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin: 0 -25px;
	box-sizing: border-box;
	font-family: var(--font3);
	font-style: normal;
	font-weight: 400;
	font-size: 2rem;
	line-height: 130%;
}

.course__item {
	box-sizing: border-box;
	position: relative;
	margin: 25px;
	font-family: var(--font-family);
	width: calc(50% - 50px);
	font-size: 26px;
	line-height: 120%;
	color: var(--c-white);
}

@media (max-width: 1880px) {
	.course__list {
		margin-left: -180px;
	}
}

@media (max-width: 1840px) {
	.course__list {
		margin-left: -100px;
	}
}

@media (max-width: 1700px) {
	.course__list {
		margin-left: 0;
	}
}

@media (max-width: 1200px) {
	.course__info {
		flex-wrap: wrap;
	}
	.course__item {
		width: 100%;
	}
}

.ideal {
	padding-top: 180px;
	padding-bottom: 60px;
}

.ideal__heading {
	margin-bottom: 51px;
}

.ideal__heading .special {
	display: block;
	margin-bottom: -72px;
}

.ideal__list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	box-sizing: border-box;
	overflow: hidden;
	padding: 0;
	margin: 0 -0.5rem;
}

.ideal__item {
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0.5rem;
	font-family: var(--font-family);
	font-size: 26px;
	line-height: 120%;
	color: var(--c-white);
	box-sizing: border-box;
	width: 100%;
	max-width: 480px;
	background: var(--c-greyer);
	backdrop-filter: blur(7.5px);
	border-radius: 30px;
}

.ideal__text {
	padding: 1rem;
}

.ideal__number {
	font-family: var(--font3);
	font-size: 50px;
	line-height: 100%;
	text-transform: uppercase;
	color: var(--c-green);
	letter-spacing: 0;
	padding: 0 0 1rem 0;
}

.ideal__text {
	margin-top: auto;
}

.team {
	position: relative;
	padding-top: 120px;
	padding-bottom: 0;
	z-index: 1;
}

.team .container {
	display: flex;
	justify-content: space-between;
	padding: 65px 100px;
	box-sizing: border-box;
	backdrop-filter: blur(7.5px);
	border-radius: 30px;
}

.team-wrapper {
	position: relative;
	width: 100%;
	max-width: 100%;
}

.team__info {
	display: flex;
	flex-direction: column;
}

.team__heading {
	font-size: 64px;
	line-height: 100%;
	margin-bottom: 24px;
	color: var(--c-white);
}

.team__heading .special {
	position: relative;
	top: 8px;
	display: block;
	font-size: 84px;
	line-height: 100%;
}

.team__heading .white {
	position: relative;
	top: -6px;
	display: block;
}

.team__description {
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 26px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);
	margin: auto 0 0 0;
	margin-left: 1px;
}

.team-form {
	width: 100%;
	max-width: 480px;
	box-sizing: border-box;
	overflow: hidden;
	border: none;
	border-radius: 30px;
	padding: 0 0 0 30px;
	font-family: var(--font-family);
	font-weight: 600;
	font-size: 26px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);
	z-index: 1;
}

.team-form .form .h1 {
	font-family: var(--font-family);
	font-weight: 600;
	font-size: 26px;
	line-height: 120%;
	letter-spacing: 0;
	text-align: center;
	color: var(--c-white);
	margin-bottom: 48px;
}

.team-form .form__lines {
	position: relative;
	width: 100%;
	max-width: 408px;
	margin-left: auto;
	margin-right: auto;
}

.team-form .form__lines .row {
	max-width: 408px;
	margin: 0 auto;
}

.team-form .form__lines .row .col-lg-3 {
	flex: none;
	width: 100%;
	max-width: 408px;
	height: 80px;
	padding: 0;
	margin-bottom: 20px;
}

.team-form .form__lines .row .col-lg-3:last-child {
	height: fit-content;
	margin-top: 3px;
	margin-bottom: 0;
}

.team-form .form__line {
	margin-bottom: 0;
}

.team-form .form__input {
	width: 100%;
	max-width: 408px;
	height: 80px;
	background-color: var(--c-white);
	border: 1px solid #e8e5e1;
	border-radius: 100px;
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 22px;
	line-height: 120%;
	letter-spacing: 0;
	color: #9b9b9b;
	padding-top: 0;
	padding-left: 31px;
}

.team-form .form__input::placeholder {
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 22px;
	line-height: 120%;
	letter-spacing: 0;
	color: #9b9b9b;
}

.team-form .form__confirmation {
	max-width: 100%;
	margin-top: 0;
	margin-left: 0;
}

.team-form .form__confirmation .checkbox {
	padding-left: 20px;
}

.team-form .form__confirmation .checkbox__input {
	top: 0;
	left: 0;
	border: 1px solid var(--c-black);
	border-radius: 2px;
	width: 16px;
	height: 16px;
}

.team-form .form__confirmation .checkbox input:checked + .checkbox__input {
	top: 0;
	left: 0;
	border: 1px solid var(--c-black);
	border-radius: 2px;
	width: 16px;
	height: 16px;
}

.team-form .form__confirmation .checkbox input:checked + .checkbox__input:before {
	position: absolute;
	top: 2px;
	left: 2px;
	background-image: url(../img/popup/check1.svg);
	background-repeat: no-repeat;
	background-position: center;
	width: 10px;
	height: 10px;
	padding-top: 0;
	padding-left: 0;
	filter: brightness(0);
}

.team-form .form__confirmation .checkbox span {
	font-family: var(--font4);
	font-weight: 300;
	font-size: 12px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);
}

.team-form .form__confirmation .checkbox span .privacy {
	color: var(--c-white);
	text-decoration: underline;
}

.team-form .form__confirmation .checkbox span br {
	display: none;
}

@media (max-width: 1509px) {
	.team__heading {
		font-size: 52px;
		line-height: 100%;
		margin-bottom: 24px;
	}
	.team__heading .special {
		top: 4px;
		font-size: 72px;
		line-height: 100%;
	}
	.team__heading .white {
		top: -8px;
	}
}

.team .button-form,
.team .button-form:hover,
.team .button-form:active,
.team .button-form:focus {
	background-color: var(--c-black);
}

.team .button-form span,
.team .button-form:hover span,
.team .button-form:active span,
.team .button-form:focus span {
	font-size: 20px;
}

.intensives {
	padding-top: 180px;
	padding-bottom: 60px;
}

.intensives__heading {
	max-width: 870px;
	margin-bottom: 51px;
}

.intensives__heading .special {
	display: block;
	margin-bottom: -13px;
}

.intensives__list {
	counter-reset: section;
	list-style-type: none;
	justify-content: space-between;
	width: 1920px;
	max-width: 1920px;
	height: 618px;
	box-sizing: border-box;
	overflow: hidden;
	padding: 0;
	margin: 0;
	margin-left: -220px;
	margin-bottom: 40px;
}

.intensives__list-item {
	flex: 0 0 100%;
	max-width: 479px;
}

.intensives__list-item .item-card {
	position: relative;
	display: flex;
	flex-direction: column;
	row-gap: 31px;
	width: 100%;
	max-width: 100%;
	min-height: 617px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: var(--c-white);
	border-radius: 30px;
	padding: 71px 19px 20px 21px;
	margin: 0;
	z-index: 1;
}

.intensives__list-item .item-card::before {
	counter-increment: section;
	content: "(0" counters(section, ".") ")";
	position: absolute;
	top: 23px;
	left: 19px;
	width: 58px;
	height: 31px;
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 26px;
	line-height: 100%;
	letter-spacing: 0;
	text-align: center;
	color: var(--c-black);
	z-index: -1;
}

.intensives__list-item .item-card__image {
	position: relative;
	width: 100%;
	max-width: 440.63px;
	min-height: 270px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: #7c7c7c;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 30px;
}

.intensives__list-item .item-card__info {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 100%;
	min-height: 205px;
	padding: 0;
	margin: 0;
}

.intensives__list-item .item-card__info-title {
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 22px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	margin-bottom: auto;
}

.intensives__list-item .item-card__info-buttons {
	width: 100%;
	max-width: 300px;
	height: 70px;
	margin-left: 1px;
}

.intensives__list-item.online .item-card::after {
	content: "(онлайн)";
	position: absolute;
	top: 23px;
	right: 18px;
	width: 115px;
	height: 31px;
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 26px;
	line-height: 100%;
	letter-spacing: 0;
	text-align: right;
	color: var(--c-red);
	z-index: -1;
}

.intensives__slider-arrows {
	width: 100%;
	max-width: 150px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	align-content: center;
	margin: 0 auto;
}

.intensives__slider-arrows .arrows {
	width: 100%;
	max-width: 100%;
	height: 60px;
	display: flex !important;
	justify-content: space-between;
}

.intensives__slider-arrows .arrows-arrow {
	display: block !important;
	cursor: pointer;
	width: 60px;
	height: 60px;
	border: none;
	transition: all 0.3s ease;
}

.intensives__slider-arrows .arrows .prev {
	background: url(../img/common/prev.svg) 50% 50%/100% no-repeat;
	margin-right: 11px;
}

.intensives__slider-arrows .arrows .prev.slick-disabled {
	opacity: 0.8;
}

.intensives__slider-arrows .arrows .next {
	background: url(../img/common/next.svg) 50% 50%/100% no-repeat;
}

.intensives__slider-arrows .arrows .next.slick-disabled {
	opacity: 0.8;
}

.intensives-slider {
	display: flex;
	justify-content: center;
	min-width: 0;
}

.intensives-slider .slick-slide {
	width: 100%;
	max-width: 509px;
}

.intensives-slider .slick-list {
	overflow: hidden;
}

.intensives-slider .slick-track {
	display: flex;
}

@media (max-width: 1880px) {
	.intensives__list {
		margin-left: -180px;
	}
}

@media (max-width: 1840px) {
	.intensives__list {
		margin-left: -100px;
	}
}

@media (max-width: 1700px) {
	.intensives__list {
		margin-left: 0;
	}
}

.space {
	padding-top: 38px;
	padding-bottom: 60px;
}

.space__heading {
	max-width: 870px;
	margin-bottom: 11px;
}

.space__heading .special {
	display: block;
	margin-bottom: -13px;
}

.space__list {
	position: relative;
	justify-content: space-between;
	row-gap: 0;
	padding: 0;
	margin: 0 3px 0 1px;
	z-index: 0;
}

.space__list-item {
	cursor: pointer;
	flex: 0 0 100%;
	max-width: 100%;
	padding-top: 40px;
	margin-left: auto;
	opacity: 1;
	transition: all 0.5s;
}

.space__list-item .item-card {
	justify-content: space-between;
	width: 100%;
	max-width: 100%;
	min-height: 117px;
	box-sizing: border-box;
	background-color: transparent;
	border-bottom: 2px solid var(--c-red);
	padding: 0;
	margin: 0;
	transition: all 0.5s;
}

.space__list-item .item-card__title {
	flex: 0 0 100%;
	max-width: 476px;
	font-family: var(--font-family);
	font-weight: 800;
	font-size: 36px;
	line-height: 120%;
	letter-spacing: 0.4px;
	text-transform: uppercase;
	color: var(--c-white);
	padding-top: 15px;
	margin-bottom: 0;
	transition: all 0.5s;
}

.space__list-item .item-card__image {
	pointer-events: none;
	position: absolute;
	top: 181px;
	left: 50.7%;
	margin-right: -50%;
	transform: translate(-50%, 0%);
	flex: 0 0 100%;
	width: 100%;
	max-width: 400px;
	height: 400px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: #d9d9d9;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 30px;
	opacity: 0;
	transition: all 0.5s;
	z-index: 0;
}

.space__list-item .item-card__buttons {
	flex: 0 0 100%;
	max-width: 313px;
	height: 70px;
	transition: all 0.5s;
}

.space__list-item:hover, .space__list-item:active, .space__list-item:focus {
	z-index: 3;
	transition: all 0.5s;
}

.space__list-item:hover .item-card__image, .space__list-item:active .item-card__image, .space__list-item:focus .item-card__image {
	opacity: 1;
	transition: all 0.5s;
}

.space__list-item.item-1 {
	z-index: 1;
}

.space__list-item.item-1 .item-card__title {
	max-width: 326px;
}

.space__list-item.item-1 .item-card__image {
	opacity: 1;
	transition: all 0.5s;
}

.space__list-item.item-2 {
	max-width: 1229px;
	margin-right: -3px;
}

.space__list-item.item-2 .item-card__title {
	max-width: 316px;
}

.space__list-item.item-3 {
	margin-right: -3px;
}

.space__list-item.item-3 .item-card__title {
	max-width: 316px;
}

.space__list-item.item-4 {
	max-width: 1229px;
	margin-right: -3px;
}

.space__list-item.item-4 .item-card__title {
	max-width: 316px;
}

.tours {
	padding-top: 100px;
	padding-bottom: 60px;
}

.tours__heading {
	max-width: 870px;
	margin-bottom: 54px;
}

.tours__heading .special {
	display: block;
	margin-bottom: -13px;
}

.tours__list {
	justify-content: space-between;
	padding: 0;
	margin: 0;
}

.tours__list-item {
	flex: 0 0 100%;
	max-width: 730px;
}

.tours__list-item .item-card {
	position: relative;
	display: flex;
	flex-direction: column;
	row-gap: 23px;
	width: 100%;
	max-width: 100%;
	min-height: 651px;
	box-sizing: border-box;
	background-color: transparent;
	padding: 0;
	margin: 0;
}

.tours__list-item .item-card__image {
	width: 100%;
	max-width: 100%;
	height: fit-content;
	box-sizing: border-box;
	overflow: hidden;
	background-color: #d9d9d9;
	border-radius: 30px;
}

.tours__list-item .item-card__info {
	position: relative;
	justify-content: space-between;
	align-items: center;
	padding: 0;
	margin: 0;
}

.tours__list-item .item-card__info-title {
	position: relative;
	top: 3px;
	flex: 0 0 100%;
	max-width: fit-content;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 26px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);
	margin-bottom: 0;
}

.tours__list-item .item-card__info-buttons {
	flex: 0 0 100%;
	max-width: fit-content;
}

.tours__list-item.item-2 .item-card__image {
	max-width: 727px;
}

@media (max-width: 1509px) {
	.tours__list-item {
		max-width: 575px;
	}
}

.experts {
	padding-top: 98px;
	padding-bottom: 60px;
}

.experts__heading {
	max-width: 870px;
	margin-bottom: 51px;
}

.experts__heading .special {
	display: block;
	margin-bottom: -13px;
}

.experts__list {
	justify-content: space-between;
	row-gap: 50px;
	padding: 0;
	margin: 0;
}

.experts__list-item {
	flex: 0 0 100%;
	max-width: 100%;
}

.experts__list-item .item-card {
	position: relative;
	justify-content: space-between;
	width: 100%;
	max-width: 100%;
	min-height: 592px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: transparent;
	padding: 0;
	margin: 0;
}

.experts__list-item .item-card__image {
	flex: 0 0 100%;
	max-width: 555px;
	min-height: 592px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: #a76c59;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 30px;
}

.experts__list-item .item-card__info {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 0 0 100%;
	max-width: 855px;
	min-height: 592px;
	padding-top: 1px;
}

.experts__list-item .item-card__info-name {
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 50px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-green);
	margin-bottom: 17px;
}

.experts__list-item .item-card__info-name span {
	display: block;
	font-family: var(--second-family);
	font-weight: 400;
	font-size: 60px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);
	margin-bottom: -12px;
}

.experts__list-item .item-card__info-list {
	position: relative;
	width: 100%;
	max-width: 840px;
	padding: 0;
	margin: 0;
	margin-bottom: auto;
}

.experts__list-item .item-card__info-list__item {
	position: relative;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 26px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);
	padding-left: 34px;
	margin-bottom: 12px;
	z-index: 1;
}

.experts__list-item .item-card__info-list__item::before {
	content: "";
	position: absolute;
	top: 9px;
	left: 5px;
	width: 12px;
	height: 12px;
	background-color: var(--c-green);
	border-radius: 2px;
	z-index: -1;
}

.experts__list-item .item-card__info-list__item:last-child {
	margin-bottom: 0;
}

.experts__list-item .item-card__info-text {
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 853px;
	height: 59px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: #2d2d2d;
	border-radius: 15px;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);
	padding: 10px 25px 9px 16px;
	margin: 0;
}

.experts__list-item.item-2 .item-card__info-list {
	max-width: 821px;
}

.experts__slider-arrows {
	width: 100%;
	display: none;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.experts__slider-arrows .arrows {
	width: 100%;
	max-width: 100%;
	height: 60px;
	display: flex !important;
	justify-content: space-between;
}

.experts__slider-arrows .arrows-arrow {
	display: block !important;
	cursor: pointer;
	width: 60px;
	height: 60px;
	border: none;
	transition: all 0.3s ease;
}

.experts__slider-arrows .arrows .prev {
	background: url(../img/common/prev.svg) 50% 50%/100% no-repeat;
	margin-right: 11px;
}

.experts__slider-arrows .arrows .prev.slick-disabled {
	opacity: 0.8;
}

.experts__slider-arrows .arrows .next {
	background: url(../img/common/next.svg) 50% 50%/100% no-repeat;
}

.experts__slider-arrows .arrows .next.slick-disabled {
	opacity: 0.8;
}

.experts-slider {
	display: flex;
	justify-content: center;
	min-width: 0;
}

.experts-slider .slick-slide {
	width: 100%;
	max-width: 357px;
}

.experts-slider .slick-list {
	overflow: hidden;
}

.experts-slider .slick-track {
	display: flex;
}

@media (max-width: 1509px) {
	.experts__list-item .item-card__image {
		max-width: 435px;
		min-height: 652px;
	}
	.experts__list-item .item-card__info {
		max-width: 695px;
		min-height: 652px;
	}
	.experts__list-item .item-card__info-text {
		height: fit-content;
		min-height: 59px;
	}
}

.partners {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.partners__heading {
	max-width: 870px;
	margin-bottom: 51px;
}

.partners .swiper {
	width: 100%;
	height: auto;
}

.partners .swiper-slide {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	width: 217px;
	height: 217px;
	background: #FFFFFF;
	border-radius: 20px;
	padding: 1rem;
}

.partners .swiper-slide img {
	display: block;
	width: 100%;
	height: auto;
	object-fit: cover;
}

.programms {
	margin-top: 4rem;
	padding-top: 7rem;
	padding-bottom: 7rem;
	background: #ECEDF0;
}

.programms__heading {
	max-width: 870px;
	margin-bottom: 51px;
}

.programms .swiper {
	width: 100%;
	height: 750px;
	margin-bottom: 0;
}

.programms .swiper-slide {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	max-width: 453px;
	height: calc((100% - 30px) / 2) !important;
	background: #FFFFFF;
	border-radius: 20px;
	padding: 1rem;
	background-image: url("data:image/svg+xml,%3Csvg width='63' height='40' viewBox='0 0 63 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' fill='%23ECEDF0'/%3E%3Ccircle cx='43' cy='20' r='20' fill='%23ED131C'/%3E%3Cpath d='M50.0537 12.8037C50.3883 12.4851 50.8335 12.3091 51.2954 12.3126C51.7573 12.316 52.1998 12.4989 52.5295 12.8224C52.8592 13.1459 53.0503 13.5849 53.0625 14.0467C53.0747 14.5084 52.9071 14.9569 52.5949 15.2974L43.1187 27.1487C42.9558 27.3242 42.7591 27.465 42.5405 27.5628C42.3218 27.6605 42.0857 27.7132 41.8463 27.7177C41.6068 27.7221 41.369 27.6782 41.1469 27.5886C40.9248 27.499 40.723 27.3655 40.5537 27.1962L34.2694 20.9119C34.0944 20.7488 33.9541 20.5522 33.8567 20.3337C33.7594 20.1152 33.707 19.8793 33.7028 19.6401C33.6986 19.401 33.7426 19.1634 33.8322 18.9416C33.9217 18.7198 34.0551 18.5183 34.2242 18.3492C34.3934 18.18 34.5949 18.0467 34.8167 17.9571C35.0384 17.8675 35.276 17.8235 35.5152 17.8278C35.7544 17.832 35.9902 17.8843 36.2087 17.9817C36.4272 18.079 36.6239 18.2194 36.7869 18.3944L41.7602 23.3653L50.0086 12.8559L50.0537 12.8037Z' fill='white'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position-x: calc(100% - 1rem);
	background-position-y: calc(0% + 1rem);
}

.programms .swiper__nav {
	padding-bottom: 3rem;
}

.programms__type {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #7C7C7C;
	padding-bottom: 0.5rem;
}

.programms__name {
	max-width: 360px;
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
}

.programms__text {
	margin-top: auto;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
}

.programms__list {
	display: flex;
	margin: 0 -0.5rem;
	align-items: stretch;
	flex-wrap: wrap;
}

.programms__item {
	margin: 0.5rem;
	padding: 1rem;
	box-sizing: border-box;
	width: calc(50% - 1rem);
	height: 342px;
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
	background: #FFFFFF;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.programms__item a.button {
	margin-top: auto;
	text-decoration: underline !important;
}

.programms__item:nth-child(2) {
	padding: 0;
	background: url(../img/programms/img.jpg) no-repeat center center;
	background-size: cover;
}

.programms__info {
	display: flex;
	gap: 1rem;
	align-items: flex-start;
	justify-content: space-between;
}

.programms__icons {
	display: flex;
	gap: 1rem;
}

.programms__button {
	display: none;
}

.program .container {
	background: var(--c-greyer);
	padding-top: 4rem;
	padding-left: 6rem;
	padding-right: 0;
	padding-bottom: 0;
}

.program__heading {
	color: var(--c-white);
	margin-bottom: 3rem;
}

.program__list {
	display: flex;
	align-items: stretch;
	justify-content: space-evenly;
}

.program__item {
	border-top: 3px solid #CCCBCB;
	display: flex;
	flex-direction: column;
	position: relative;
	font-style: normal;
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	box-sizing: border-box;
	width: 100%;
	max-width: 332px;
	height: 100%;
	flex-shrink: inherit;
	width: 100%;
	max-width: 354px;
	min-width: 335px;
	height: auto;
}

.program__item :hover img {
	filter: grayscale(0);
}

.program .swiper {
	background: var(--c-white);
	padding: 3rem;
}

.program__date {
	padding: 0.3rem 0 2rem 0;
}

.program__img {
	display: flex;
	overflow: hidden;
	margin-bottom: 1rem;
	height: 100%;
	max-height: 231px;
}

.program__img img {
	width: 100%;
	height: auto;
	filter: grayscale(1);
}

.program__text {
	display: flex;
	flex-direction: column;
	padding: 1rem 0;
	margin-top: auto;
	height: 100%;
	font-style: normal;
	font-weight: 500;
	font-size: 1.0625rem;
}

.program__text .program__buttons {
	margin-top: auto;
}

.program__title {
	font-style: normal;
	font-weight: 600;
	font-size: 1.625rem;
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
}

.program__number {
	margin: 1rem 0 2rem;
	width: 100%;
}

.programma {
	padding-top: 70px;
	padding-bottom: 130px;
}

.programma__heading {
	max-width: 100%;
	margin-bottom: 39px;
}

.programma__wrapper {
	width: 100%;
}

.programma__list {
	position: relative;
	justify-content: space-between;
	row-gap: 20px;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin: 0;
}

.programma__list-item {
	flex: 0 0 100%;
	max-width: 100%;
}

.programma__list-item .item-card {
	cursor: pointer;
	position: relative;
	max-width: 100%;
	min-height: 66px;
	overflow: hidden;
	box-sizing: border-box;
	background: #1F1F1F;
	backdrop-filter: blur(7.5px);
	border-radius: 30px;
	padding: 2rem 2rem;
	opacity: 1;
	transition: all 0.5s ease;
}

.programma__list-item .item-card__header {
	position: relative;
	max-width: 100%;
	height: 100%;
	margin-bottom: 0;
	transition: all 0.5s ease;
	display: flex;
	align-items: center;
}

.programma__list-item .item-card__header span {
	color: var(--c-green);
}

.programma__list-item .item-card__header-number {
	font-family: var(--font3);
	font-size: 40px;
	line-height: 100%;
	text-transform: uppercase;
	color: var(--c-green);
	letter-spacing: 0;
	padding: 0 1rem 0 0;
}

.programma__list-item .item-card__header-title {
	max-width: calc(100% - 9rem);
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 26px;
	line-height: 100%;
	letter-spacing: 0;
	color: var(--c-white);
	margin-bottom: 0;
	transition: all 0.5s ease;
}

.programma__list-item .item-card__header-arrow {
	position: absolute;
	top: 0;
	right: -5px;
	background: transparent;
	transition: all 0.5s ease;
	width: 45px;
	height: 45px;
	border-radius: 45px;
	background: #0A0A0A;
	display: flex;
	justify-content: center;
	align-items: center;
}

.programma__list-item .item-card__header-arrow::before {
	content: "";
	background: url(../img/faq/arrow.svg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 20px;
	height: 12px;
	transform: rotate(0);
	opacity: 1;
	transition: all 0.5s ease;
}

.programma__list-item .item-card__body {
	width: calc(100% - 3rem);
	height: 0;
	box-sizing: border-box;
	overflow: hidden;
	transition: all 0.5s ease;
	padding-left: 4.4rem;
}

.programma__list-item .item-card__body-text {
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);
	margin-bottom: 0;
	transition: all 0.5s ease;
}

.programma__list-item .item-card__body ul {
	font-family: var(--font-family);
	font-style: normal;
	font-weight: 400;
	font-size: 22px;
	line-height: 120%;
	color: #C3C3C3;
	margin: 0 0 0 2rem;
}

.programma__list-item .item-card__body ul li {
	list-style: disc;
}

.programma__list-item .item-card:hover {
	opacity: 0.8;
	transition: all 0.5s ease;
}

.programma__list-item .item-card:hover .item-card__header-title {
	color: var(--c-white);
	transition: all 0.5s ease;
}

.programma__list-item.active .item-card__header {
	margin-bottom: 30px;
}

.programma__list-item.active .item-card__header-arrow::before {
	transform: rotate(180deg);
}

.programma__list-item.no-click {
	pointer-events: none !important;
}

.skills {
	padding-top: 180px;
	padding-bottom: 60px;
}

.skills__heading {
	margin-bottom: 51px;
}

.skills__heading .special {
	display: block;
	margin-bottom: -72px;
}

.skills__wrap {
	overflow-x: auto;
	padding-bottom: 0.5rem;
}

.skills__list {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	box-sizing: border-box;
}

.skills__item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	padding: 1rem;
	margin: 0.5rem;
	font-family: var(--font-family);
	font-size: 26px;
	line-height: 120%;
	color: var(--c-white);
	box-sizing: border-box;
	width: 100%;
	min-width: 300px;
	box-sizing: border-box;
	border: 1px solid var(--c-green);
	backdrop-filter: blur(7.5px);
	border-radius: 30px;
}

.skills__item:nth-child(1) {
	max-width: calc(480px - 1rem);
}

.skills__item:nth-child(2) {
	max-width: calc(589px - 1rem);
}

.skills__item:nth-child(3) {
	max-width: calc(393px - 1rem);
}

.skills__item:nth-child(4) {
	max-width: calc(683px - 1rem);
}

.skills__item:nth-child(5) {
	max-width: calc(777px - 1rem);
}

.skills__ico {
	margin-bottom: 1rem;
	width: 100%;
	display: none;
	justify-content: flex-end;
}

.skills__ico span {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70px;
	height: 70px;
	border-radius: 70px;
	background: radial-gradient(50% 82.45% at 50% 50%, #92B83E 0%, #72912D 100%);
}

.skills__slider-arrows {
	width: 100%;
	display: none;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.skills__slider-arrows .arrows {
	width: 100%;
	max-width: 100%;
	height: 60px;
	display: flex !important;
	justify-content: space-between;
}

.skills__slider-arrows .arrows-arrow {
	display: block !important;
	cursor: pointer;
	width: 60px;
	height: 60px;
	border: none;
	transition: all 0.3s ease;
}

.skills__slider-arrows .arrows .prev {
	background: url(../img/common/prev.svg) 50% 50%/100% no-repeat;
	margin-right: 11px;
}

.skills__slider-arrows .arrows .prev.slick-disabled {
	opacity: 0.8;
}

.skills__slider-arrows .arrows .next {
	background: url(../img/common/next.svg) 50% 50%/100% no-repeat;
}

.skills__slider-arrows .arrows .next.slick-disabled {
	opacity: 0.8;
}

.skills-slider {
	display: flex;
	justify-content: center;
	min-width: 0;
}

.skills-slider .slick-slide {
	width: 100%;
	max-width: 357px;
}

.skills-slider .slick-list {
	overflow: hidden;
	height: fit-content !important;
}

.skills-slider .slick-track {
	display: flex;
}

.price {
	position: relative;
	box-sizing: border-box;
	overflow: hidden;
	padding-top: 10rem;
	padding-bottom: 5rem;
	z-index: 1;
	background-image: url(../img/price/price.jpg);
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 30px;
}

.price .container {
	position: relative;
	z-index: 2;
}

.price__info {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.price__subheading {
	display: block;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 26px;
	line-height: 100%;
	letter-spacing: 0;
	color: var(--c-white);
	margin-bottom: 18px;
}

.price__heading {
	margin-bottom: 17px;
	margin-bottom: 17px;
	text-align: center;
}

.price__heading .special {
	position: relative;
	top: 9px;
	display: block;
}

.price__heading .red {
	position: relative;
	top: -7px;
	display: block;
}

.price__description {
	font-family: var(--font3);
	text-align: center;
	letter-spacing: 0;
	color: var(--c-white);
	margin-bottom: 0;
	font-weight: 400;
	font-size: 60px;
	line-height: 100%;
	text-transform: uppercase;
}

.price__description--old {
	text-decoration: line-through;
	font-family: var(--font-family);
	font-style: normal;
	font-weight: 400;
	font-size: 3.875rem;
	line-height: 100%;
	text-align: center;
	text-transform: uppercase;
	color: #9F9F9F;
}

.price__buttons {
	padding: 2rem 0;
	width: 100%;
	max-width: 100%;
}

.price__buttons .button {
	padding: 0 2rem;
	width: 100%;
	max-width: 100%;
}

.price__buttons .button span {
	color: var(--c-main);
}

.last {
	position: relative;
	padding-top: 120px;
	padding-bottom: 0;
	z-index: 1;
}

.last .container {
	display: flex;
	justify-content: space-between;
	padding: 65px 100px;
	box-sizing: border-box;
	backdrop-filter: blur(7.5px);
	border-radius: 30px;
}

.last-wrapper {
	position: relative;
	width: 100%;
	max-width: 100%;
}

.last__info {
	display: flex;
	flex-direction: column;
}

.last__heading {
	font-size: 64px;
	line-height: 100%;
	margin-bottom: 24px;
	color: var(--c-black);
}

.last__heading .special {
	position: relative;
	top: 8px;
	display: block;
	font-size: 84px;
	line-height: 100%;
}

.last__heading .white {
	position: relative;
	top: -6px;
	display: block;
}

.last__description {
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 26px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);
	margin: auto 0 0 0;
	margin-left: 1px;
}

.last-form {
	width: 480px;
	max-width: 480px;
	box-sizing: border-box;
	overflow: hidden;
	border: none;
	border-radius: 30px;
	padding: 0 0 0 30px;
	font-family: var(--font-family);
	font-weight: 600;
	font-size: 26px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);
	z-index: 1;
}

.last-form .form .h1 {
	font-family: var(--font-family);
	font-weight: 600;
	font-size: 26px;
	line-height: 120%;
	letter-spacing: 0;
	text-align: center;
	color: var(--c-white);
	margin-bottom: 48px;
}

.last-form .form__lines {
	position: relative;
	width: 100%;
	max-width: 408px;
	margin-left: auto;
	margin-right: auto;
}

.last-form .form__lines .row {
	max-width: 408px;
	margin: 0 auto;
}

.last-form .form__lines .row .col-lg-3 {
	flex: none;
	width: 100%;
	max-width: 408px;
	height: 80px;
	padding: 0;
	margin-bottom: 20px;
}

.last-form .form__lines .row .col-lg-3:last-child {
	height: fit-content;
	margin-top: 3px;
	margin-bottom: 0;
}

.last-form .form__line {
	margin-bottom: 0;
}

.last-form .form__input {
	width: 100%;
	max-width: 408px;
	height: 80px;
	background-color: var(--c-white);
	border: 1px solid #e8e5e1;
	border-radius: 100px;
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 22px;
	line-height: 120%;
	letter-spacing: 0;
	color: #9b9b9b;
	padding-top: 0;
	padding-left: 31px;
}

.last-form .form__input::placeholder {
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 22px;
	line-height: 120%;
	letter-spacing: 0;
	color: #9b9b9b;
}

.last-form .form__confirmation {
	max-width: 100%;
	margin-top: 0;
	margin-left: 0;
}

.last-form .form__confirmation .checkbox {
	padding-left: 20px;
}

.last-form .form__confirmation .checkbox__input {
	top: 0;
	left: 0;
	border: 1px solid var(--c-black);
	border-radius: 2px;
	width: 16px;
	height: 16px;
}

.last-form .form__confirmation .checkbox input:checked + .checkbox__input {
	top: 0;
	left: 0;
	border: 1px solid var(--c-black);
	border-radius: 2px;
	width: 16px;
	height: 16px;
}

.last-form .form__confirmation .checkbox input:checked + .checkbox__input:before {
	position: absolute;
	top: 2px;
	left: 2px;
	background-image: url(../img/popup/check1.svg);
	background-repeat: no-repeat;
	background-position: center;
	width: 10px;
	height: 10px;
	padding-top: 0;
	padding-left: 0;
	filter: brightness(0);
}

.last-form .form__confirmation .checkbox span {
	font-family: var(--font4);
	font-weight: 300;
	font-size: 12px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);
}

.last-form .form__confirmation .checkbox span .privacy {
	color: var(--c-white);
	text-decoration: underline;
}

.last-form .form__confirmation .checkbox span br {
	display: none;
}

@media (max-width: 1509px) {
	.last__heading {
		font-size: 52px;
		line-height: 100%;
		margin-bottom: 24px;
	}
	.last__heading .special {
		top: 4px;
		font-size: 72px;
		line-height: 100%;
	}
	.last__heading .white {
		top: -8px;
	}
}

.last .button-form,
.last .button-form:hover,
.last .button-form:active,
.last .button-form:focus {
	background-color: var(--c-black);
}

.faq {
	padding-top: 70px;
	padding-bottom: 130px;
}

.faq__heading {
	max-width: 100%;
	margin-bottom: 39px;
}

.faq__wrapper {
	width: 100%;
}

.faq__list {
	position: relative;
	justify-content: space-between;
	row-gap: 20px;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin: 0;
}

.faq__list-item {
	flex: 0 0 100%;
	max-width: 100%;
}

.faq__list-item .item-card {
	cursor: pointer;
	position: relative;
	max-width: 100%;
	min-height: 66px;
	overflow: hidden;
	box-sizing: border-box;
	background: #1F1F1F;
	backdrop-filter: blur(7.5px);
	border-radius: 30px;
	padding: 1rem 2rem;
	opacity: 1;
	transition: all 0.5s ease;
}

.faq__list-item .item-card__header {
	position: relative;
	max-width: 100%;
	height: 100%;
	margin-bottom: 0;
	transition: all 0.5s ease;
}

.faq__list-item .item-card__header-title {
	display: flex;
	align-items: center;
	max-width: 650px;
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 26px;
	line-height: 100%;
	letter-spacing: 0;
	color: var(--c-white);
	margin-bottom: 0;
	transition: all 0.5s ease;
}

.faq__list-item .item-card__header-arrow {
	position: absolute;
	top: -10px;
	right: -15px;
	width: 24px;
	height: 24px;
	background: transparent;
	transition: all 0.5s ease;
}

.faq__list-item .item-card__header-arrow::before {
	content: "";
	position: absolute;
	top: 11px;
	right: 1px;
	background: url(../img/faq/faq_arrow.svg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 16px;
	height: 18px;
	transform: rotate(0);
	opacity: 1;
	transition: all 0.5s ease;
}

.faq__list-item .item-card__body {
	height: 0;
	box-sizing: border-box;
	overflow: hidden;
	transition: all 0.5s ease;
}

.faq__list-item .item-card__body-text {
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);
	margin-bottom: 0;
	transition: all 0.5s ease;
}

.faq__list-item .item-card:hover {
	opacity: 0.8;
	transition: all 0.5s ease;
}

.faq__list-item .item-card:hover .item-card__header-title {
	color: var(--c-white);
	transition: all 0.5s ease;
}

.faq__list-item.active .item-card__header {
	margin-bottom: 30px;
}

.faq__list-item.active .item-card__header-arrow::before {
	transform: rotate(180deg);
}

.faq__list-item.no-click {
	pointer-events: none !important;
}

.footer {
	padding-top: 6rem;
	padding-bottom: 2rem;
	background: var(--c-grey);
	font-style: normal;
	font-weight: 500;
	font-size: 1.125rem;
	mix-blend-mode: normal;
}

.footer a {
	color: var(--c-black);
}

.footer__top {
	display: flex;
	gap: 2rem;
	box-sizing: border-box;
	align-items: flex-start;
	padding-bottom: 2rem;
}

.footer__desc {
	box-sizing: border-box;
	width: 50%;
	display: flex;
}

.footer__info {
	box-sizing: border-box;
	width: 50%;
	display: flex;
	gap: 3rem;
}

.footer__info--item {
	display: flex;
	gap: 2rem;
}

.footer__title {
	font-weight: 700;
	font-size: 1.0625rem;
	padding: 1.3rem 0;
	display: block;
}

.footer__logo {
	position: relative;
	cursor: pointer;
	display: block;
	opacity: 1;
	transition: all 0.5s;
}

.footer__logo:hover, .footer__logo:focus, .footer__logo:active {
	opacity: 0.8;
	transition: all 0.5s;
}

.footer__logo img {
	width: 207px;
}

.footer__slogan {
	border-left: 2px solid var(--c-black);
	margin-left: 3rem;
	padding-left: 1rem;
	max-width: 320px;
}

.footer__address {
	position: relative;
}

.footer__address--item {
	padding: 2rem 0 0;
}

.footer__address--link,
.footer__address a {
	opacity: 1;
	transition: all 0.5s;
}

.footer__address--link:hover, .footer__address--link:focus, .footer__address--link:active,
.footer__address a:hover,
.footer__address a:focus,
.footer__address a:active {
	color: var(--c-black);
	opacity: 0.8;
	transition: all 0.5s;
}

.footer__box {
	position: relative;
}

.footer__menu--list {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.footer__ps {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-style: normal;
	font-weight: 400;
	font-size: 0.9375rem;
}

.fancybox-content {
	background: none;
	padding: 0;
}

.fancybox-active {
	overflow: hidden;
}

.compensate-for-scrollbar {
	margin-right: 0 !important;
}

.fancybox-bg {
	background: rgba(45, 45, 45, 0.6) !important;
}

#modal-form {
	position: relative;
	width: 480px;
	max-width: 480px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: var(--c-greyer);
	border: 2px solid var(--c-greyer);
	border-radius: 30px;
	padding: 1.5rem 1rem;
	font-family: var(--font-family);
	font-weight: 600;
	font-size: 1.625rem;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);
}

#modal-form .form .h1 {
	font-family: var(--font-family);
	font-weight: 600;
	font-size: 26px;
	line-height: 120%;
	letter-spacing: 0;
	text-align: center;
	color: var(--c-white);
	margin-bottom: 47px;
}

#modal-form .form__lines {
	position: relative;
	width: 100%;
	max-width: 408px;
	margin-left: auto;
	margin-right: auto;
}

#modal-form .form__lines .row {
	max-width: 408px;
	margin: 0 auto;
}

#modal-form .form__lines .row .col-lg-3 {
	flex: none;
	width: 100%;
	max-width: 408px;
	height: 80px;
	padding: 0;
	margin-bottom: 20px;
}

#modal-form .form__lines .row .col-lg-3:last-child {
	height: fit-content;
	margin-top: 3px;
	margin-bottom: 0;
}

#modal-form .form__line {
	margin-bottom: 0;
}

#modal-form .form__button {
	width: 100%;
}

#modal-form .form__button span {
	font-weight: 500;
}

#modal-form .form__input {
	width: 100%;
	max-width: 408px;
	height: 64px;
	border-radius: 10px;
	background-color: var(--c-white);
	border: 1px solid #e8e5e1;
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 22px;
	line-height: 120%;
	letter-spacing: 0;
	color: #9b9b9b;
	padding-top: 0;
	padding-left: 31px;
}

#modal-form .form__input::placeholder {
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 22px;
	line-height: 120%;
	letter-spacing: 0;
	color: #9b9b9b;
}

#modal-form .form__confirmation {
	max-width: 100%;
	margin-top: 0;
	margin-left: 0;
}

#modal-form .form__confirmation .checkbox {
	padding-left: 20px;
}

#modal-form .form__confirmation .checkbox__input {
	top: 0;
	left: 0;
	border: 1px solid var(--c-white);
	border-radius: 2px;
	width: 16px;
	height: 16px;
}

#modal-form .form__confirmation .checkbox input:checked + .checkbox__input {
	top: 0;
	left: 0;
	border: 1px solid var(--c-white);
	border-radius: 2px;
	width: 16px;
	height: 16px;
}

#modal-form .form__confirmation .checkbox input:checked + .checkbox__input:before {
	position: absolute;
	top: 2px;
	left: 2px;
	background-image: url(../img/popup/check1.svg);
	background-repeat: no-repeat;
	background-position: center;
	width: 10px;
	height: 10px;
	padding-top: 0;
	padding-left: 0;
}

#modal-form .form__confirmation .checkbox span {
	font-family: var(--font4);
	font-weight: 300;
	font-size: 12px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);
}

#modal-form .form__confirmation .checkbox span .privacy {
	color: var(--c-white);
	text-decoration: underline;
}

#modal-form .form__confirmation .checkbox span br {
	display: none;
}

#modal-form .fancybox-close-small {
	top: 5px;
	right: 5px;
}

#modal-form .fancybox-close-small svg {
	color: var(--c-white);
}

.modal-intensives {
	position: relative;
	width: 1232px;
	max-width: 1232px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: var(--c-white);
	border: none;
	border-radius: 30px;
	color: var(--c-black);
	padding: 21px 20px 20px 20px;
	margin: 0;
}

.modal-intensives__card .m-card__heading {
	min-height: 77px;
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 32px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	margin-bottom: 39px;
}

.modal-intensives__card .m-card__heading .mob {
	display: none;
}

.modal-intensives__card .m-card__info {
	justify-content: space-between;
	padding: 0;
	margin: 0;
	margin-bottom: 30px;
}

.modal-intensives__card .m-card__info-left {
	position: relative;
	flex: 0 0 100%;
	max-width: 586px;
}

.modal-intensives__card .m-card__info-right {
	position: relative;
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	flex: 0 0 100%;
	max-width: 586px;
	min-height: 388px;
}

.modal-intensives__card .m-card__info-image {
	position: relative;
	width: 100%;
	max-width: 586px;
	min-height: 388px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: #7c7c7c;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 30px;
}

.modal-intensives__card .m-card__info-education {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 300px;
	height: 70px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: var(--c-green);
	border-radius: 30px 0;
	font-family: var(--font-family);
	font-weight: 800;
	font-size: 22px;
	line-height: 100%;
	letter-spacing: 0;
	text-transform: uppercase;
	text-align: center;
	color: var(--c-white);
	padding: 0;
	padding-left: 3px;
	margin: 0;
}

.modal-intensives__card .m-card__info-format {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin: 0;
}

.modal-intensives__card .m-card__info-format__heading {
	display: none;
	min-height: 120px;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	margin-left: 1px;
	margin-bottom: 16px;
}

.modal-intensives__card .m-card__info-format__list {
	position: relative;
	justify-content: space-between;
	row-gap: 10px;
	max-width: 489px;
	padding: 0;
	margin: 0;
	margin-bottom: 12px;
}

.modal-intensives__card .m-card__info-format__list-item {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 100%;
	max-width: fit-content;
	height: 49px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: #efefef;
	border-radius: 50px;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	padding: 10px 17px 8px 16px;
	margin: 0;
}

.modal-intensives__card .m-card__info-format__text {
	display: block;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 22px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	margin-bottom: 0;
}

.modal-intensives__card .m-card__info-whom {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin: 0;
}

.modal-intensives__card .m-card__info-whom__text {
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	margin-bottom: 8px;
}

.modal-intensives__card .m-card__info-whom__list {
	position: relative;
	padding: 0;
	margin: 0;
}

.modal-intensives__card .m-card__info-whom__list-item {
	position: relative;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 22px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	padding-left: 30px;
	margin-bottom: 3px;
	z-index: 1;
}

.modal-intensives__card .m-card__info-whom__list-item::before {
	content: "";
	position: absolute;
	top: 7px;
	left: 5px;
	width: 12px;
	height: 12px;
	background-color: var(--c-green);
	border-radius: 2px;
	z-index: -1;
}

.modal-intensives__card .m-card__info-whom__list-item:last-child {
	margin-bottom: 0;
}

.modal-intensives__card .m-card__info-documents {
	position: relative;
	top: 1px;
	width: 100%;
	max-width: 100%;
	min-height: 58px;
	font-family: var(--font-family);
	font-style: italic;
	font-weight: 500;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	padding-left: 70px;
	margin: 0;
	margin-top: auto;
	z-index: 1;
}

.modal-intensives__card .m-card__info-documents::before {
	content: "";
	position: absolute;
	top: 2px;
	left: 0;
	width: 55px;
	height: 55px;
	background-image: url(../img/popup/doc.svg);
	background-position: top right;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: -1;
}

.modal-intensives__card .m-card__topics {
	position: relative;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	overflow: hidden;
	background-color: #efefef;
	border-radius: 30px;
	padding: 21px 20px 20px 20px;
	margin: 0;
	margin-bottom: 21px;
}

.modal-intensives__card .m-card__topics-text {
	display: block;
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	margin-bottom: 7px;
}

.modal-intensives__card .m-card__topics-list {
	position: relative;
	padding: 0;
	margin: 0;
}

.modal-intensives__card .m-card__topics-list__item {
	position: relative;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 24px;
	line-height: 121%;
	letter-spacing: 0;
	color: var(--c-black);
	padding-left: 30px;
	margin-bottom: 8px;
	z-index: 1;
}

.modal-intensives__card .m-card__topics-list__item::before {
	content: "";
	position: absolute;
	top: 7px;
	left: 5px;
	width: 12px;
	height: 12px;
	background-color: var(--c-green);
	border-radius: 2px;
	z-index: -1;
}

.modal-intensives__card .m-card__topics-list__item:last-child {
	margin-bottom: 0;
}

.modal-intensives__card .m-card__speakers {
	position: relative;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin: 0;
}

.modal-intensives__card .m-card__speakers-text {
	display: block;
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	margin-bottom: 11px;
}

.modal-intensives__card .m-card__speakers-list {
	position: relative;
	justify-content: space-between;
	padding: 0;
	margin: 0;
}

.modal-intensives__card .m-card__speakers-list__item {
	flex: 0 0 100%;
	max-width: 586px;
}

.modal-intensives__card .m-card__speakers-list__item .item-card {
	position: relative;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	padding: 1px 40px 0 124px;
	margin: 0;
	z-index: 1;
}

.modal-intensives__card .m-card__speakers-list__item .item-card__photo {
	position: absolute;
	top: 0;
	left: 0;
	width: 100px;
	max-width: 100px;
	box-sizing: border-box;
	overflow: hidden;
	border-radius: 100%;
	z-index: -1;
}

.modal-intensives__card .m-card__speakers-list__item .item-card__info {
	position: relative;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin: 0;
}

.modal-intensives__card .m-card__speakers-list__item .item-card__info-name {
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	margin-bottom: 7px;
}

.modal-intensives__card .m-card__speakers-list__item .item-card__info-regalia {
	position: relative;
	padding: 0;
	margin: 0;
}

.modal-intensives__card .m-card__speakers-list__item .item-card__info-regalia__item {
	position: relative;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 24px;
	line-height: 121%;
	letter-spacing: 0;
	color: var(--c-black);
	padding-left: 30px;
	margin-bottom: 8px;
	z-index: 1;
}

.modal-intensives__card .m-card__speakers-list__item .item-card__info-regalia__item::before {
	content: "";
	position: absolute;
	top: 7px;
	left: 5px;
	width: 12px;
	height: 12px;
	background-color: var(--c-green);
	border-radius: 2px;
	z-index: -1;
}

.modal-intensives__card .m-card__speakers-list__item .item-card__info-regalia__item:last-child {
	margin-bottom: 0;
}

.modal-intensives__card .m-card__speakers-list__item.item-big {
	max-width: 100%;
}

.modal-intensives .fancybox-close-small {
	top: 23px;
	right: 23px;
	padding: 5px;
}

.modal-intensives .fancybox-close-small svg {
	color: #d3d3d3;
}

.modal-intensives.i-mini .modal-intensives__card .m-card__info-image {
	min-height: 400px;
}

.modal-intensives.i-mini .modal-intensives__card .m-card__info-format__list {
	row-gap: 12px;
	max-width: 533px;
	margin-bottom: 5px;
}

.modal-intensives.i-mini .modal-intensives__card .m-card__info-format__list-item:nth-child(1) {
	padding: 10px 16px 8px 16px;
}

.modal-intensives.i-mini .modal-intensives__card .m-card__info-documents {
	top: auto;
	font-size: 22px;
	line-height: 120%;
}

.modal-intensives.i-mini .modal-intensives__card .m-card__info-documents::before {
	top: 3px;
}

.modal-intensives.i-mini.i6 .modal-intensives__card .m-card__info-format__list {
	row-gap: 12px;
	max-width: 535px;
	margin-bottom: 5px;
}

.modal-intensives.i-mini.i6 .modal-intensives__card .m-card__info-format__list-item:nth-child(3) {
	padding: 10px 16px 8px 16px;
}

.modal-intensives.i-mini.i8 .modal-intensives__card .m-card__info-format__list {
	row-gap: 12px;
	max-width: 535px;
	margin-bottom: 5px;
}

.modal-intensives.i-mini.i8 .modal-intensives__card .m-card__info-format__list-item:nth-child(1) {
	margin-right: 100px;
}

.modal-intensives.i-mini.i8 .modal-intensives__card .m-card__info-format__list-item:nth-child(3) {
	padding: 10px 16px 8px 16px;
}

.modal-space {
	position: relative;
	width: 1232px;
	max-width: 1232px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: var(--c-white);
	border: none;
	border-radius: 30px;
	color: var(--c-black);
	padding: 21px 20px 20px 20px;
	margin: 0;
}

.modal-space__card .m-card__heading {
	min-height: 77px;
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 32px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	margin-bottom: 39px;
}

.modal-space__card .m-card__info {
	justify-content: space-between;
	padding: 0;
	margin: 0;
}

.modal-space__card .m-card__info-left {
	position: relative;
	flex: 0 0 100%;
	max-width: 586px;
}

.modal-space__card .m-card__info-right {
	position: relative;
	flex: 0 0 100%;
	max-width: 586px;
}

.modal-space__card .m-card__info-image {
	position: relative;
	width: 100%;
	max-width: 586px;
	min-height: 388px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: #7c7c7c;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 30px;
}

.modal-space__card .m-card__info-heading {
	display: none;
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	margin-left: 1px;
	margin-top: 31px;
	margin-bottom: 16px;
}

.modal-space__card .m-card__info-text {
	position: relative;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	margin-top: 31px;
	margin-bottom: 0;
}

.modal-space__card .m-card__info-text span {
	display: block;
	margin-bottom: 6px;
}

.modal-space__card .m-card__info-text span:last-child {
	margin-bottom: 0;
}

.modal-space__card .m-card__info-text.mr-0 {
	margin: 0 !important;
}

.modal-space__card .m-card__info-triggers {
	position: relative;
	padding: 0;
	margin: 0;
	margin-top: 8px;
	margin-bottom: 7px;
}

.modal-space__card .m-card__info-triggers__item {
	position: relative;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	padding-left: 34px;
	margin-bottom: 7px;
	z-index: 1;
}

.modal-space__card .m-card__info-triggers__item::before {
	content: "";
	position: absolute;
	top: 5px;
	left: 5px;
	width: 12px;
	height: 12px;
	background-color: var(--c-green);
	border-radius: 2px;
	z-index: -1;
}

.modal-space__card .m-card__info-triggers__item:nth-child(1)::before {
	top: 9px;
}

.modal-space__card .m-card__info-triggers__item:last-child {
	margin-bottom: 0;
}

.modal-space__card .m-card__info-list {
	position: relative;
	padding: 0;
	margin: 0;
}

.modal-space__card .m-card__info-list__item {
	width: 100%;
	max-width: 100%;
	margin-bottom: 24px;
}

.modal-space__card .m-card__info-list__item .item-card {
	position: relative;
	width: 100%;
	max-width: 100%;
	min-height: 66px;
	padding: 1px 20px 0 70px;
	margin: 0;
}

.modal-space__card .m-card__info-list__item .item-card__image {
	position: absolute;
	top: 6px;
	left: 0;
	width: 50px;
	height: 50px;
	box-sizing: border-box;
	overflow: hidden;
	border-radius: 100%;
	padding: 0;
	margin: 0;
}

.modal-space__card .m-card__info-list__item .item-card__text {
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	margin-bottom: 8px;
}

.modal-space__card .m-card__info-list__item .item-card__text .sub {
	display: block;
	font-weight: 700;
	margin-bottom: 8px;
}

.modal-space__card .m-card__info-list__item .item-card__text:last-child {
	margin-bottom: 0;
}

.modal-space__card .m-card__info-list__item:nth-child(1) .item-card__image {
	top: 10px;
}

.modal-space__card .m-card__info-list__item:nth-child(2) {
	margin-bottom: 19px;
}

.modal-space__card .m-card__info-list__item:nth-child(2) .item-card__image {
	top: 5px;
}

.modal-space__card .m-card__info-list__item:nth-child(3) {
	margin-bottom: 20px;
}

.modal-space__card .m-card__info-list__item:nth-child(3) .item-card__text .sub {
	margin-bottom: 0;
}

.modal-space__card .m-card__info-list__item:last-child {
	margin-bottom: 0;
}

.modal-space__card .m-card__info-topics {
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	overflow: hidden;
	background-color: #efefef;
	border-radius: 30px;
	padding: 21px 20px 20px 20px;
	margin: 0;
	margin-top: 31px;
}

.modal-space__card .m-card__info-topics__subtext {
	display: block;
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	margin-bottom: 8px;
}

.modal-space__card .m-card__info-topics__list {
	position: relative;
	padding: 0;
	margin: 0;
}

.modal-space__card .m-card__info-topics__list-item {
	position: relative;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-black);
	padding-left: 30px;
	margin-bottom: 8px;
	z-index: 1;
}

.modal-space__card .m-card__info-topics__list-item::before {
	content: "";
	position: absolute;
	top: 7px;
	left: 5px;
	width: 12px;
	height: 12px;
	background-color: var(--c-green);
	border-radius: 2px;
	z-index: -1;
}

.modal-space__card .m-card__info-topics__list-item:last-child {
	margin-bottom: 0;
}

.modal-space .fancybox-close-small {
	top: 23px;
	right: 23px;
	padding: 5px;
}

.modal-space .fancybox-close-small svg {
	color: #d3d3d3;
}

.modal-space.s2 .modal-space__card .m-card__info-list__item:nth-child(3) {
	margin-bottom: 19px;
}

.modal-space.s2 .modal-space__card .m-card__info-list__item:nth-child(3) .item-card__text .sub {
	margin-bottom: 8px;
}

.modal-space.s2 .modal-space__card .m-card__info-topics {
	margin-top: 90px;
}

.modal-space.s3 .modal-space__card .m-card__info-list__item:nth-child(3) {
	margin-bottom: 22px;
}

.modal-space.s3 .modal-space__card .m-card__info-list__item:nth-child(3) .item-card__text .sub {
	margin-bottom: 8px;
}

.modal-space.s3 .modal-space__card .m-card__info-topics {
	min-height: 341px;
	margin-top: 29px;
}

.modal-space.s4 .modal-space__card .m-card__info-list__item:nth-child(3) {
	margin-bottom: 19px;
}

.modal-space.s4 .modal-space__card .m-card__info-list__item:nth-child(3) .item-card__text .sub {
	margin-bottom: 8px;
}

.modal-space.s4 .modal-space__card .m-card__info-topics {
	min-height: 341px;
	margin-top: 90px;
}

.modal-space.s5 .modal-space__card .m-card__info-text span {
	margin-bottom: 1px;
}

.modal-space.s5 .modal-space__card .m-card__info-list__item:nth-child(3) {
	margin-bottom: 0;
}

.modal-space.s5 .modal-space__card .m-card__info-list__item:nth-child(3) .item-card__text .sub {
	margin-bottom: 0;
}

.modal-space.s5 .modal-space__card .m-card__info-topics {
	min-height: 341px;
	margin-top: 118px;
}

#phone-error,
#email-error,
#name-error {
	display: none !important;
	width: 100%;
	font-family: var(--font-family);
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 17px;
	color: #f00;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 10px;
}

.fancybox-button svg {
	color: var(--c-white);
}

body.compensate-for-scrollbar {
	height: 100%;
	overflow: hidden;
}

@media (max-width: 1250px) {
	.modal-intensives {
		width: 750px;
		max-width: 750px;
		padding: 20px 20px 20px 20px;
	}
	.modal-intensives__card .m-card__heading {
		max-width: 670px;
		min-height: 27px;
		font-size: 26px;
		margin-bottom: 20px;
	}
	.modal-intensives__card .m-card__info {
		margin-bottom: 21px;
	}
	.modal-intensives__card .m-card__info-left {
		max-width: 296px;
	}
	.modal-intensives__card .m-card__info-right {
		row-gap: 0;
		max-width: 396px;
		min-height: fit-content;
	}
	.modal-intensives__card .m-card__info-image {
		max-width: 296px;
		min-height: 270px;
	}
	.modal-intensives__card .m-card__info-education {
		top: 0;
		left: 0;
		max-width: 169px;
		height: 44px;
		font-size: 22px;
		line-height: 100%;
		padding-left: 3px;
	}
	.modal-intensives__card .m-card__info-format {
		margin-bottom: 21px;
	}
	.modal-intensives__card .m-card__info-format__list {
		row-gap: 10px;
		max-width: 261px;
		margin-bottom: 10px;
	}
	.modal-intensives__card .m-card__info-format__list-item {
		max-width: 261px;
		height: 49px;
		font-size: 20px;
		line-height: 120%;
		padding: 10px 17px 10px 16px;
	}
	.modal-intensives__card .m-card__info-format__list-item:nth-child(1) {
		max-width: 208px;
	}
	.modal-intensives__card .m-card__info-format__text {
		font-size: 20px;
		line-height: 120%;
	}
	.modal-intensives__card .m-card__info-whom {
		margin-bottom: 25px;
	}
	.modal-intensives__card .m-card__info-whom__text {
		margin-bottom: 7px;
	}
	.modal-intensives__card .m-card__info-whom__list-item {
		font-size: 20px;
		line-height: 120%;
		padding-left: 30px;
		margin-bottom: 4px;
	}
	.modal-intensives__card .m-card__info-whom__list-item::before {
		top: 7px;
		left: 5px;
		width: 12px;
		height: 12px;
	}
	.modal-intensives__card .m-card__info-documents {
		max-width: 296px;
		font-size: 20px;
		line-height: 120%;
		padding-left: 70px;
		margin: 0;
	}
	.modal-intensives__card .m-card__info-documents::before {
		top: -3px;
		left: 0;
		width: 55px;
		height: 55px;
	}
	.modal-intensives__card .m-card__topics {
		padding: 21px 20px 20px 20px;
		margin-bottom: 21px;
	}
	.modal-intensives__card .m-card__topics-text {
		margin-bottom: 7px;
	}
	.modal-intensives__card .m-card__topics-list__item {
		margin-bottom: 8px;
	}
	.modal-intensives__card .m-card__topics-list__item::before {
		top: 7px;
		left: 5px;
		width: 12px;
		height: 12px;
	}
	.modal-intensives__card .m-card__topics-list__item br {
		display: none;
	}
	.modal-intensives__card .m-card__speakers {
		padding: 0 20px;
	}
	.modal-intensives__card .m-card__speakers-text {
		margin-bottom: 11px;
	}
	.modal-intensives__card .m-card__speakers-list {
		margin: 0;
	}
	.modal-intensives__card .m-card__speakers-list__item {
		max-width: 293px !important;
	}
	.modal-intensives__card .m-card__speakers-list__item .item-card {
		padding: 0;
	}
	.modal-intensives__card .m-card__speakers-list__item .item-card__photo {
		position: relative;
		top: auto;
		left: auto;
		width: 100px;
		max-width: 100px;
		margin-bottom: 20px;
	}
	.modal-intensives__card .m-card__speakers-list__item .item-card__info-name {
		position: absolute;
		top: -119px;
		right: 0;
		max-width: 169px;
		margin-bottom: 7px;
	}
	.modal-intensives__card .m-card__speakers-list__item .item-card__info-regalia__item {
		padding-left: 30px;
		margin-bottom: 8px;
	}
	.modal-intensives__card .m-card__speakers-list__item .item-card__info-regalia__item::before {
		top: 7px;
		left: 5px;
		width: 12px;
		height: 12px;
	}
	.modal-intensives__card .m-card__speakers-list__item .item-card__info-regalia__item br {
		display: none;
	}
	.modal-intensives .fancybox-close-small {
		top: 12px;
		right: 23px;
		padding: 5px;
	}
	.modal-intensives.i-mini .modal-intensives__card .m-card__info-image {
		min-height: 270px;
	}
	.modal-intensives.i-mini .modal-intensives__card .m-card__info-format__list {
		row-gap: 10px;
		max-width: 296px;
		margin-bottom: 0;
	}
	.modal-intensives.i-mini .modal-intensives__card .m-card__info-format__list-item {
		max-width: fit-content;
		padding: 10px 17px 10px 16px;
	}
	.modal-intensives.i-mini .modal-intensives__card .m-card__info-format__list-item:nth-child(1) {
		height: 70px;
		padding: 10px 17px 8px 16px;
		order: 1;
	}
	.modal-intensives.i-mini .modal-intensives__card .m-card__info-format__list-item:nth-child(2) {
		padding: 10px 46px 10px 16px;
	}
	.modal-intensives.i-mini .modal-intensives__card .m-card__info-format__list-item:nth-child(3) {
		padding: 10px 10px 10px 16px;
	}
	.modal-intensives.i-mini .modal-intensives__card .m-card__info-whom {
		margin-bottom: 23px;
	}
	.modal-intensives.i-mini .modal-intensives__card .m-card__info-documents {
		font-size: 20px;
		line-height: 120%;
	}
	.modal-intensives.i-mini .modal-intensives__card .m-card__info-documents::before {
		top: -3px;
	}
	.modal-intensives.i-mini.i6 .modal-intensives__card .m-card__info-format__list {
		row-gap: 10px;
		max-width: 296px;
		margin-bottom: 0;
	}
	.modal-intensives.i-mini.i6 .modal-intensives__card .m-card__info-format__list-item {
		max-width: fit-content;
		padding: 10px 17px 10px 16px;
	}
	.modal-intensives.i-mini.i6 .modal-intensives__card .m-card__info-format__list-item:nth-child(1) {
		height: 70px;
		padding: 10px 17px 8px 16px;
		order: 1;
	}
	.modal-intensives.i-mini.i6 .modal-intensives__card .m-card__info-format__list-item:nth-child(2) {
		padding: 10px 47px 10px 16px;
	}
	.modal-intensives.i-mini.i6 .modal-intensives__card .m-card__info-format__list-item:nth-child(3) {
		padding: 10px 24px 10px 16px;
	}
	.modal-intensives.i-mini.i8 .modal-intensives__card .m-card__info-format__list {
		row-gap: 10px;
		max-width: 296px;
		margin-bottom: 0;
	}
	.modal-intensives.i-mini.i8 .modal-intensives__card .m-card__info-format__list-item {
		max-width: fit-content;
		padding: 10px 17px 10px 16px;
	}
	.modal-intensives.i-mini.i8 .modal-intensives__card .m-card__info-format__list-item:nth-child(1) {
		height: 49px;
		padding: 10px 106px 9px 16px;
		margin: 0;
		order: 1;
	}
	.modal-intensives.i-mini.i8 .modal-intensives__card .m-card__info-format__list-item:nth-child(2) {
		padding: 10px 47px 10px 16px;
	}
	.modal-intensives.i-mini.i8 .modal-intensives__card .m-card__info-format__list-item:nth-child(3) {
		padding: 10px 19px 10px 16px;
	}
	.modal-space {
		width: 750px;
		max-width: 750px;
		padding: 21px 20px 20px 20px;
	}
	.modal-space__card .m-card__heading {
		max-width: 670px;
		min-height: 27px;
		font-size: 26px;
		margin-bottom: 20px;
	}
	.modal-space__card .m-card__info-left {
		max-width: 296px;
	}
	.modal-space__card .m-card__info-right {
		max-width: 396px;
	}
	.modal-space__card .m-card__info-image {
		max-width: 296px;
		min-height: 270px;
	}
	.modal-space__card .m-card__info-text {
		font-size: 20px;
		line-height: 120%;
		margin-top: 49px;
	}
	.modal-space__card .m-card__info-text span {
		margin-bottom: 4px;
	}
	.modal-space__card .m-card__info-text.mr-0 {
		margin: 0;
	}
	.modal-space__card .m-card__info-triggers {
		margin-top: 8px;
		margin-bottom: 8px;
	}
	.modal-space__card .m-card__info-triggers__item {
		font-size: 20px;
		line-height: 120%;
		padding-left: 29px;
		margin-bottom: 4px;
	}
	.modal-space__card .m-card__info-triggers__item::before {
		top: 6px;
		left: 5px;
		width: 12px;
		height: 12px;
	}
	.modal-space__card .m-card__info-triggers__item:nth-child(1)::before {
		top: 7px;
	}
	.modal-space__card .m-card__info-list {
		max-width: 296px;
	}
	.modal-space__card .m-card__info-list__item {
		margin-bottom: 22px;
	}
	.modal-space__card .m-card__info-list__item .item-card {
		min-height: 66px;
		padding: 1px 0 0 60px;
		margin: 0;
	}
	.modal-space__card .m-card__info-list__item .item-card__image {
		top: -1px;
		left: 0;
		width: 50px;
		height: 50px;
	}
	.modal-space__card .m-card__info-list__item .item-card__text {
		font-size: 18px;
		line-height: 120%;
		margin-bottom: 5px;
	}
	.modal-space__card .m-card__info-list__item .item-card__text .sub {
		margin-bottom: 6px;
	}
	.modal-space__card .m-card__info-list__item:nth-child(1) .item-card__image {
		top: 2px;
	}
	.modal-space__card .m-card__info-list__item:nth-child(2) {
		margin-bottom: 15px;
	}
	.modal-space__card .m-card__info-list__item:nth-child(2) .item-card__image {
		top: 0;
	}
	.modal-space__card .m-card__info-list__item:nth-child(3) {
		margin-bottom: 20px;
	}
	.modal-space__card .m-card__info-list__item:nth-child(3) .item-card__text .sub {
		display: initial;
		margin-bottom: 0;
	}
	.modal-space__card .m-card__info-topics {
		min-height: fit-content;
		padding: 21px 20px 20px 20px;
		margin-top: 5px;
	}
	.modal-space__card .m-card__info-topics__subtext {
		margin-bottom: 7px;
	}
	.modal-space__card .m-card__info-topics__list-item {
		font-size: 20px;
		line-height: 120%;
		padding-left: 30px;
		margin-bottom: 8px;
	}
	.modal-space__card .m-card__info-topics__list-item::before {
		top: 7px;
		left: 5px;
		width: 12px;
		height: 12px;
	}
	.modal-space .fancybox-close-small {
		top: 12px;
		right: 23px;
		padding: 5px;
	}
	.modal-space.s2 .modal-space__card .m-card__info-list__item:nth-child(2) {
		margin-bottom: 20px;
	}
	.modal-space.s2 .modal-space__card .m-card__info-list__item:nth-child(3) {
		margin-bottom: 20px;
	}
	.modal-space.s2 .modal-space__card .m-card__info-list__item:nth-child(3) .item-card__image {
		top: 0;
	}
	.modal-space.s2 .modal-space__card .m-card__info-list__item:nth-child(3) .item-card__text .sub {
		display: block;
		margin-bottom: 6px;
	}
	.modal-space.s2 .modal-space__card .m-card__info-topics {
		margin-top: 4px;
	}
	.modal-space.s3 .modal-space__card .m-card__info-heading {
		margin-bottom: -29px;
	}
	.modal-space.s3 .modal-space__card .m-card__info-list__item:nth-child(2) {
		margin-bottom: 20px;
	}
	.modal-space.s3 .modal-space__card .m-card__info-list__item:nth-child(3) {
		margin-bottom: 20px;
	}
	.modal-space.s3 .modal-space__card .m-card__info-list__item:nth-child(3) .item-card__image {
		top: 0;
	}
	.modal-space.s3 .modal-space__card .m-card__info-list__item:nth-child(3) .item-card__text .sub {
		display: block;
		margin-bottom: 6px;
	}
	.modal-space.s3 .modal-space__card .m-card__info-topics {
		min-height: fit-content;
		margin-top: 4px;
	}
	.modal-space.s4 .modal-space__card .m-card__info-list__item:nth-child(2) {
		margin-bottom: 20px;
	}
	.modal-space.s4 .modal-space__card .m-card__info-list__item:nth-child(3) {
		margin-bottom: 20px;
	}
	.modal-space.s4 .modal-space__card .m-card__info-list__item:nth-child(3) .item-card__image {
		top: 0;
	}
	.modal-space.s4 .modal-space__card .m-card__info-list__item:nth-child(3) .item-card__text .sub {
		display: block;
		margin-bottom: 6px;
	}
	.modal-space.s4 .modal-space__card .m-card__info-topics {
		min-height: fit-content;
		margin-top: 4px;
	}
	.modal-space.s5 .modal-space__card .m-card__info-heading {
		margin-bottom: 0px;
	}
	.modal-space.s5 .modal-space__card .m-card__info-text {
		margin-top: 20px;
	}
	.modal-space.s5 .modal-space__card .m-card__info-list__item:nth-child(3) {
		margin-bottom: 0;
	}
	.modal-space.s5 .modal-space__card .m-card__info-list__item:nth-child(3) .item-card__text .sub {
		margin-bottom: 0;
	}
	.modal-space.s5 .modal-space__card .m-card__info-topics {
		min-height: fit-content;
		margin-top: 20px;
	}
}

form__input.error {
	border: 1px solid #f00 !important;
	box-shadow: inset 0 0 1px 1px #f00 !important;
	-webkit-box-shadow: inset 0 0 1px 1px #f00 !important;
	-moz-box-shadow: inset 0 0 1px 1px #f00 !important;
	-webkit-appearance: none !important;
}

form__input.error:focus, form__input.error:active {
	border: 1px solid #f00 !important;
	box-shadow: inset 0 0 1px 1px #f00 !important;
	-webkit-box-shadow: inset 0 0 1px 1px #f00 !important;
	-moz-box-shadow: inset 0 0 1px 1px #f00 !important;
	-webkit-appearance: none !important;
}

form #phone-error,
form #email-error,
form #name-error {
	display: none !important;
	width: 100%;
	font-family: var(--font-family);
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 17px;
	color: #f00;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 10px;
}

.timer {
	padding: 0;
	background: url(../img/common/bg_timer.jpg) center center;
}

.timer__inner {
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	font-style: normal;
	font-weight: 600;
	font-size: 2.25rem;
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #FFFFFF;
}

@media (max-width: 1510px) {
	.timer__inner {
		font-size: 1.85rem;
	}
}

@media (max-width: 1355px) {
	.timer__inner {
		font-size: 1.25rem;
	}
}

.timer-top {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}

.timer-title {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0.625rem 1.25rem;
	background: #F8444B;
	border-radius: 0.625rem;
}

.timer-title br {
	display: none;
}

.timer-items {
	display: flex;
}

.timer-item {
	position: relative;
	flex-basis: 25%;
	display: flex;
	align-items: flex-start;
	gap: 5px;
}

.timer-item-value {
	display: flex;
	align-items: center;
}

.timer-item-text {
	display: none;
	align-items: center;
}

.timer__button {
	height: 52px;
	padding: 0 1.5rem;
	border-radius: 50px;
	font-weight: 600;
	font-size: 1.25rem;
	text-transform: none;
}

.timer .timer-item_1 .timer-item-text {
	display: flex;
}

.timer-item_1 .timer-item-text:after,
.timer-item_2 .timer-item-value:after,
.timer-item_3 .timer-item-value:after {
	content: ':';
	padding: 0 0.5rem;
	display: block;
}

.dost {
	padding-top: 0;
	padding-bottom: 3rem;
}

.dost__heading {
	max-width: 870px;
}

.dost .swiper {
	width: 100%;
	height: auto;
}

.dost .swiper-wrapper {
	align-items: stretch;
}

.dost .swiper-slide {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	max-width: 335px;
	height: 100%;
	min-height: 400px;
	background: #FFFFFF;
	border-radius: 20px;
	padding: 1rem;
	background-color: #ED131C;
}

.dost__type {
	font-weight: 500;
	font-style: normal;
	font-size: 24px;
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: var(--c-white);
}

.dost__name {
	font-weight: 500;
	font-style: normal;
	font-size: 24px;
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: var(--c-white);
	padding-bottom: 3rem;
}

.dost__text {
	width: 161px;
	height: 161px;
	margin-top: auto;
	margin-left: auto;
	display: flex;
	background-image: url("data:image/svg+xml,%3Csvg width='157' height='161' viewBox='0 0 157 161' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M78.5 0L92.8303 17.7146L113.428 7.97201L118.653 30.1501L141.437 30.3091L136.522 52.5579L156.982 62.5871L142.9 80.5L156.982 98.4129L136.522 108.442L141.437 130.691L118.653 130.85L113.428 153.028L92.8303 143.285L78.5 161L64.1697 143.285L43.5724 153.028L38.3473 130.85L15.5626 130.691L20.4776 108.442L0.0183029 98.4129L14.1 80.5L0.0183029 62.5871L20.4776 52.5579L15.5626 30.3091L38.3473 30.1501L43.5724 7.97201L64.1697 17.7146L78.5 0Z' fill='white'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position: 50% 50%;
	justify-content: center;
	align-items: center;
}

.dost__text img {
	height: auto;
	max-height: min-content;
}

.dost__text:nth-child(1) img {
	width: 92px;
}

.dost__text:nth-child(2) img {
	width: 100px;
}

.dost__text:nth-child(3) img {
	width: 70px;
}

.dost__text:nth-child(4) img {
	width: 79px;
}

.dost__list {
	display: flex;
	margin: 0 -0.5rem;
	align-items: stretch;
	flex-wrap: wrap;
}

.dost__item {
	margin: 0.5rem;
	padding: 1rem;
	box-sizing: border-box;
	width: calc(50% - 1rem);
	height: 342px;
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
	background: #FFFFFF;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.dost__item a {
	margin-top: auto;
}

.dost__item:nth-child(2) {
	padding: 0;
	background: url(/img/programms/img.jpg) no-repeat center center;
}

.dost__button {
	display: none;
}

.news {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.news__heading {
	max-width: 870px;
}

.news .swiper {
	width: 100%;
	margin-bottom: 0;
}

.news .swiper-slide {
	display: flex;
	box-sizing: border-box;
	width: 100%;
	padding: 0;
}

.news .swiper__nav {
	padding-bottom: 3rem;
}

.news .swiper-pagination-bullet {
	width: calc((100% / 6) - 0.5rem);
}

.news__type {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #7C7C7C;
	padding-bottom: 0.5rem;
}

.news__name {
	max-width: 360px;
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
}

.news__text {
	margin-top: auto;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
}

.news__list {
	display: flex;
	margin: 0 0;
	align-items: stretch;
	flex-wrap: wrap;
}

.news__item {
	margin: 0.5rem;
	padding: 1rem;
	box-sizing: border-box;
	width: calc(50% - 1rem);
	height: 346px;
	font-style: normal;
	font-weight: 600;
	font-size: 1.875rem;
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
	background: #FFFFFF;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.news__item a {
	margin-top: auto;
}

.news__item:nth-child(2) {
	padding: 0;
	background: no-repeat center center;
	background-size: cover;
}

.news__button {
	display: none;
}

.students {
	padding-top: 4rem;
	padding-bottom: 2rem;
	background: #ECEDF0;
}

.students__heading {
	max-width: 870px;
}

.students .swiper {
	width: 100%;
	margin-bottom: 0;
}

.students .swiper-slide {
	display: flex;
	box-sizing: border-box;
	width: 100%;
	padding: 0;
}

.students .swiper__nav {
	padding-bottom: 3rem;
}

.students__type {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #7C7C7C;
	padding-bottom: 0.5rem;
}

.students__name {
	max-width: 360px;
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
}

.students__text {
	margin-top: auto;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
}

.students__text ul.squared {
	margin-bottom: 0;
}

.students__text ul.squared li {
	font-style: normal;
	font-weight: 600;
	font-size: 1.125rem;
	line-height: 120%;
	font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
	color: #7E7F81;
}

.students__list {
	display: flex;
	margin: 0 0;
	align-items: stretch;
	background: #FFFFFF;
	width: 100%;
	max-width: 690px;
	border-radius: 20px;
	justify-content: space-between;
	gap: 1rem;
}

.students__item {
	padding: 1rem 0 0 1rem;
	box-sizing: border-box;
	height: 320px;
	font-style: normal;
	font-weight: 600;
	font-size: 1.875rem;
	line-height: 110%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
	background: #FFFFFF;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.students__item a {
	margin-top: auto;
}

.students__item:nth-child(2) {
	width: 100%;
	max-width: 211px;
	padding: 0;
	margin: 0.5rem;
	background: no-repeat center center;
	background-size: cover;
}

.students__button {
	display: none;
}

.lazy {
	background-image: none !important;
	opacity: 0;
}

.lazy:before, .lazy:after {
	content: none !important;
}

.lazy-loaded {
	opacity: 1;
	transition: 0.1s;
}

/***********/
/* Blocks */
/***********/
/* Popups */
