.experts {
  padding-top: 98px;
  padding-bottom: 60px;

  &__heading {
    max-width: 870px;
    margin-bottom: 51px;

    .special {
      display: block;
      margin-bottom: -13px;
    }
  }

  &__list {
    justify-content: space-between;
    row-gap: 50px;
    padding: 0;
    margin: 0;

    &-item {
      flex: 0 0 100%;
      max-width: 100%;

      .item-card {
        position: relative;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
        min-height: 592px;
        box-sizing: border-box;
        overflow: hidden;
        background-color: transparent;
        padding: 0;
        margin: 0;

        &__image {
          flex: 0 0 100%;
          max-width: 555px;
          min-height: 592px;
          box-sizing: border-box;
          overflow: hidden;
          background-color: #a76c59;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 30px;
        }

        &__info {
          position: relative;
          display: flex;
          flex-direction: column;
          flex: 0 0 100%;
          max-width: 855px;
          min-height: 592px;
          padding-top: 1px;

          &-name {
            font-family: var(--font-family);
            font-weight: 700;
            font-size: 50px;
            line-height: 120%;
            letter-spacing: 0;
            color: var(--c-green);
            margin-bottom: 17px;

            span {
              display: block;
              font-family: var(--second-family);
              font-weight: 400;
              font-size: 60px;
              line-height: 120%;
              letter-spacing: 0;
              color: var(--c-white);
              margin-bottom: -12px;
            }
          }

          &-list {
            position: relative;
            width: 100%;
            max-width: 840px;
            padding: 0;
            margin: 0;
            margin-bottom: auto;

            &__item {
              position: relative;
              font-family: var(--font-family);
              font-weight: 500;
              font-size: 26px;
              line-height: 120%;
              letter-spacing: 0;
              color: var(--c-white);
              padding-left: 34px;
              margin-bottom: 12px;
              z-index: 1;

              &::before {
                content: "";
                position: absolute;
                top: 9px;
                left: 5px;
                width: 12px;
                height: 12px;
                background-color: var(--c-green);
                border-radius: 2px;
                z-index: -1;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          &-text {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 853px;
            height: 59px;
            box-sizing: border-box;
            overflow: hidden;
            background-color: #2d2d2d;
            border-radius: 15px;
            font-family: var(--font-family);
            font-weight: 500;
            font-size: 20px;
            line-height: 120%;
            letter-spacing: 0;
            color: var(--c-white);
            padding: 10px 25px 9px 16px;
            margin: 0;
          }
        }
      }

      &.item-2 {
        .item-card {
          &__info {
            &-list {
              max-width: 821px;
            }
          }
        }
      }
    }
  }

  &__slider-arrows {
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    align-content: center;

    .arrows {
      width: 100%;
      max-width: 100%;
      height: 60px;
      display: flex !important;
      justify-content: space-between;

      &-arrow {
        display: block !important;
        cursor: pointer;
        width: 60px;
        height: 60px;
        border: none;
        transition: all 0.3s ease;
      }

      .prev {
        background: url(../img/common/prev.svg) 50% 50% / 100% no-repeat;
        margin-right: 11px;

        &.slick-disabled {
          opacity: 0.8;
        }
      }

      .next {
        background: url(../img/common/next.svg) 50% 50% / 100% no-repeat;


        &.slick-disabled {
          opacity: 0.8;
        }
      }

    }
  }

  &-slider {
    display: flex;
    justify-content: center;
    min-width: 0;

    .slick {
      &-slide {
        width: 100%;
        max-width: 357px;
      }

      &-list {
        overflow: hidden;
      }

      &-track {
        display: flex;
      }
    }
  }

  @media (max-width: 1509px) {
    &__list {
      &-item {
        .item-card {
          &__image {
            max-width: 435px;
            min-height: 652px;
          }

          &__info {
            max-width: 695px;
            min-height: 652px;

            &-text {
              height: fit-content;
              min-height: 59px;
            }
          }
        }
      }
    }
  }
}