.first {
  margin-top: 6.6rem;
  margin-bottom: 0;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0 0 0 0;
  z-index: 1;
  height: 493px;

  .container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding: 0;
  }

  &__info {
    // background: var(--c-white);
    background: linear-gradient(135deg,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.59) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    box-sizing: border-box;
    padding: 5rem 4rem 3rem 6rem;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 3;
    width: 100%;
    max-width: 672px;
  }

  &__img {
    display: none;
  }

  &__subheading {
    display: block;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 26px;
    line-height: 100%;
    letter-spacing: 0;
    color: var(--c-white);
    margin-bottom: 18px;
  }

  &__heading {
    margin-bottom: 1rem;
    margin-bottom: 1rem;
    max-width: 800px;

    .special {
      position: relative;
      top: 9px;
      display: block;
    }

    .red {
      position: relative;
      top: -7px;
      display: block;
    }
  }

  &__description {
    font-family: var(--font-family);
    line-height: 120%;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: 0;
  }

  &__buttons {
    padding: 2rem 0 0 0;
    margin: 0 0 0 auto;
    .button {
    }
  }

  @media (max-width: 1509px) {
    &__buttons {
    }
  }
}

.main .first {
  padding: 0;
  @media (max-width: 650px) {
    // padding: 12rem 0 2rem;
  }
}