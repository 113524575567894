.tours {
  padding-top: 100px;
  padding-bottom: 60px;

  &__heading {
    max-width: 870px;
    margin-bottom: 54px;

    .special {
      display: block;
      margin-bottom: -13px;
    }
  }

  &__list {
    justify-content: space-between;
    padding: 0;
    margin: 0;

    &-item {
      flex: 0 0 100%;
      max-width: 730px;

      .item-card {
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 23px;
        width: 100%;
        max-width: 100%;
        min-height: 651px;
        box-sizing: border-box;
        background-color: transparent;
        padding: 0;
        margin: 0;

        &__image {
          width: 100%;
          max-width: 100%;
          height: fit-content;
          box-sizing: border-box;
          overflow: hidden;
          background-color: #d9d9d9;
          border-radius: 30px;
        }

        &__info {
          position: relative;
          justify-content: space-between;
          align-items: center;
          padding: 0;
          margin: 0;

          &-title {
            position: relative;
            top: 3px;
            flex: 0 0 100%;
            max-width: fit-content;
            font-family: var(--font-family);
            font-weight: 500;
            font-size: 26px;
            line-height: 120%;
            letter-spacing: 0;
            color: var(--c-white);
            margin-bottom: 0;
          }

          &-buttons {
            flex: 0 0 100%;
            max-width: fit-content;
          }
        }
      }

      &.item-2 {
        .item-card {
          &__image {
            max-width: 727px;
          }
        }
      }
    }
  }

  @media (max-width: 1509px) {
    &__list {
      &-item {
        max-width: 575px;
      }
    }
  }
}