form {
	&__input {
		&.error {
			border: 1px solid #f00 !important;
			box-shadow: inset 0 0 1px 1px #f00 !important;
			-webkit-box-shadow: inset 0 0 1px 1px #f00 !important;
			-moz-box-shadow: inset 0 0 1px 1px #f00 !important;
			-webkit-appearance: none !important;

			&:focus,
			&:active {
				border: 1px solid #f00 !important;
				box-shadow: inset 0 0 1px 1px #f00 !important;
				-webkit-box-shadow: inset 0 0 1px 1px #f00 !important;
				-moz-box-shadow: inset 0 0 1px 1px #f00 !important;
				-webkit-appearance: none !important;
			}
		}
	}

	#phone-error,
	#email-error,
	#name-error {
		display: none !important;
		width: 100%;
		font-family: var(--font-family);
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 17px;
		color: #f00;
		text-align: center;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}