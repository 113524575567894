.dost {
  padding-top: 0;
  padding-bottom: 3rem;

  &__heading {
    max-width: 870px;
  }



  


  .swiper {
    width: 100%;
    height: auto;
    &-wrapper {
      align-items: stretch;
    }
    &-slide {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      max-width: 335px;
      height: 100%;
      min-height: 400px;
      background: #FFFFFF;
      border-radius: 20px;
      padding: 1rem;
      background-color: #ED131C;
    }
  }

  &__type {
    font-weight: 500;
    font-style: normal;
    font-size: 24px;
    line-height: 110%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--c-white);
  }
  &__name {
    font-weight: 500;
    font-style: normal;
    font-size: 24px;
    line-height: 110%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--c-white);
    padding-bottom: 3rem;
  }
  &__text {
    width: 161px;
    height: 161px;
    margin-top: auto;
    margin-left: auto;
    display: flex;
    background-image: url("data:image/svg+xml,%3Csvg width='157' height='161' viewBox='0 0 157 161' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M78.5 0L92.8303 17.7146L113.428 7.97201L118.653 30.1501L141.437 30.3091L136.522 52.5579L156.982 62.5871L142.9 80.5L156.982 98.4129L136.522 108.442L141.437 130.691L118.653 130.85L113.428 153.028L92.8303 143.285L78.5 161L64.1697 143.285L43.5724 153.028L38.3473 130.85L15.5626 130.691L20.4776 108.442L0.0183029 98.4129L14.1 80.5L0.0183029 62.5871L20.4776 52.5579L15.5626 30.3091L38.3473 30.1501L43.5724 7.97201L64.1697 17.7146L78.5 0Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    justify-content: center;
    align-items: center;
    img {
      height: auto;
      max-height: min-content;
    }
    &:nth-child(1) {
      img {
        width: 92px;
      }
    }
    &:nth-child(2) {
      img {
        width: 100px;
      }
    }
    &:nth-child(3) {
      img {
      width: 70px;
      }
    }
    &:nth-child(4) {
      img {
        width: 79px;
      }
    }
  }
  
  &__list {
    display: flex;
    margin: 0 -0.5rem;
    align-items: stretch;
    flex-wrap: wrap;
  }
  &__item {
    margin: 0.5rem;
    padding: 1rem;
    box-sizing: border-box;
    width: calc(50% - 1rem);
    height: 342px;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 110%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
    background: #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    a {
      margin-top: auto;
    }
    &:nth-child(2) {
      padding: 0;
      background: url(/img/programms/img.jpg) no-repeat center center;
    }
  }
  &__info {

  }
  &__button {
    display: none;
  }


  @media (max-width: 1880px) {
    &__list {
    }
  }

  @media (max-width: 1840px) {
    &__list {
    }
  }

  @media (max-width: 1700px) {
    &__list {
    }
  }
}