.programma {
  padding-top: 70px;
  padding-bottom: 130px;

  &__heading {
    max-width: 100%;
    margin-bottom: 39px;
  }
 
  &__wrapper {
    width: 100%;
  }

  &__list {
    position: relative;
    justify-content: space-between;
    row-gap: 20px;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;

    &-item {
      flex: 0 0 100%;
      max-width: 100%;

      .item-card {
        cursor: pointer;
        position: relative;
        max-width: 100%;
        min-height: 66px;
        overflow: hidden;

        box-sizing: border-box;
        background: #1F1F1F;
        backdrop-filter: blur(7.5px);
        border-radius: 30px;

        padding: 2rem 2rem;
        opacity: 1;
        transition: all 0.5s ease;

        &__header {
          position: relative;
          max-width: 100%;
          height: 100%;
          margin-bottom: 0;
          transition: all 0.5s ease;
          display: flex;
          align-items: center;

          span {
            color: var(--c-green);
          }

          &-number {
            font-family: var(--font3);
            font-size: 40px;
            line-height: 100%;
            text-transform: uppercase;
            color: var(--c-green);
            letter-spacing: 0;
            padding: 0 1rem 0 0;
        
          }

          &-title {
            max-width: calc(100% - 9rem);
            font-family: var(--font-family);
            font-weight: 700;
            font-size: 26px;
            line-height: 100%;
            letter-spacing: 0;
            color: var(--c-white);
            margin-bottom: 0;
            transition: all 0.5s ease;
          }

          &-arrow {
            position: absolute;
            top: 0;
            right: -5px;
            background: transparent;
            transition: all 0.5s ease;
            width: 45px;
            height: 45px;
            border-radius: 45px;
            background: #0A0A0A;
            display: flex;
            justify-content: center;
            align-items: center;

            &::before {
              content: "";
              background: url(../img/faq/arrow.svg);
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              width: 20px;
              height: 12px;
              transform: rotate(0);
              opacity: 1;
              transition: all 0.5s ease;
            }
          }
        }

        &__body {
          width: calc(100% - 3rem);
          height: 0;
          box-sizing: border-box;
          overflow: hidden;
          transition: all 0.5s ease;
          padding-left: 4.4rem;

          &-text {
            font-family: var(--font-family);
            font-weight: 400;
            font-size: 20px;
            line-height: 120%;
            letter-spacing: 0;
            color: var(--c-white);
            margin-bottom: 0;
            transition: all 0.5s ease;
          }
          ul {
            font-family: var(--font-family);
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 120%;
            color: #C3C3C3;
            margin: 0 0 0 2rem;
            li {
              list-style: disc;
            }
          }
        }

        &:hover {
          opacity: 0.8;
          transition: all 0.5s ease;

          .item-card__header {
            &-title {
              color: var(--c-white);
              transition: all 0.5s ease;
            }
          }
        }
      }

      &.active {
        .item-card {
          // padding: 10px 30px 29px 0;

          &__header {
            margin-bottom: 30px;

            &-arrow {
              &::before {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      &.no-click {
        pointer-events: none !important;
      }
    }
  }
}