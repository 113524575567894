.news {
  padding-top: 4rem;
  padding-bottom: 4rem;

  &__heading {
    max-width: 870px;
  }



  


  .swiper {
    width: 100%;
    margin-bottom: 0;
    &-slide {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      padding: 0;
    }
    &__nav {
      padding-bottom: 3rem;
    }
    &-pagination-bullet {
      width: calc((100% / 6) - 0.5rem);
    }
  }

  &__type {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #7C7C7C;
    padding-bottom: 0.5rem;
  }
  &__name {
    max-width: 360px;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 110%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
  }
  &__text {
    margin-top: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
  }
  
  &__list {
    display: flex;
    margin: 0 0;
    align-items: stretch;
    flex-wrap: wrap;
  }
  &__item {
    margin: 0.5rem;
    padding: 1rem;
    box-sizing: border-box;
    width: calc(50% - 1rem);
    height: 346px;
    font-style: normal;
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 110%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
    background: #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    a {
      margin-top: auto;
    }
    &:nth-child(2) {
      padding: 0;
      background: no-repeat center center;
      background-size: cover;
    }
  }
  &__info {

  }
  &__button {
    display: none;
  }


  @media (max-width: 1880px) {
    &__list {
    }
  }

  @media (max-width: 1840px) {
    &__list {
    }
  }

  @media (max-width: 1700px) {
    &__list {
    }
  }
}