.preim {
  padding-top: 5rem;
  padding-bottom: 3.75rem;

  .container {
    display: flex;
  }

  &__heading {
    margin-bottom: 51px;

    .special {
      display: block;
      margin-bottom: -72px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
  }

  &__list {
    counter-reset: section;
    list-style-type: none;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0;
    margin: 0;
    border-top: 1px solid #323232;
    border-bottom: 1px solid #323232;

    &-item {
      position: relative;
      padding: 0.5rem;
      box-sizing: border-box;
      margin: 0.5rem;
      font-family: var(--font-family);
      font-size: 26px;
      line-height: 120%;
      color: var(--c-white);
      border-radius: 30px;
      width: 100%;
      max-width: calc((100% / 3) - 1rem);


      &::before {
        counter-increment: section;
        content: "0" counters(section, ".");
        position: relative;
        display: block;
        padding-bottom: 0.5rem;
        font-family: var(--font3);
        font-size: 70px;
        line-height: 100%;
        text-transform: uppercase;
        letter-spacing: 0;
        background: radial-gradient(50% 82.45% at 50% 50%, #9C7AE4 0%, #6B38C1 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

    }
  }

  &__img {
    position: relative;
    box-sizing: border-box;
    margin-left: 75px;
    display: flex;
    flex-direction: column;
    min-width: 480px;
    img {
      margin-bottom: 25px;
    }
  }



  @media (max-width: 1880px) {
    &__list {

    }
  }

  @media (max-width: 1840px) {
    &__list {

    }
  }

  @media (max-width: 1700px) {
    &__list {

    }
  }
}