.price {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  padding-top: 10rem;
  padding-bottom: 5rem;
  z-index: 1;
  background-image: url(../img/price/price.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;

  .container {
    position: relative;
    z-index: 2;
  }

  &__info {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__subheading {
    display: block;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 26px;
    line-height: 100%;
    letter-spacing: 0;
    color: var(--c-white);
    margin-bottom: 18px;
  }

  &__heading {
    margin-bottom: 17px;
    margin-bottom: 17px;
    text-align: center;

    .special {
      position: relative;
      top: 9px;
      display: block;
    }

    .red {
      position: relative;
      top: -7px;
      display: block;
    }
  }

  &__description {
    font-family: var(--font3);
    text-align: center;
    letter-spacing: 0;
    color: var(--c-white);
    margin-bottom: 0;
    font-weight: 400;
    font-size: 60px;
    line-height: 100%;
    text-transform: uppercase;
    &--old {
      text-decoration: line-through;
      font-family: var(--font-family);
      font-style: normal;
      font-weight: 400;
      font-size: 3.875rem;
      line-height: 100%;
      text-align: center;
      text-transform: uppercase;
      color: #9F9F9F;
    }
  }
  
  &__buttons {
    padding: 2rem 0;
    // position: absolute;
    // top: 220px;
    // right: 50%;
    // width: 100%;
    width: 100%;
    max-width: 100%;
    .button {
      padding: 0 2rem;
      width: 100%;
      max-width: 100%;
        span {
          color: var(--c-main);
        }
    }
  }

  @media (max-width: 1509px) {
    &__buttons {
      // top: 313px;
      // margin-right: -540px;
    }
  }
}