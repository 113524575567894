.space {
  padding-top: 38px;
  padding-bottom: 60px;

  &__heading {
    max-width: 870px;
    margin-bottom: 11px;

    .special {
      display: block;
      margin-bottom: -13px;
    }
  }

  &__list {
    position: relative;
    justify-content: space-between;
    row-gap: 0;
    padding: 0;
    margin: 0 3px 0 1px;
    z-index: 0;

    &-item {
      cursor: pointer;
      flex: 0 0 100%;
      max-width: 100%;
      padding-top: 40px;
      margin-left: auto;
      opacity: 1;
      transition: all 0.5s;

      .item-card {
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
        min-height: 117px;
        box-sizing: border-box;
        background-color: transparent;
        border-bottom: 2px solid var(--c-red);
        padding: 0;
        margin: 0;
        transition: all 0.5s;

        &__title {
          flex: 0 0 100%;
          max-width: 476px;
          font-family: var(--font-family);
          font-weight: 800;
          font-size: 36px;
          line-height: 120%;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          color: var(--c-white);
          padding-top: 15px;
          margin-bottom: 0;
          transition: all 0.5s;
        }

        &__image {
          pointer-events: none;
          position: absolute;
          top: 181px;
          left: 50.7%;
          margin-right: -50%;
          transform: translate(-50%, 0%);
          flex: 0 0 100%;
          width: 100%;
          max-width: 400px;
          height: 400px;
          box-sizing: border-box;
          overflow: hidden;
          background-color: #d9d9d9;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 30px;
          opacity: 0;
          transition: all 0.5s;
          z-index: 0;
        }

        &__buttons {
          flex: 0 0 100%;
          max-width: 313px;
          height: 70px;
          transition: all 0.5s;
        }
      }

      &:hover,
      &:active,
      &:focus {
        z-index: 3;
        transition: all 0.5s;

        .item-card {
          &__image {
            opacity: 1;
            transition: all 0.5s;
          }
        }
      }

      &.item-1 {
        z-index: 1;

        .item-card {
          &__title {
            max-width: 326px;
          }

          &__image {
            opacity: 1;
            transition: all 0.5s;
          }
        }
      }

      &.item-2 {
        max-width: 1229px;
        margin-right: -3px;

        .item-card {
          &__title {
            max-width: 316px;
          }
        }
      }

      &.item-3 {
        margin-right: -3px;

        .item-card {
          &__title {
            max-width: 316px;
          }
        }
      }

      &.item-4 {
        max-width: 1229px;
        margin-right: -3px;

        .item-card {
          &__title {
            max-width: 316px;
          }
        }
      }
    }
  }
}