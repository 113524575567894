.partners {
  padding-top: 4rem;
  padding-bottom: 4rem;

  &__heading {
    max-width: 870px;
    margin-bottom: 51px;
  }

  .swiper {
    width: 100%;
    height: auto;
    &-slide {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 217px;
      height: 217px;
      background: #FFFFFF;
      border-radius: 20px;
      padding: 1rem;
      img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
  
  
  



  @media (max-width: 1880px) {

  }

  @media (max-width: 1840px) {

  }

  @media (max-width: 1700px) {

  }
}