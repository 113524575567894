.about {
  padding-top: 0;
  padding-bottom: 0;
  background: linear-gradient(to right,  #ffffff 0%,#ffffff 50%,var(--c-grey) 51%,var(--c-grey) 100%);

  .container {
    background: var(--c-greyer);
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 6rem;
  }

  &__box {
    background: var(--c-grey);
    padding: 4rem 0 4rem 4rem;
  }

  &__heading {
    padding-left: 4rem;
    margin-bottom: 6rem;
  }



  


  .swiper {
    width: 100%;
    height: auto;
    &-wrapper {
      align-items: stretch;
    }
    &-slide {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      max-width: 380px;
      // height: 100%;
      // min-height: 400px;
      height: auto;
      padding: 0 1rem 0 0;
      border-right: 2px solid #A09F9F;
      &:last-child {
        border-right: none;
      }
    }
    &__nav {
      justify-content: space-evenly;
    }
  }

  &__img {
    margin-right: 0.5rem;
  }
  &__name {
    line-height: 110%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-style: normal;
    font-weight: 500;
    font-size: 1.0625rem;
    line-height: 20px;
    mix-blend-mode: normal;
  }

  @media (max-width: 1880px) {
    &__list {
    }
  }

  @media (max-width: 1840px) {
    &__list {
    }
  }

  @media (max-width: 1700px) {
    &__list {
    }
  }
}