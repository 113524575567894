.program {

  .container {
    background: var(--c-greyer);
    padding-top: 4rem;
    padding-left: 6rem;
    padding-right: 0;
    padding-bottom: 0;

  }

  &__heading {
    // margin-bottom: 51px;
    color: var(--c-white);
    margin-bottom: 3rem;
  }

  &__list {
    display: flex;
    // flex-wrap: wrap;
    // box-sizing: border-box;
    // overflow: hidden;
    // padding: 0;
    // margin: 0 -0.5rem;
    align-items: stretch;
    justify-content: space-evenly;
  }

  &__item {
    border-top: 3px solid #CCCBCB;
    display: flex;
    flex-direction: column;
    position: relative;
    // margin: 0.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 110%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    box-sizing: border-box;
    width: 100%;
    max-width: 332px;
    height: 100%;
    flex-shrink: inherit;
    width: 100%;
    max-width: 354px;
    min-width: 335px;
    height: auto;
    :hover {
      img {
        filter: grayscale(0);
      }
    }
  }

  .swiper {
    background: var(--c-white);
    padding: 3rem;
    &-slide {

    }
  }

  &__date {
    padding: 0.3rem 0 2rem 0;
  }

  &__img {
    display: flex;
    overflow: hidden;
    margin-bottom: 1rem;
    height: 100%;
    max-height: 231px;
    img {
      width: 100%;
      height: auto;
      filter: grayscale(1);
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    margin-top: auto;
    height: 100%;

    font-style: normal;
    font-weight: 500;
    font-size: 1.0625rem;

    .program__buttons {
      margin-top: auto;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 1.625rem;
    line-height: 110%;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  &__number {
    margin: 1rem 0 2rem;
    width: 100%;
  }


  @media (max-width: 1880px) {
    &__list {

    }
  }

  @media (max-width: 1840px) {
    &__list {

    }
  }

  @media (max-width: 1700px) {
    &__list {

    }
  }
}