.skills {
  padding-top: 180px;
  padding-bottom: 60px;

  .container {
  }

  &__heading {
    margin-bottom: 51px;
    .special {
      display: block;
      margin-bottom: -72px;
    }
  }

  &__wrap {
    overflow-x: auto;
    padding-bottom: 0.5rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    box-sizing: border-box;
    // min-width: 1030px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 1rem;
    margin: 0.5rem;
    font-family: var(--font-family);
    font-size: 26px;
    line-height: 120%;
    color: var(--c-white);
    box-sizing: border-box;
    width: 100%;
    // max-width: calc((100% / 3) - 1rem);
    min-width: 300px;
    box-sizing: border-box;
    border: 1px solid var(--c-green);
    backdrop-filter: blur(7.5px);
    border-radius: 30px;
    &:nth-child(1) {
      max-width: calc(480px - 1rem);
    }
    &:nth-child(2) {
      max-width: calc(589px - 1rem);
    }
    &:nth-child(3) {
      max-width: calc(393px - 1rem);
    }
    &:nth-child(4) {
      max-width: calc(683px - 1rem);
    }
    &:nth-child(5) {
      max-width: calc(777px - 1rem);
    }
  }
  &__ico {
    margin-bottom: 1rem;
    width: 100%;
    display: none;
    justify-content: flex-end;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 70px;
      border-radius: 70px;
      background: radial-gradient(50% 82.45% at 50% 50%, #92B83E 0%, #72912D 100%);
    }
  }
  &__text {
  }



  &__slider-arrows {
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    align-content: center;

    .arrows {
      width: 100%;
      max-width: 100%;
      height: 60px;
      display: flex !important;
      justify-content: space-between;

      &-arrow {
        display: block !important;
        cursor: pointer;
        width: 60px;
        height: 60px;
        border: none;
        transition: all 0.3s ease;
      }

      .prev {
        background: url(../img/common/prev.svg) 50% 50% / 100% no-repeat;
        margin-right: 11px;

        &.slick-disabled {
          opacity: 0.8;
        }
      }

      .next {
        background: url(../img/common/next.svg) 50% 50% / 100% no-repeat;


        &.slick-disabled {
          opacity: 0.8;
        }
      }

    }
  }

  &-slider {
    display: flex;
    justify-content: center;
    min-width: 0;

    .slick {
      &-slide {
        width: 100%;
        max-width: 357px;
      }

      &-list {
        overflow: hidden;
        height: fit-content !important;
      }

      &-track {
        display: flex;
      }
    }
  }


  @media (max-width: 1880px) {
    &__list {

    }
  }

  @media (max-width: 1840px) {
    &__list {

    }
  }

  @media (max-width: 1700px) {
    &__list {

    }
  }
}