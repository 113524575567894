.intensives {
  padding-top: 180px;
  padding-bottom: 60px;

  &__heading {
    max-width: 870px;
    margin-bottom: 51px;

    .special {
      display: block;
      margin-bottom: -13px;
    }
  }

  &__list {
    counter-reset: section;
    list-style-type: none;
    justify-content: space-between;
    width: 1920px;
    max-width: 1920px;
    height: 618px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0;
    margin: 0;
    margin-left: -220px;
    margin-bottom: 40px;

    &-item {
      flex: 0 0 100%;
      max-width: 479px;

      .item-card {
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 31px;
        width: 100%;
        max-width: 100%;
        min-height: 617px;
        box-sizing: border-box;
        overflow: hidden;
        background-color: var(--c-white);
        border-radius: 30px;
        padding: 71px 19px 20px 21px;
        margin: 0;
        z-index: 1;

        &::before {
          counter-increment: section;
          content: "(0" counters(section, ".") ")";
          position: absolute;
          top: 23px;
          left: 19px;
          width: 58px;
          height: 31px;
          font-family: var(--font-family);
          font-weight: 700;
          font-size: 26px;
          line-height: 100%;
          letter-spacing: 0;
          text-align: center;
          color: var(--c-black);
          z-index: -1;
        }

        &__image {
          position: relative;
          width: 100%;
          max-width: 440.63px;
          min-height: 270px;
          box-sizing: border-box;
          overflow: hidden;
          background-color: #7c7c7c;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 30px;
        }

        &__info {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 100%;
          min-height: 205px;
          padding: 0;
          margin: 0;

          &-title {
            font-family: var(--font-family);
            font-weight: 500;
            font-size: 22px;
            line-height: 120%;
            letter-spacing: 0;
            color: var(--c-black);
            margin-bottom: auto;
          }

          &-buttons {
            width: 100%;
            max-width: 300px;
            height: 70px;
            margin-left: 1px;
          }
        }
      }

      &.online {
        .item-card {
          &::after {
            content: "(онлайн)";
            position: absolute;
            top: 23px;
            right: 18px;
            width: 115px;
            height: 31px;
            font-family: var(--font-family);
            font-weight: 700;
            font-size: 26px;
            line-height: 100%;
            letter-spacing: 0;
            text-align: right;
            color: var(--c-red);
            z-index: -1;
          }
        }
      }
    }
  }

  &__slider-arrows {
    width: 100%;
    max-width: 150px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0 auto;

    .arrows {
      width: 100%;
      max-width: 100%;
      height: 60px;
      display: flex !important;
      justify-content: space-between;

      &-arrow {
        display: block !important;
        cursor: pointer;
        width: 60px;
        height: 60px;
        border: none;
        transition: all 0.3s ease;
      }

      .prev {
        background: url(../img/common/prev.svg) 50% 50% / 100% no-repeat;
        margin-right: 11px;

        &.slick-disabled {
          opacity: 0.8;
        }
      }

      .next {
        background: url(../img/common/next.svg) 50% 50% / 100% no-repeat;


        &.slick-disabled {
          opacity: 0.8;
        }
      }

    }
  }

  &-slider {
    display: flex;
    justify-content: center;
    min-width: 0;

    .slick {
      &-slide {
        width: 100%;
        max-width: 509px;
      }

      &-list {
        overflow: hidden;
      }

      &-track {
        display: flex;
      }
    }
  }

  @media (max-width: 1880px) {
    &__list {
      margin-left: -180px;
    }
  }

  @media (max-width: 1840px) {
    &__list {
      margin-left: -100px;
    }
  }

  @media (max-width: 1700px) {
    &__list {
      margin-left: 0;
    }
  }
}