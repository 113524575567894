.fancybox-content {
	background: none;
	padding: 0;
}

.fancybox-active {
	overflow: hidden;
}


.compensate-for-scrollbar {
	margin-right: 0 !important;
}

.fancybox-bg {
	background: rgba(45, 45, 45, 0.6) !important;
}

#modal-form {
	position: relative;
	width: 480px;
	max-width: 480px;
	// min-height: 716px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: var(--c-greyer);
	border: 2px solid var(--c-greyer);
	border-radius: 30px;
	padding: 1.5rem 1rem;
	font-family: var(--font-family);
	font-weight: 600;
	font-size: 1.625rem;
	line-height: 120%;
	letter-spacing: 0;
	color: var(--c-white);


	.form {
		.h1 {
			font-family: var(--font-family);
			font-weight: 600;
			font-size: 26px;
			line-height: 120%;
			letter-spacing: 0;
			text-align: center;
			color: var(--c-white);
			margin-bottom: 47px;
		}

		&__lines {
			position: relative;
			width: 100%;
			max-width: 408px;
			margin-left: auto;
			margin-right: auto;

			.row {
				max-width: 408px;
				margin: 0 auto;

				.col-lg-3 {
					flex: none;
					width: 100%;
					max-width: 408px;
					height: 80px;
					padding: 0;
					margin-bottom: 20px;

					&:last-child {
						height: fit-content;
						margin-top: 3px;
						margin-bottom: 0;
					}
				}
			}
		}

		&__line {
			margin-bottom: 0;
		}
		&__button {
			width: 100%;
			span {
				font-weight: 500;
			}
		}
		&__input {
			width: 100%;
			max-width: 408px;
			height: 64px;
			border-radius: 10px;
			background-color: var(--c-white);
			border: 1px solid #e8e5e1;
			font-family: var(--font-family);
			font-weight: 400;
			font-size: 22px;
			line-height: 120%;
			letter-spacing: 0;
			color: #9b9b9b;
			padding-top: 0;
			padding-left: 31px;

			&::placeholder {
				font-family: var(--font-family);
				font-weight: 400;
				font-size: 22px;
				line-height: 120%;
				letter-spacing: 0;
				color: #9b9b9b;
			}
		}

		&__confirmation {
			max-width: 100%;
			margin-top: 0;
			margin-left: 0;

			.checkbox {
				padding-left: 20px;

				&__input {
					top: 0;
					left: 0;
					border: 1px solid var(--c-white);
					border-radius: 2px;
					width: 16px;
					height: 16px;
				}

				input {
					&:checked+.checkbox__input {
						top: 0;
						left: 0;
						border: 1px solid var(--c-white);
						border-radius: 2px;
						width: 16px;
						height: 16px;

						&:before {
							position: absolute;
							top: 2px;
							left: 2px;
							background-image: url(../img/popup/check1.svg);
							background-repeat: no-repeat;
							background-position: center;
							width: 10px;
							height: 10px;
							padding-top: 0;
							padding-left: 0;
						}
					}
				}

				span {
					font-family: var(--font4);
					font-weight: 300;
					font-size: 12px;
					line-height: 120%;
					letter-spacing: 0;
					color: var(--c-white);

					.privacy {
						color: var(--c-white);
						text-decoration: underline;
					}

					br {
						display: none;
					}
				}
			}
		}
	}

	.fancybox-close-small {
		top: 5px;
		right: 5px;

		svg {
			color: var(--c-white);
		}
	}
}

.modal-intensives {
	position: relative;
	width: 1232px;
	max-width: 1232px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: var(--c-white);
	border: none;
	border-radius: 30px;
	color: var(--c-black);
	padding: 21px 20px 20px 20px;
	margin: 0;

	&__card {
		.m-card {
			&__heading {
				min-height: 77px;
				font-family: var(--font-family);
				font-weight: 700;
				font-size: 32px;
				line-height: 120%;
				letter-spacing: 0;
				color: var(--c-black);
				margin-bottom: 39px;

				.mob {
					display: none;
				}
			}

			&__info {
				justify-content: space-between;
				padding: 0;
				margin: 0;
				margin-bottom: 30px;

				&-left {
					position: relative;
					flex: 0 0 100%;
					max-width: 586px;
				}

				&-right {
					position: relative;
					display: flex;
					flex-direction: column;
					row-gap: 16px;
					flex: 0 0 100%;
					max-width: 586px;
					min-height: 388px;
				}

				&-image {
					position: relative;
					width: 100%;
					max-width: 586px;
					min-height: 388px;
					box-sizing: border-box;
					overflow: hidden;
					background-color: #7c7c7c;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					border-radius: 30px;
				}

				&-education {
					position: absolute;
					top: 0;
					left: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					max-width: 300px;
					height: 70px;
					box-sizing: border-box;
					overflow: hidden;
					background-color: var(--c-green);
					border-radius: 30px 0;
					font-family: var(--font-family);
					font-weight: 800;
					font-size: 22px;
					line-height: 100%;
					letter-spacing: 0;
					text-transform: uppercase;
					text-align: center;
					color: var(--c-white);
					padding: 0;
					padding-left: 3px;
					margin: 0;
				}

				&-format {
					position: relative;
					display: flex;
					flex-direction: column;
					width: 100%;
					max-width: 100%;
					padding: 0;
					margin: 0;

					&__heading {
						display: none;
						min-height: 120px;
						font-family: var(--font-family);
						font-weight: 500;
						font-size: 20px;
						line-height: 120%;
						letter-spacing: 0;
						color: var(--c-black);
						margin-left: 1px;
						margin-bottom: 16px;
					}

					&__list {
						position: relative;
						justify-content: space-between;
						row-gap: 10px;
						max-width: 489px;
						padding: 0;
						margin: 0;
						margin-bottom: 12px;

						&-item {
							display: flex;
							justify-content: center;
							align-items: center;
							flex: 0 0 100%;
							max-width: fit-content;
							height: 49px;
							box-sizing: border-box;
							overflow: hidden;
							background-color: #efefef;
							border-radius: 50px;
							font-family: var(--font-family);
							font-weight: 500;
							font-size: 24px;
							line-height: 120%;
							letter-spacing: 0;
							color: var(--c-black);
							padding: 10px 17px 8px 16px;
							margin: 0;

						}
					}

					&__text {
						display: block;
						font-family: var(--font-family);
						font-weight: 500;
						font-size: 22px;
						line-height: 120%;
						letter-spacing: 0;
						color: var(--c-black);
						margin-bottom: 0;
					}
				}

				&-whom {
					position: relative;
					display: flex;
					flex-direction: column;
					width: 100%;
					max-width: 100%;
					padding: 0;
					margin: 0;

					&__text {
						font-family: var(--font-family);
						font-weight: 700;
						font-size: 24px;
						line-height: 120%;
						letter-spacing: 0;
						color: var(--c-black);
						margin-bottom: 8px;
					}

					&__list {
						position: relative;
						padding: 0;
						margin: 0;

						&-item {
							position: relative;
							font-family: var(--font-family);
							font-weight: 500;
							font-size: 22px;
							line-height: 120%;
							letter-spacing: 0;
							color: var(--c-black);
							padding-left: 30px;
							margin-bottom: 3px;
							z-index: 1;

							&::before {
								content: "";
								position: absolute;
								top: 7px;
								left: 5px;
								width: 12px;
								height: 12px;
								background-color: var(--c-green);
								border-radius: 2px;
								z-index: -1;
							}

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}

				&-documents {
					position: relative;
					top: 1px;
					width: 100%;
					max-width: 100%;
					min-height: 58px;
					font-family: var(--font-family);
					font-style: italic;
					font-weight: 500;
					font-size: 24px;
					line-height: 120%;
					letter-spacing: 0;
					color: var(--c-black);
					padding-left: 70px;
					margin: 0;
					margin-top: auto;
					z-index: 1;

					&::before {
						content: "";
						position: absolute;
						top: 2px;
						left: 0;
						width: 55px;
						height: 55px;
						background-image: url(../img/popup/doc.svg);
						background-position: top right;
						background-repeat: no-repeat;
						background-size: cover;
						z-index: -1;
					}


				}
			}

			&__topics {
				position: relative;
				width: 100%;
				max-width: 100%;
				box-sizing: border-box;
				overflow: hidden;
				background-color: #efefef;
				border-radius: 30px;
				padding: 21px 20px 20px 20px;
				margin: 0;
				margin-bottom: 21px;

				&-text {
					display: block;
					font-family: var(--font-family);
					font-weight: 700;
					font-size: 24px;
					line-height: 120%;
					letter-spacing: 0;
					color: var(--c-black);
					margin-bottom: 7px;
				}

				&-list {
					position: relative;
					padding: 0;
					margin: 0;

					&__item {
						position: relative;
						font-family: var(--font-family);
						font-weight: 500;
						font-size: 24px;
						line-height: 121%;
						letter-spacing: 0;
						color: var(--c-black);
						padding-left: 30px;
						margin-bottom: 8px;
						z-index: 1;

						&::before {
							content: "";
							position: absolute;
							top: 7px;
							left: 5px;
							width: 12px;
							height: 12px;
							background-color: var(--c-green);
							border-radius: 2px;
							z-index: -1;
						}

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			&__speakers {
				position: relative;
				width: 100%;
				max-width: 100%;
				padding: 0;
				margin: 0;

				&-text {
					display: block;
					font-family: var(--font-family);
					font-weight: 700;
					font-size: 24px;
					line-height: 120%;
					letter-spacing: 0;
					color: var(--c-black);
					margin-bottom: 11px;
				}

				&-list {
					position: relative;
					justify-content: space-between;
					padding: 0;
					margin: 0;

					&__item {
						flex: 0 0 100%;
						max-width: 586px;

						.item-card {
							position: relative;
							width: 100%;
							max-width: 100%;
							box-sizing: border-box;
							padding: 1px 40px 0 124px;
							margin: 0;
							z-index: 1;

							&__photo {
								position: absolute;
								top: 0;
								left: 0;
								width: 100px;
								max-width: 100px;
								box-sizing: border-box;
								overflow: hidden;
								border-radius: 100%;
								z-index: -1;
							}

							&__info {
								position: relative;
								width: 100%;
								max-width: 100%;
								padding: 0;
								margin: 0;

								&-name {
									font-family: var(--font-family);
									font-weight: 700;
									font-size: 24px;
									line-height: 120%;
									letter-spacing: 0;
									color: var(--c-black);
									margin-bottom: 7px;
								}

								&-regalia {
									position: relative;
									padding: 0;
									margin: 0;

									&__item {
										position: relative;
										font-family: var(--font-family);
										font-weight: 500;
										font-size: 24px;
										line-height: 121%;
										letter-spacing: 0;
										color: var(--c-black);
										padding-left: 30px;
										margin-bottom: 8px;
										z-index: 1;

										&::before {
											content: "";
											position: absolute;
											top: 7px;
											left: 5px;
											width: 12px;
											height: 12px;
											background-color: var(--c-green);
											border-radius: 2px;
											z-index: -1;
										}

										&:last-child {
											margin-bottom: 0;
										}
									}
								}
							}
						}

						&.item-big {
							max-width: 100%;
						}
					}
				}
			}
		}
	}

	.fancybox-close-small {
		top: 23px;
		right: 23px;
		padding: 5px;

		svg {
			color: #d3d3d3;
		}
	}

	&.i-mini {
		.modal-intensives__card {
			.m-card {
				&__info {
					&-image {
						min-height: 400px;
					}

					&-format {
						&__list {
							row-gap: 12px;
							max-width: 533px;
							margin-bottom: 5px;

							&-item {
								&:nth-child(1) {
									padding: 10px 16px 8px 16px;
								}
							}
						}
					}

					&-documents {
						top: auto;
						font-size: 22px;
						line-height: 120%;

						&::before {
							top: 3px;
						}
					}
				}
			}
		}

		&.i6 {
			.modal-intensives__card {
				.m-card {
					&__info {
						&-format {
							&__list {
								row-gap: 12px;
								max-width: 535px;
								margin-bottom: 5px;

								&-item {
									&:nth-child(3) {
										padding: 10px 16px 8px 16px;
									}
								}
							}
						}
					}
				}
			}
		}

		&.i8 {
			.modal-intensives__card {
				.m-card {
					&__info {
						&-format {
							&__list {
								row-gap: 12px;
								max-width: 535px;
								margin-bottom: 5px;

								&-item {
									&:nth-child(1) {
										margin-right: 100px;
									}

									&:nth-child(3) {
										padding: 10px 16px 8px 16px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.modal-space {
	position: relative;
	width: 1232px;
	max-width: 1232px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: var(--c-white);
	border: none;
	border-radius: 30px;
	color: var(--c-black);
	padding: 21px 20px 20px 20px;
	margin: 0;

	&__card {
		.m-card {
			&__heading {
				min-height: 77px;
				font-family: var(--font-family);
				font-weight: 700;
				font-size: 32px;
				line-height: 120%;
				letter-spacing: 0;
				color: var(--c-black);
				margin-bottom: 39px;
			}

			&__info {
				justify-content: space-between;
				padding: 0;
				margin: 0;

				&-left {
					position: relative;
					flex: 0 0 100%;
					max-width: 586px;
				}

				&-right {
					position: relative;
					flex: 0 0 100%;
					max-width: 586px;
				}

				&-image {
					position: relative;
					width: 100%;
					max-width: 586px;
					min-height: 388px;
					box-sizing: border-box;
					overflow: hidden;
					background-color: #7c7c7c;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					border-radius: 30px;
				}

				&-heading {
					display: none;
					font-family: var(--font-family);
					font-weight: 700;
					font-size: 24px;
					line-height: 120%;
					letter-spacing: 0;
					color: var(--c-black);
					margin-left: 1px;
					margin-top: 31px;
					margin-bottom: 16px;
				}

				&-text {
					position: relative;
					font-family: var(--font-family);
					font-weight: 500;
					font-size: 24px;
					line-height: 120%;
					letter-spacing: 0;
					color: var(--c-black);
					margin-top: 31px;
					margin-bottom: 0;

					span {
						display: block;
						margin-bottom: 6px;

						&:last-child {
							margin-bottom: 0;
						}
					}

					&.mr-0 {
						margin: 0 !important;
					}
				}

				&-triggers {
					position: relative;
					padding: 0;
					margin: 0;
					margin-top: 8px;
					margin-bottom: 7px;

					&__item {
						position: relative;
						font-family: var(--font-family);
						font-weight: 500;
						font-size: 24px;
						line-height: 120%;
						letter-spacing: 0;
						color: var(--c-black);
						padding-left: 34px;
						margin-bottom: 7px;
						z-index: 1;

						&::before {
							content: "";
							position: absolute;
							top: 5px;
							left: 5px;
							width: 12px;
							height: 12px;
							background-color: var(--c-green);
							border-radius: 2px;
							z-index: -1;
						}

						&:nth-child(1) {
							&::before {
								top: 9px;
							}
						}

						&:last-child {
							margin-bottom: 0;
						}
					}
				}

				&-list {
					position: relative;
					padding: 0;
					margin: 0;

					&__item {
						width: 100%;
						max-width: 100%;
						margin-bottom: 24px;

						.item-card {
							position: relative;
							width: 100%;
							max-width: 100%;
							min-height: 66px;
							padding: 1px 20px 0 70px;
							margin: 0;

							&__image {
								position: absolute;
								top: 6px;
								left: 0;
								width: 50px;
								height: 50px;
								box-sizing: border-box;
								overflow: hidden;
								border-radius: 100%;
								padding: 0;
								margin: 0;
							}

							&__text {
								font-family: var(--font-family);
								font-weight: 500;
								font-size: 24px;
								line-height: 120%;
								letter-spacing: 0;
								color: var(--c-black);
								margin-bottom: 8px;

								.sub {
									display: block;
									font-weight: 700;
									margin-bottom: 8px;
								}

								&:last-child {
									margin-bottom: 0;
								}
							}
						}

						&:nth-child(1) {
							.item-card {
								&__image {
									top: 10px;
								}
							}
						}

						&:nth-child(2) {
							margin-bottom: 19px;

							.item-card {
								&__image {
									top: 5px;
								}
							}
						}

						&:nth-child(3) {
							margin-bottom: 20px;

							.item-card {
								&__text {
									.sub {
										margin-bottom: 0;
									}
								}
							}
						}

						&:last-child {
							margin-bottom: 0;
						}
					}
				}

				&-topics {
					width: 100%;
					max-width: 100%;
					box-sizing: border-box;
					overflow: hidden;
					background-color: #efefef;
					border-radius: 30px;
					padding: 21px 20px 20px 20px;
					margin: 0;
					margin-top: 31px;

					&__subtext {
						display: block;
						font-family: var(--font-family);
						font-weight: 700;
						font-size: 24px;
						line-height: 120%;
						letter-spacing: 0;
						color: var(--c-black);
						margin-bottom: 8px;
					}

					&__list {
						position: relative;
						padding: 0;
						margin: 0;

						&-item {
							position: relative;
							font-family: var(--font-family);
							font-weight: 500;
							font-size: 24px;
							line-height: 120%;
							letter-spacing: 0;
							color: var(--c-black);
							padding-left: 30px;
							margin-bottom: 8px;
							z-index: 1;

							&::before {
								content: "";
								position: absolute;
								top: 7px;
								left: 5px;
								width: 12px;
								height: 12px;
								background-color: var(--c-green);
								border-radius: 2px;
								z-index: -1;
							}

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}

	.fancybox-close-small {
		top: 23px;
		right: 23px;
		padding: 5px;

		svg {
			color: #d3d3d3;
		}
	}

	&.s2 {
		.modal-space__card {
			.m-card {
				&__info {
					&-list {
						&__item {
							&:nth-child(3) {
								margin-bottom: 19px;

								.item-card {
									&__text {
										.sub {
											margin-bottom: 8px;
										}
									}
								}
							}
						}
					}

					&-topics {
						margin-top: 90px;
					}
				}
			}
		}
	}

	&.s3 {
		.modal-space__card {
			.m-card {
				&__info {
					&-list {
						&__item {
							&:nth-child(3) {
								margin-bottom: 22px;

								.item-card {
									&__text {
										.sub {
											margin-bottom: 8px;
										}
									}
								}
							}
						}
					}

					&-topics {
						min-height: 341px;
						margin-top: 29px;
					}
				}
			}
		}
	}

	&.s4 {
		.modal-space__card {
			.m-card {
				&__info {
					&-list {
						&__item {
							&:nth-child(3) {
								margin-bottom: 19px;

								.item-card {
									&__text {
										.sub {
											margin-bottom: 8px;
										}
									}
								}
							}
						}
					}

					&-topics {
						min-height: 341px;
						margin-top: 90px;
					}
				}
			}
		}
	}

	&.s5 {
		.modal-space__card {
			.m-card {
				&__info {
					&-text {
						span {
							margin-bottom: 1px;
						}
					}

					&-list {
						&__item {
							&:nth-child(3) {
								margin-bottom: 0;

								.item-card {
									&__text {
										.sub {
											margin-bottom: 0;
										}
									}
								}
							}
						}
					}

					&-topics {
						min-height: 341px;
						margin-top: 118px;
					}
				}
			}
		}
	}
}

#phone-error,
#email-error,
#name-error {
	display: none !important;
	width: 100%;
	font-family: var(--font-family);
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 17px;
	color: #f00;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 10px;
}

.fancybox-button svg {
	color: var(--c-white);
}

body.compensate-for-scrollbar {
	height: 100%;
	overflow: hidden;
}

@media (max-width: 1250px) {
	.modal-intensives {
		width: 750px;
		max-width: 750px;
		padding: 20px 20px 20px 20px;

		&__card {
			.m-card {
				&__heading {
					max-width: 670px;
					min-height: 27px;
					font-size: 26px;
					margin-bottom: 20px;
				}

				&__info {
					margin-bottom: 21px;

					&-left {
						max-width: 296px;
					}

					&-right {
						row-gap: 0;
						max-width: 396px;
						min-height: fit-content;
					}

					&-image {
						max-width: 296px;
						min-height: 270px;
					}

					&-education {
						top: 0;
						left: 0;
						max-width: 169px;
						height: 44px;
						font-size: 22px;
						line-height: 100%;
						padding-left: 3px;
					}

					&-format {
						margin-bottom: 21px;

						&__list {
							row-gap: 10px;
							max-width: 261px;
							margin-bottom: 10px;

							&-item {
								max-width: 261px;
								height: 49px;
								font-size: 20px;
								line-height: 120%;
								padding: 10px 17px 10px 16px;

								&:nth-child(1) {
									max-width: 208px;
								}
							}
						}

						&__text {
							font-size: 20px;
							line-height: 120%;
						}
					}

					&-whom {
						margin-bottom: 25px;

						&__text {
							margin-bottom: 7px;
						}

						&__list {
							&-item {
								font-size: 20px;
								line-height: 120%;
								padding-left: 30px;
								margin-bottom: 4px;

								&::before {
									top: 7px;
									left: 5px;
									width: 12px;
									height: 12px;
								}
							}
						}
					}

					&-documents {
						max-width: 296px;
						font-size: 20px;
						line-height: 120%;
						padding-left: 70px;
						margin: 0;

						&::before {
							top: -3px;
							left: 0;
							width: 55px;
							height: 55px;
						}


					}
				}

				&__topics {
					padding: 21px 20px 20px 20px;
					margin-bottom: 21px;

					&-text {
						margin-bottom: 7px;
					}

					&-list {
						&__item {
							margin-bottom: 8px;

							&::before {
								top: 7px;
								left: 5px;
								width: 12px;
								height: 12px;
							}

							br {
								display: none;
							}
						}
					}
				}

				&__speakers {
					padding: 0 20px;

					&-text {
						margin-bottom: 11px;
					}

					&-list {
						margin: 0;

						&__item {
							max-width: 293px !important;

							.item-card {
								padding: 0;

								&__photo {
									position: relative;
									top: auto;
									left: auto;
									width: 100px;
									max-width: 100px;
									margin-bottom: 20px;
								}

								&__info {
									&-name {
										position: absolute;
										top: -119px;
										right: 0;
										max-width: 169px;
										margin-bottom: 7px;
									}

									&-regalia {
										&__item {
											padding-left: 30px;
											margin-bottom: 8px;

											&::before {
												top: 7px;
												left: 5px;
												width: 12px;
												height: 12px;
											}

											br {
												display: none;
											}
										}
									}
								}
							}
						}
					}
				}


			}
		}

		.fancybox-close-small {
			top: 12px;
			right: 23px;
			padding: 5px;
		}

		&.i-mini {
			.modal-intensives__card {
				.m-card {
					&__info {
						&-image {
							min-height: 270px;
						}

						&-format {
							&__list {
								row-gap: 10px;
								max-width: 296px;
								margin-bottom: 0;

								&-item {
									max-width: fit-content;
									padding: 10px 17px 10px 16px;

									&:nth-child(1) {
										height: 70px;
										padding: 10px 17px 8px 16px;
										order: 1;
									}

									&:nth-child(2) {
										padding: 10px 46px 10px 16px;
									}

									&:nth-child(3) {
										padding: 10px 10px 10px 16px;
									}
								}
							}
						}

						&-whom {
							margin-bottom: 23px;
						}

						&-documents {
							font-size: 20px;
							line-height: 120%;

							&::before {
								top: -3px;
							}
						}
					}
				}
			}

			&.i6 {
				.modal-intensives__card {
					.m-card {
						&__info {
							&-format {
								&__list {
									row-gap: 10px;
									max-width: 296px;
									margin-bottom: 0;

									&-item {
										max-width: fit-content;
										padding: 10px 17px 10px 16px;

										&:nth-child(1) {
											height: 70px;
											padding: 10px 17px 8px 16px;
											order: 1;
										}

										&:nth-child(2) {
											padding: 10px 47px 10px 16px;
										}

										&:nth-child(3) {
											padding: 10px 24px 10px 16px;
										}
									}
								}
							}
						}
					}
				}
			}

			&.i8 {
				.modal-intensives__card {
					.m-card {
						&__info {
							&-format {
								&__list {
									row-gap: 10px;
									max-width: 296px;
									margin-bottom: 0;

									&-item {
										max-width: fit-content;
										padding: 10px 17px 10px 16px;

										&:nth-child(1) {
											height: 49px;
											padding: 10px 106px 9px 16px;
											margin: 0;
											order: 1;
										}

										&:nth-child(2) {
											padding: 10px 47px 10px 16px;
										}

										&:nth-child(3) {
											padding: 10px 19px 10px 16px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.modal-space {
		width: 750px;
		max-width: 750px;
		padding: 21px 20px 20px 20px;

		&__card {
			.m-card {
				&__heading {
					max-width: 670px;
					min-height: 27px;
					font-size: 26px;
					margin-bottom: 20px;
				}

				&__info {
					&-left {
						max-width: 296px;
					}

					&-right {
						max-width: 396px;
					}

					&-image {
						max-width: 296px;
						min-height: 270px;
					}

					&-text {
						font-size: 20px;
						line-height: 120%;
						margin-top: 49px;

						span {
							margin-bottom: 4px;
						}

						&.mr-0 {
							margin: 0;
						}
					}

					&-triggers {
						margin-top: 8px;
						margin-bottom: 8px;

						&__item {
							font-size: 20px;
							line-height: 120%;
							padding-left: 29px;
							margin-bottom: 4px;

							&::before {
								top: 6px;
								left: 5px;
								width: 12px;
								height: 12px;
							}

							&:nth-child(1) {
								&::before {
									top: 7px;
								}
							}
						}
					}

					&-list {
						max-width: 296px;

						&__item {
							margin-bottom: 22px;

							.item-card {
								min-height: 66px;
								padding: 1px 0 0 60px;
								margin: 0;

								&__image {
									top: -1px;
									left: 0;
									width: 50px;
									height: 50px;
								}

								&__text {
									font-size: 18px;
									line-height: 120%;
									margin-bottom: 5px;

									.sub {
										margin-bottom: 6px;
									}
								}
							}

							&:nth-child(1) {
								.item-card {
									&__image {
										top: 2px;
									}
								}
							}

							&:nth-child(2) {
								margin-bottom: 15px;

								.item-card {
									&__image {
										top: 0;
									}
								}
							}

							&:nth-child(3) {
								margin-bottom: 20px;

								.item-card {
									&__text {
										.sub {
											display: initial;
											margin-bottom: 0;
										}
									}
								}
							}
						}
					}

					&-topics {
						min-height: fit-content;
						padding: 21px 20px 20px 20px;
						margin-top: 5px;

						&__subtext {
							margin-bottom: 7px;
						}

						&__list {
							&-item {
								font-size: 20px;
								line-height: 120%;
								padding-left: 30px;
								margin-bottom: 8px;

								&::before {
									top: 7px;
									left: 5px;
									width: 12px;
									height: 12px;
								}
							}
						}

					}
				}
			}
		}

		.fancybox-close-small {
			top: 12px;
			right: 23px;
			padding: 5px;
		}

		&.s2 {
			.modal-space__card {
				.m-card {
					&__info {
						&-list {
							&__item {
								&:nth-child(2) {
									margin-bottom: 20px;
								}

								&:nth-child(3) {
									margin-bottom: 20px;

									.item-card {
										&__image {
											top: 0;
										}

										&__text {
											.sub {
												display: block;
												margin-bottom: 6px;
											}
										}
									}
								}
							}
						}

						&-topics {
							margin-top: 4px;
						}
					}
				}
			}
		}

		&.s3 {
			.modal-space__card {
				.m-card {
					&__info {
						&-heading {
							margin-bottom: -29px;
						}

						&-list {
							&__item {
								&:nth-child(2) {
									margin-bottom: 20px;
								}

								&:nth-child(3) {
									margin-bottom: 20px;

									.item-card {
										&__image {
											top: 0;
										}

										&__text {
											.sub {
												display: block;
												margin-bottom: 6px;
											}
										}
									}
								}
							}
						}

						&-topics {
							min-height: fit-content;
							margin-top: 4px;
						}
					}
				}
			}
		}

		&.s4 {
			.modal-space__card {
				.m-card {
					&__info {
						&-list {
							&__item {
								&:nth-child(2) {
									margin-bottom: 20px;
								}

								&:nth-child(3) {
									margin-bottom: 20px;

									.item-card {
										&__image {
											top: 0;
										}

										&__text {
											.sub {
												display: block;
												margin-bottom: 6px;
											}
										}
									}
								}
							}
						}

						&-topics {
							min-height: fit-content;
							margin-top: 4px;
						}
					}
				}
			}
		}

		&.s5 {
			.modal-space__card {
				.m-card {
					&__info {
						&-heading {
							margin-bottom: 0px;
						}

						&-text {
							margin-top: 20px;
						}

						&-list {
							&__item {
								&:nth-child(3) {
									margin-bottom: 0;

									.item-card {
										&__text {
											.sub {
												margin-bottom: 0;
											}
										}
									}
								}
							}
						}

						&-topics {
							min-height: fit-content;
							margin-top: 20px;
						}
					}
				}
			}
		}
	}
}