$desktop-max-width: 1920px;
$mobile-min-width: 360px;

:root {
  --font-family: "Montserrat", sans-serif;
  // --font-family: "Raleway", sans-serif;
  // --font-family: "Inter", sans-serif;
  // --second-family: "MV-SKIFER", sans-serif;
  // --third-family: "HelveticaNeueCyr", sans-serif;
  // --font3: "Dela Gothic One", sans-serif;
  // --font4: "Suisse Intl", sans-serif;

  --c-main: #000;
  --c-red: #ED131C;
  --c-black: #000;
  --c-white: #ffffff;
  --c-grey: #E8E9F0;
  --c-greyer: #484A4E;
  --c-green: #ACF805;
  --c-t-green: #ACF805;
  --c-b-white: #FFF;
}

@import "_mixins";
@import "_functions";